import React, { useState, useRef } from 'react'
import { Inplace, InplaceContent, InplaceDisplay } from 'primereact/inplace'
import { InputText } from 'primereact/inputtext'

const CreateModule = ({
  onSubmit
}) => {
  const [module, setModule] = useState({ name: '' })
  const randomId = `inplace-text-${Math.random().toString(36).substring(7)}`
  const inputRef = useRef()

  return (
    <Inplace
      className='create-module-input'
      id={randomId}
      closable
      onOpen={() => {
        setTimeout(() => {
          inputRef?.current?.focus()
        }, 200)
      }}
    >
      <InplaceDisplay>
        <div className='module-input flex gap-2 align-items-center'>
          <i className='pi pi-plus px-2' />
          <span>Agregar</span>
        </div>
      </InplaceDisplay>
      <InplaceContent>
        <div className='module-input flex gap-4 align-items-center justify-content-between'>
          <div className='flex align-items-center gap-2 w-full'>
            <i className='pi pi-plus px-2' />
            <InputText
              id='module-name'
              className='w-full'
              ref={inputRef}
              value={module.name}
              onChange={(e) => setModule({ ...module, name: e.target.value })}
            />
          </div>
          <div className='module-input-controls flex align-items-center'>
            <i className="pi pi-times cursor-pointer" onClick={() => {
              document.querySelector(`#${randomId} .p-inplace-content-close`).click()
              setModule(prev => ({ ...prev, name: '' }))
            }}></i>
            <i className="pi pi-check cursor-pointer" onClick={() => {
              onSubmit(module)
              setModule(prev => ({ ...prev, name: '' }))
            }}></i>
          </div>
        </div>
      </InplaceContent>
    </Inplace>
  )
}

export default CreateModule
