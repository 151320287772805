import React, { useEffect, useLayoutEffect, useState } from 'react'
import TimeRegisterHeader from './controllers/TimeRegisterHeader.controller'
import TimeRegisterContent from './controllers/TimeRegisterContent.controller'
import TimeRegisterContextProvider from './contexts/TimeRegister.context'
import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'
import useSessionContext from '../../hooks/contextConsumers/useSessionContext.hook'
import { getAutomaticStartupTour, getAfterTaskCreationTour } from '../../utils/tour.utils'

const waitForElement = (selector, callback, interval = 1000, timeout = 10000) => {
  const startTime = new Date().getTime()
  const intervalId = setInterval(() => {
    const element = document.querySelector(selector)
    const currentTime = new Date().getTime()
    if (element) {
      clearInterval(intervalId)
      callback()
    } else if (currentTime - startTime >= timeout) { // Proteccion en dev
      clearInterval(intervalId)
      console.warn(`Elemento ${selector} no encontrado en el tiempo esperado`)
    }
  }, interval)

  return intervalId
}

const TimeRegister = () => {
  const [refreshProjects, setRefreshProjects] = useState(false)
  const { user } = useSessionContext()
  const tourDriver = driver({
    disableButtons: ['previous'],
    showProgress: true,
    allowClose: false,
    doneBtnText: 'Finalizar',
    closeBtnText: 'Cerrar',
    nextBtnText: 'Siguiente',
    prevBtnText: 'Anterior',
    progressText: '{{current}} de {{total}}',
    allowKeyboardControl: false
  })

  useEffect(() => {
    if (!user.hasCompletedBasicTutorial) {
      const intervalId = waitForElement('#time-register-section .empty-tasks-wrapper .p-button', () => {
        if (tourDriver.getState().isInitialized) return
        const steps = getAutomaticStartupTour(tourDriver, user)
        tourDriver.setSteps(steps)
        tourDriver.drive()
      })
      return () => clearInterval(intervalId)
    }
  }, [])

  useLayoutEffect(() => {
    if (!user.hasCompletedTaskTutorial && user.hasCompletedBasicTutorial) {
      const intervalId = waitForElement('.p-datatable-tbody > tr:first-child .pi-ellipsis-v', () => {
        if (tourDriver.getState().isInitialized) return
        const steps = getAfterTaskCreationTour(tourDriver)
        tourDriver.setSteps(steps)
        tourDriver.drive()
      }, 1000, 10000000)
      return () => clearInterval(intervalId)
    }
  }, [refreshProjects])

  return (
    <div id='time-register-section'>
      <TimeRegisterHeader
        setRefreshProjects={setRefreshProjects}
        initTutorial={() => {
          if (!user.hasCompletedBasicTutorial) return alert('Debes completar al menos el primer tutorial')
          const firstSteps = getAutomaticStartupTour(tourDriver, user)
          const secondSteps = getAfterTaskCreationTour(tourDriver, user)

          if (user.hasCompletedBasicTutorial && !user?.hasCompletedTaskTutorial) {
            tourDriver.setSteps(firstSteps)
          } else if (user.hasCompletedBasicTutorial && user?.hasCompletedTaskTutorial) {
            tourDriver.setSteps([
              ...firstSteps,
              // ...secondSteps
              ...(document.querySelector('.pi-ellipsis-v') ? secondSteps : [])
            ])
          }
          tourDriver.drive()
        }}
      />
      <TimeRegisterContextProvider>
        <TimeRegisterContent
          refreshProjects={refreshProjects}
        />
      </TimeRegisterContextProvider>
    </div>
  )
}

export default TimeRegister
