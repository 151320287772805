/* eslint-disable */
import React from 'react'
import MultiSelect from '../atoms/MultiSelect.atom'
import TeamChip from '../molecules/TeamChip.molecule'

const MultiSelectTeamMemberFilter = ({
  data,
  options
}) => {
  let teamAreas = []

  data.map((team) => {
    teamAreas.push({
      averageTime: team.data.averageTime,
      billedTime: team.data.billedTime,
      code: team.data.code,
      name: team.data.name,
      rangeData: team.data.rangeData,
      items: team.children.map(member => ({
        averageTime: member.data.averageTime,
        billedTime: member.data.billedTime,
        rangeData: member.data.rangeData,
        sisuTime: member.data.sisuTime,
        name: member.data.name,
        teamMemberID: member.data.teamMemberID,
        teamMemberLastName: member.data.teamMemberLastName,
        teamMemberName: member.data.teamMemberName,
        teamMemberTagName: member.data.teamMemberTagName
      }))
    })
  })

  const getSelectedItemsLabel = () => {
    let selectedValues = 0

    if (options.value) {
      const filteredValues = options.value.filter(value => {
        if (value === 'UX/UI' || value === 'DEVS' || value === 'LEADS') {
          return false
        } else return true
      })
      selectedValues = filteredValues.length
    }

    return selectedValues > 0
      ? `${selectedValues} seleccionados`
      : 'Ningún integrante seleccionado'
  }

  return (
    <MultiSelect
      className='client-project-filter-multiselect'
      value={options.value}
      options={teamAreas}
      onChange={(e) => {
        if (!e?.value || !Array.isArray(e.value)) {
          return
        }

        let finalSelection = []

        teamAreas.map((team) => {
          team.items.map((member) => {
            if (e.value.includes(member.name)) {
              finalSelection.push(member.name)
              if (!finalSelection.includes(team.name)) {
                finalSelection.push(team.name)
              }
            }
          })
        })

        options.filterCallback(finalSelection)
      }}
      optionLabel="teamMemberLastName"
      optionValue="name"
      placeholder={!teamAreas || !teamAreas?.length ? 'Sin opciones' : 'Todos'}
      optionGroupLabel='code'
      optionGroupChildren='items'
      optionGroupTemplate={(option) => {
        return (
          <TeamChip
            teamTag={option.code}
          />
        )
      }}
      itemTemplate={(option) => {
        return (
          <span>
            {option.name}
          </span>
        )
      }}
      selectedItemTemplate={(option) => {
        if (!option) {
          return 'Todos'
        }
        const team = teamAreas.find(item => item.name === option)

        if (team) {
          return null
        } else {
          return option
        }
      }}
      maxSelectedLabels={1}
      selectedItemsLabel={getSelectedItemsLabel()}
      disabled={!teamAreas || !teamAreas?.length}
    />
  )
}

export default MultiSelectTeamMemberFilter
