import React from 'react'
import IconWithTooltip from '../../../components/molecules/IconWithTooltip.molecule'
import PropTypes from 'prop-types'

export const ValueWithLabelTemplate = ({
  label,
  value,
  labelTooltip,
  labelTooltipContent,
  labelTooltipPosition,
  labelTooltipIcon,
  showWarningBadge,
  loading
}) => {
  return (
    <div className='flex flex-column gap-1 flex-1 flex-shrink-0 white-space-nowrap'>
      <span
        className='font-semibold text-base font-mulish'
        style={{ color: 'light-dark(var(--secondary-color-dark), var(--text-color)' }}
      >
        {label}
        {labelTooltip &&
          <IconWithTooltip
            tooltipValue={labelTooltipContent}
            position={labelTooltipPosition}
            iconClassName={`${labelTooltipIcon} mx-1`}
          />}
      </span>
      <span
        className='font-cairo font-semibold text-xl'
        style={{
          color: showWarningBadge ? 'var(--clr-danger)' : 'var(--text-color)'
        }}
      >
        {loading
          ? <i className='pi pi-spin pi-spinner'/>
          : value || '-'}
        {showWarningBadge && <i className='pi pi-exclamation-triangle ml-1' />}
      </span>
    </div>
  )
}

export const ValueWithLabelContainer = ({ children }) => {
  return (
    <div className='flex flex-wrap justify-content-center' style={{ gap: '16px' }}>
      {children}
    </div>
  )
}

ValueWithLabelTemplate.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  labelTooltip: PropTypes.bool,
  labelTooltipContent: PropTypes.string,
  labelTooltipPosition: PropTypes.string,
  labelTooltipIcon: PropTypes.string,
  showWarningBadge: PropTypes.bool
}

ValueWithLabelContainer.propTypes = {
  children: PropTypes.node
}
