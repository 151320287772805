export const getSingleSelectionMenu = ({ onDuplicate, onEdit, onDelete, onSelect }) => {
  return [
    {
      label: 'Editar',
      icon: 'pi pi-pencil',
      command: async () => {
        await onEdit()
      }
    },
    {
      label: 'Eliminar',
      icon: 'pi pi-trash',
      command: async () => {
        await onDelete()
      }
    },
    {
      label: 'Seleccionar',
      icon: 'pi pi-check-square',
      command: async () => onSelect()
    }
  ]
}

export const getMultipleSelectionMenu = ({ labelTemplate, onDeselect, onDuplicate, onDelete }) => {
  return [
    {
      template: () => labelTemplate()
    },
    {
      label: 'Deseleccionar',
      icon: 'pi pi-check-square',
      command: () => onDeselect()
    },
    {
      label: 'Eliminar',
      icon: 'pi pi-trash',
      command: () => onDelete()
    }
  ]
}
