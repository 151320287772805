import React from 'react'
import InputText from '../atoms/InputText.atom'
import PropTypes from 'prop-types'
import ErrorMessageForm from '../atoms/ErrorMessageForm.atom'
import { hasAnyErrors } from '../../utils/validations.utils'
import Label from '../atoms/Label.atom'
import ViewOutputForm from '../atoms/ViewOutputForm.atom'

export default function InputTextForm (props) {
  const forId = props.id || `input-text-${props.label?.replace(/ /g, '-')}-${new Date().getTime()}`
  return (
    <>
      <Label htmlFor={forId}>
        {props.label}{props.require ? ' *' : ''}{props.optional ? ' (opcional)' : ''}
      </Label> {
        props.viewOnly
          ? <ViewOutputForm value={props.value} />
          : <InputText
            id={forId}
            name={props.name}
            keyfilter={props.keyfilter}
            type={props.type || 'text'}
            value={props.value}
            onChange={props.onChange}
            onBlur={(e) => {
              if (props.autoTrim) {
                const trimmedValue = e.target.value.trim()
                e.target.value = trimmedValue
                props.onChange && props.onChange({ target: { name: props.name, value: trimmedValue } })
              }
              props.onBlur && props.onBlur(e)
            }}
            placeholder={props.placeholder}
            className={`${hasAnyErrors(props.error) ? 'p-invalid' : ''} ${props.className}`}
            disabled={props.disabled}
            maxLength={props.maxLength}
            autoComplete={props.autoComplete || 'off'}
            autoFocus={props.autoFocus}
            onKeyDown={props.onKeyDown}
          />
      }
      { hasAnyErrors(props.error) ? <ErrorMessageForm errorMessage={props.error[0]?.errorMessage }/> : null}
    </>
  )
}

InputTextForm.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  type: PropTypes.oneOfType([PropTypes.oneOf(['text', 'email', undefined])]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  keyfilter: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.array]),
  require: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
  optional: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
  autoTrim: PropTypes.bool,
  autoComplete: PropTypes.string
}

InputTextForm.defaultProps = {
  autoTrim: true
}
