export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404
}

export const SERVER_ERROR_CODES = {
  BACKEND_UNAVAILABLE: 'server/unavailable',
  SERVER_UNAVAILABLE: 'server/unauthenticated',
  NETWORK_UNAVAILABLE: 'network/unavailable'
}

export const SERVER_ERRORS = {
  [SERVER_ERROR_CODES.BACKEND_UNAVAILABLE]: 'El servidor está tomando un descanso. Dale un momento e inténtalo de nuevo más tarde.',
  [SERVER_ERROR_CODES.SERVER_UNAVAILABLE]: 'Parece que el servidor no te reconoce. Inténtalo de nuevo más tarde.',
  [SERVER_ERROR_CODES.NETWORK_UNAVAILABLE]: 'El servidor está tomando un descanso. Dale un momento e inténtalo de nuevo más tarde.'
}
