import React, { useEffect, useState, useRef } from 'react'
import {
  getIndividualTasksOfTeamMemberId
} from '../../../services/report.service'
import RegisteredTasksDataTable from './tables/RegisteredTasksTable/RegisteredTaskTable.controller'
import ContextMenu from '../../../components/atoms/ContextMenu.atom'
import ModalFormEditTask from '../../../components/compounds/ModalFormEditTask.compound'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'
import useToast from '../../../hooks/useToast.hook'
import { useNavigate } from 'react-router-dom'
import ModalFormCreateTask from '../../../components/compounds/ModalFormCreateTask.compound'
import { calculateLazyScrollIndexOffset, getContextMenuItemsForIndividualReport, getExportFilename, getLazyUniqueDaysDisplayed } from '../../../utils/report.utils'
import { INDIVIDUAL_REPORT_ROW_HEIGHT, INDIVIDUAL_REPORT_SKELETON_COLUMNS } from '../../../consts/reports.consts'
import lazyLoadingTemplate from '../../../components/compounds/registeredTasksTable/LazyLoadingTemplate.compound'

const RegisteredTasks = React.forwardRef(({
  teamMember = { id: null },
  period,
  dateRange,
  setShowControls,
  openCreateTaskForTeamMemberModal,
  setOpenCreateTaskForTeamMemberModal
}, ref) => {
  const [reportBuffer, setReportBuffer] = useState([])
  const [tasks, setTasks] = useState()
  const [selectedTask, setSelectedTask] = useState(null)
  const [loading, setLoading] = useState(true)
  const [lazyLoading, setLazyLoading] = useState(false)
  const [editTaskVisible, setEditTaskVisible] = useState(false)
  const [updatingTask, setUpdatingTask] = useState(null)
  const [lastEvent, setLastEvent] = useState(null)
  const { setErrorMessage } = useToast()
  const navigate = useNavigate()
  const cm = useRef(null)
  const startScrollIndex = useRef(0)
  const endScrollIndex = useRef(0)
  let loadLazyTimeout = null
  const cmItems = [
    {
      label: 'Editar',
      icon: 'pi pi-pencil',
      command: async () => {
        setEditTaskVisible(true)
      }
    }, {
      label: 'Historial de cambios',
      icon: 'pi pi-history',
      command: () => {
        navigate('/historial-tarea', {
          state: { taskId: selectedTask?.taskId }
        })
      }
    }
  ]

  useEffect(() => {
    if (!teamMember?.id) return
    getIndividualTasks()
  }, [teamMember?.id, dateRange])

  const getIndividualTasks = async () => {
    try {
      setLoading(true)
      const [startDate, endDate] = dateRange
      const response = await getIndividualTasksOfTeamMemberId(teamMember.id, startDate, endDate)
      if (!response.success) {
        throw new Error(response.message || 'Ocurrió un error al obtener las tareas')
      }
      const result = response.result
      setReportBuffer(result.tasks)
      console.log('Length: ', result.totalElementsCount)
      setTasks({
        ...result
      })
    } catch (error) {
      setErrorMessage({ message: error.message })
    } finally {
      setLoading(false)
      setShowControls(true)
      setLazyLoading(true)
    }
  }
  const onLazyLoad = async (event) => {
    console.log('onLazyLoad', event)
    !lazyLoading && setLazyLoading(true)
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout)
    }
    if (!tasks) return
    loadLazyTimeout = setTimeout(() => {
      const first = event?.first
      const last = event?.last
      setLastEvent({ first, last })
      const _virtualTasks = [...reportBuffer]
      const loadedTasks = reportBuffer.slice(first, last)
      Array.prototype.splice.apply(_virtualTasks, [
        ...[first, last - first],
        ...loadedTasks
      ])
      setTasks({
        ...tasks,
        tasks: _virtualTasks
      })
      setLazyLoading(false)
      setLoading(false)
    }, 200)
  }

  const changeSize = React.useMemo(() => {
    return tasks?.tasks?.length
  }, [tasks])
  if (loading || !tasks) {
    return (
      <div className='w-full h-20rem flex align-items-center justify-content-center'>
        <ProgressSpinner />
      </div>
    )
  }
  const exportFunction = () => {
    const [startDate, endDate] = dateRange
    return getIndividualTasksOfTeamMemberId(teamMember.id, startDate, endDate)
  }
  const onScrollIndexChange = ({ first, last }) => {
    const tbodyEl = ref.current?.getTable()?.querySelector('tbody') ?? null
    if (tbodyEl === null) {
      return
    }
    const offset = calculateLazyScrollIndexOffset(tasks?.tasks, startScrollIndex.current)
    tbodyEl.style.transform = `translate3d(0px, ${offset}px, 0px)`

    if (endScrollIndex.current !== startScrollIndex.current) {
      endScrollIndex.current = first
      return
    }
    endScrollIndex.current = first
    const observer = new MutationObserver(() => {
      observer.disconnect()
      startScrollIndex.current = endScrollIndex.current
      const index = endScrollIndex.current > 10 ? endScrollIndex.current - 1 : 0
      const newOffset = calculateLazyScrollIndexOffset(tasks?.tasks, index)
      tbodyEl.style.transform = `translate3d(0px, ${newOffset}px, 0px)`
    })
    observer.observe(tbodyEl, { childList: true })
  }
  const averageItemSize = (INDIVIDUAL_REPORT_ROW_HEIGHT * tasks?.tasks?.length - 1 + getLazyUniqueDaysDisplayed(tasks?.tasks, tasks?.tasks?.length - 1) * 33) / tasks?.tasks?.length
  return (
    <>
      <RegisteredTasksDataTable
        // key={changeSize}
        ref={ref}
        exportFilename={getExportFilename(`REP_IND_Reporte individual_${teamMember?.name?.split(' ').join('')}${teamMember?.lastName?.split(' ').join('')}`, dateRange, period)}
        exportFunction={exportFunction}
        period={period}
        dateRange={dateRange}
        data={tasks}
        setSelectedTask={setSelectedTask}
        updatingTask={updatingTask}
        cm={cm}
        virtualScrollerOptions={{
          className: 'virtual-scroller-teammember-tasks',
          lazy: true,
          itemSize: Math.max(averageItemSize, INDIVIDUAL_REPORT_ROW_HEIGHT),
          // itemSize: INDIVIDUAL_REPORT_ROW_HEIGHT,
          numToleratedItems: 50,
          onLazyLoad,
          delay: 200,
          showLoader: true,
          loading: lazyLoading,
          onScrollIndexChange,
          loadingTemplate: lazyLoadingTemplate
        }}
        teamMemberId={teamMember?.id}
        setLazyLoading={setLazyLoading}
        loading={loading}
      />
      <ContextMenu
        ref={cm}
        model={getContextMenuItemsForIndividualReport(selectedTask, cmItems)}
      />
      <ModalFormEditTask
        visible={editTaskVisible}
        setVisible={setEditTaskVisible}
        task={selectedTask}
        teamMemberId={teamMember?.id}
        onSubmit={() => {
          setUpdatingTask(selectedTask)
        }}
        onEditCompleted={(errorMessage) => {
          if (errorMessage) {
            setErrorMessage({ message: errorMessage })
          }
          getIndividualTasks()
          setEditTaskVisible(false)
          setUpdatingTask(null)
        }}
        onlyActiveProjects={false}
        isAdminEdit
        waitForResponse={false}
      />

      <ModalFormCreateTask
        visible={openCreateTaskForTeamMemberModal}
        setVisible={setOpenCreateTaskForTeamMemberModal}
        teamMember={teamMember}
        onCreateCompleted={(errorMessage) => {
          if (errorMessage) {
            setErrorMessage({ message: errorMessage })
          }
          getIndividualTasks()
          setLoading(false)
        }}
        onSubmit={() => {
          setLoading(true)
        }}
      />
    </>
  )
})

export default RegisteredTasks
