import React, { useEffect, useState } from 'react'
import PieChart from '../../../components/compounds/graphics/PieChart.compound'
import SquareRoundedColorListLegend from '../../../components/compounds/graphics/components/SquareRoundedColorListLegend'
import useScreenDimensionsContext from '../../../hooks/contextConsumers/useScreenDimensionsContext.hook'
import { formatDecimals } from '../../../utils/numbers.utils'
import ContainerOfChart from '../../../components/compounds/graphics/components/ContainerOfChart'
import { OPACITY_HIGHLIGHT_LEVELS } from '../../../consts/modules/graphics.consts'
import { debounce } from 'lodash'

const BilledTimePieChart = ({
  data: chartData,
  loading,
  error
}) => {
  const [data, setData] = useState(chartData)
  const { screenWidth } = useScreenDimensionsContext()
  const isMobile = screenWidth < 720
  useEffect(() => {
    setData(chartData)
  }, [chartData])
  const CHART_CONTAINER_CONFIG = {
    title: 'Facturación',
    iconClassName: 'pi pi-dollar text-3xl',
    className: 'flex-1 font-cairo flex-shrink-0 w-6'
  }
  const PIECHART_RESPONSIVE_CONTAINER_CONFIG = {
    minHeight: '300px',
    maxHeight: '300px',
    minWidth: !isMobile ? '450px' : '200px',
    maxWidth: '470px'
  }
  const SHOW_EMPTY_MESSAGE = !data?.length

  const tooltipTemplate = (data) => {
    if (data.value === 0) return null
    return (
      <div className='custom-recharts-tooltip billed-time-tooltip flex flex-column text-white align-items-center'>
        <div className='title-contaner flex justify-content-between w-full'>
          <div className='label-container font-bold font-mulish flex align-items-center' style={{ background: data.color }}>{data.extraInfo.label}</div>
          <div>{formatDecimals(data.value)}hs</div>
        </div>
        <span className='max-w-10 white-space-normal line-height-2 font-mulish'>{data.extraInfo.description}</span>
      </div>
    )
  }
  const debouncedSetData = debounce(setData, 50)
  const handleMouseEnterLegend = (e) => {
    const hoveredLegend = e?.currentTarget?.dataset?.name
    const updatedData = data.map((chart) => ({
      ...chart,
      data: chart.data.map(item => ({
        ...item,
        extraInfo: {
          ...item.extraInfo,
          opacity: item.name === hoveredLegend ? OPACITY_HIGHLIGHT_LEVELS.HIGHLIGHTED : OPACITY_HIGHLIGHT_LEVELS.NOT_HIGHLIGHTED
        }
      }))
    }))
    debouncedSetData(updatedData)
  }

  const handleMouseLeaveLegend = () => {
    const resetData = data.map((chart) => ({
      ...chart,
      data: chart.data.map(item => ({
        ...item,
        extraInfo: {
          ...item.extraInfo,
          opacity: OPACITY_HIGHLIGHT_LEVELS.HIGHLIGHTED
        }
      }))
    }))
    debouncedSetData(resetData)
  }

  if (error) {
    return (
      <ContainerOfChart
        title='Facturación'
        iconClassName='pi pi-dollar text-3xl'
        className='flex-1 font-cairo flex-shrink-0 w-6'
      >
        <div className="flex justify-content-center align-items-center">
          {error}: Error al cargar la información
        </div>
      </ContainerOfChart>
    )
  }

  return (
    <>
      <PieChart
        data={data}
        responsiveContainerConfig={PIECHART_RESPONSIVE_CONTAINER_CONFIG}
        legend
        legendTemplate={(data) => (
          <SquareRoundedColorListLegend
            data={data}
            onMouseEnter={handleMouseEnterLegend}
            onMouseLeave={handleMouseLeaveLegend}
          />
        )}
        legendConfig={{
          verticalAlign: isMobile ? 'bottom' : 'middle',
          align: isMobile ? 'center' : 'right',
          layout: isMobile ? 'horizontal' : 'vertical'
        }}
        margin={{
          top: !isMobile ? 16 : 5,
          bottom: !isMobile ? 16 : 5
        }}
        label={false}
        tooltip
        tooltipTemplate={tooltipTemplate}
        showEmptyMessage={SHOW_EMPTY_MESSAGE}
        emptyMessageConfig={{
          title: 'Sin información',
          message: 'No hay horas registradas durante el periodo seleccionado, intenta cambiando los filtros.'
        }}
        chartContainerConfig={CHART_CONTAINER_CONFIG}
        loading={loading}
      />
    </>
  )
}

export default BilledTimePieChart
