import { API_TASKS } from '../consts/apiRoutes.consts'
import SERVICE_CONSTS from '../consts/services.consts'
import { api } from './base/api.service'

const {
  RESPONSE_BASIC_STRUCTURE,
  MESSAGES
} = SERVICE_CONSTS

export const getDailyTasks = async (teamMemberID) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_TASKS}/getDailyTasks`, {
      teamMemberData: {
        teamMemberID
      }
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const createDailyTask = async (taskData) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_TASKS}/createDailyTask`, taskData)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const createDayTasks = async (taskDayData) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_TASKS}/createDayTask`, taskDayData)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const createDayTaskForTeamMemberIDByAdmin = async (taskData) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_TASKS}/createDayTaskForTeamMemberIDByAdmin`, taskData)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getTodayTasksOfTeamMember = async (teamMemberID) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_TASKS}/getTodayTasksOfTeamMemberID`, {
      teamMemberData: {
        teamMemberID
      }
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getTasksOfTeamMemberIDAtDate = async (teamMemberID, date) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_TASKS}/getTasksOfTeamMemberIDAtDate`, {
      teamMemberData: {
        teamMemberID,
        date
      }
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getHoursResumeOfTeamMemberID = async (teamMemberID, weekStart, weekEnd, dayDate) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_TASKS}/getHoursResumeOfTeamMemberID`, {
      teamMemberData: {
        teamMemberID, weekStart, weekEnd, dayDate
      }
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const duplicateTask = async (taskID) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_TASKS}/duplicateTask`, {
      taskID
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const duplicateTasks = async (taskIDs) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_TASKS}/duplicateTasks`, {
      taskIDs
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const deleteTask = async (taskID) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.delete(`${API_TASKS}/deleteTask`, {
      taskID
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const deleteTasks = async (taskIDs) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.delete(`${API_TASKS}/deleteTasks`, {
      taskIDs
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const editTask = async (taskData) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }
  // console.log('td', taskData)
  let result
  try {
    result = await api.put(`${API_TASKS}/editTask`, { taskData })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const updateTasksOrder = async (tasks) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.put(`${API_TASKS}/updateTasksOrder`, tasks)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const validateTaskID = async (taskId) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.get(`${API_TASKS}/validateTaskID/${taskId}`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getTaskChangelog = async (taskId) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.get(`${API_TASKS}/getTaskChangelog/${taskId}`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}
