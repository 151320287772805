import React, { useEffect, useState } from 'react'
import Dropdown from '../../../../components/atoms/Dropdown.atom'
import { TeamMemberProjectRoleContainer } from '../../../../components/layouts/containers/TeamMemberProjectRoleContainer.container'
import { useSession } from '../../../../hooks/auth/useSession.hook'
import { Rol } from '../../../../consts/roles.consts'
import { getTeamMembers } from '../../../../services/teamMember.service'
import DropdownCustomFilterSearch from '../../../../components/molecules/DropdownCustomFilterSearch.molecule'

const TeamMemberDropdown = ({
  selectedTeamMember,
  setSelectedTeamMember,
  placeholder = 'Selecciona un miembro',
  optionLabel = 'name',
  ...props
}) => {
  const [options, setOptions] = useState([])
  const auth = useSession()
  const id = `team-member-dropdown-${new Date().getTime()}`
  useEffect(() => {
    const fetchTeamMembers = async () => {
      const response = await getTeamMembers()
      if (response.success) {
        setOptions(response.result)
        if (!auth.user.isAdmin()) {
          console.log('Getting Team Member Data')
          const teamMemberData = response.result.find(member => member.id === auth.user.id)
          console.log('Team Member Data: ', teamMemberData)
          setSelectedTeamMember(teamMemberData)
        }
      }
    }
    fetchTeamMembers()
  }, [])

  useEffect(() => {
    if (options.length > 0 && selectedTeamMember) {
      const member = options.find(option => option.id === selectedTeamMember.id)
      setSelectedTeamMember(member)
    }
  }, [options, selectedTeamMember, setSelectedTeamMember])

  const itemTemplate = item => (
    <div className='overflow-hidden white-space-nowrap max-w-14rem text-overflow-ellipsis'>
      <i className="pi pi-user mr-2"></i>
      <span>{item?.nameTag} - </span>
      <span>
        {item?.name} {item?.lastName}
      </span>
    </div>
  )

  return (
    // options.length > 0 &&
    <TeamMemberProjectRoleContainer roles={[Rol.ADMIN, Rol.ADMIN_WITHOUT_REPORT]}>
      <Dropdown
        inputId={id}
        options={options}
        value={selectedTeamMember}
        onChange={e => {
          const selected = options.find(option => option.id === e.value.id)
          setSelectedTeamMember(selected)
        }}
        optionLabel={optionLabel}
        placeholder={placeholder}
        itemTemplate={itemTemplate}
        valueTemplate={(item) => {
          if (!item) return placeholder
          return itemTemplate(item)
        }}
        filter
        filterBy='nameTag,name,lastName'
        filterTemplate={(props) => <DropdownCustomFilterSearch props={props} />}
        emptyFilterMessage='Sin resultados ¿Seguro que trabaja aquí?'
        disabled={options.length === 0}
      />
    </TeamMemberProjectRoleContainer>
  )
}

export default TeamMemberDropdown
