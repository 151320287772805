import React, { useEffect, useState } from 'react'
import FormSectionTitle from './FormSectionTitle'
import FormContainer from '../../../../../components/layouts/containers/FormContainer.container'
import FieldContainer from '../../../../../components/layouts/containers/FieldContainer.container'
import InputTextForm from '../../../../../components/molecules/InputTextForm.molecule'
import BadgePreview from '../../components/BadgePreview'
import InputSwitchForm from '../../../../../components/molecules/InputSwitchForm.molecule'
import DropdownColorInput from '../../../../../components/molecules/DropdownColorInput.molecule'
import { isNotEmpty, isNotMaxLength, validateFieldValue } from '../../../../../utils/validations.utils'

const FormClientDetails = ({
  client,
  setClient,
  isCreate = true,
  projects,
  setProjects
}) => {
  // const isCreate = !client?.name && !client?.code
  const initialFieldState = {
    name: {
      errors: [],
      value: null,
      typeValidations: [isNotEmpty]
    },
    code: {
      errors: [],
      value: null,
      typeValidations: [isNotEmpty, isNotMaxLength],
      validationOptions: { isNotMaxLength: { max: 3 } }
    },
    color: {
      errors: [],
      value: null,
      typeValidations: [isNotEmpty]
    },
    active: {
      errors: [],
      value: null,
      typeValidations: [isNotEmpty]
    }
  }
  const [validations, setValidations] = useState(initialFieldState)
  const [originalProjects, setOriginalProjects] = useState([])
  const [formData, setFormData] = useState({
    name: client?.name || '',
    code: client?.code || '',
    color: client?.color || '',
    active: client?.active || false
  })

  useEffect(() => {
    if (client) {
      setFormData({
        name: client.name,
        code: client.code,
        color: client.color,
        active: client.active
      })
    }
  }, [client])

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value })
    setValidations(validateFieldValue(validations, name, value))
    setClient({ ...client, [name]: value })
    if (name === 'active') {
      if (value === false) {
        setOriginalProjects([...projects])
        const deactivateProjects = projects.map(p => ({
          ...p,
          active: 0,
          modules: p.modules.map(m => ({
            ...m,
            active: 0
          }))
        }))
        setProjects(deactivateProjects)
      } else {
        setProjects(originalProjects)
      }
    }
  }

  return (
    <>
      <FormSectionTitle title="Detalles del cliente"/>
      <FormContainer className='mt-3'>
        <FieldContainer col={4} md={4}>
          <InputTextForm
            label="Nombre"
            placeholder="Nombre"
            name="name"
            onChange={handleChange}
            value={formData.name}
            error={validations.name.errors}
          />
        </FieldContainer>
        <FieldContainer col={4} md={4} className='mb-0'>
          <InputTextForm
            label="Código"
            placeholder="Código"
            name="code"
            onChange={handleChange}
            value={formData.code}
            error={validations.code.errors}
            maxLength="3"
          />
        </FieldContainer>
        {
          !isCreate && (
            <FieldContainer col={4} md={4}>
              <span className='mb-2 font-semibold line-height-3'>ID del cliente</span>
              <div className='mt-3'>{client?.id}</div>
            </FieldContainer>
          )
        }
        <FieldContainer col={4} md={4} className='mb-0'>
          <DropdownColorInput
            label={'Color'}
            colors={[
              { label: 'Amarillo', value: '#C79807' },
              { label: 'Naranja', value: '#F97316' },
              { label: 'Rojo', value: '#D9342B' },
              { label: 'Rosa', value: '#EC4899' },
              { label: 'Púrpura', value: '#A855F7' },
              { label: 'Índigo', value: '#5457CD' },
              ...(client.id === 1 ? [{ label: 'SISU', value: '#2896D8' }] : []),
              { label: 'Azul', value: '#326FD1' },
              { label: 'Teal', value: '#14B8A6' },
              { label: 'Verde', value: '#1DA750' }
            ]}
            placeholder="Selecciona un color"
            name="color"
            value={formData.color}
            onChange={handleChange}
            error={validations.color.errors}
          />
        </FieldContainer>
        <FieldContainer col={4} md={4} className='mb-0'>
          <BadgePreview
            label={formData?.code || '---'}
            color={formData?.color || 'var(--secondary-color)'}
          />
        </FieldContainer>
        {
          !isCreate && (
            <FieldContainer col={4} md={4} className='mb-0'>
              <span className='mb-2 line-height-3'>
                Estatus
              </span>
              <InputSwitchForm
                name="active"
                label={formData.active ? 'Activo' : 'Inactivo'}
                value={Boolean(formData.active)}
                onChange={handleChange}
                className='success-slider'
              />
            </FieldContainer>
          )
        }
      </FormContainer>
    </>
  )
}

export default FormClientDetails
