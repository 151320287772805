import React from 'react'
import ClientChip from '../components/molecules/ClientChip.molecule'
import ProjectChip from '../components/molecules/ProjectChip.molecule'
import { Link } from 'react-router-dom'

export const ITEM_SIZE_VIRTUAL_SCROLLER = 80

export const TABLE_SUFFIX = {
  kgM2: 'kg/m²',
  m2: 'm²',
  mililitrosXJulio: 'ml/J',
  litrosxMetroCuadrado: ' l/m²'
}

export const DEFAULT_SKELETON_TABLE_CONFIG = {
  loading: true,
  qtyOfRows: 20
}

export const CLIENT_CHIP_COLUMN_PROPS = {
  field: 'data.name',
  header: 'Cliente/Proyecto',
  body: (node) => {
    let name = ''
    if (node?.data?.name) {
      // We remove the project code prefix
      const oldName = node?.data?.name
      const items = oldName.split(' - ')
      name = items[items.length - 1]
    }

    if (node?.data?.code) {
      const isChild = node.parentKey !== undefined
      const clientCode = node.data.code
      const projectCode = node.data.tagName

      return (
        <div
          className='team-member-name'
          // to={`/reporte-general/horas-cliente/${node.data.code}/${node.startDate}/${node.endDate}`}
          // title='Ir al reporte de cliente ↗'
        >
          <div className='flex gap-2 align-items-center'>
            {isChild
              ? <ProjectChip clientCode={clientCode} projectCode={projectCode} />
              : <ClientChip
                className='w-fit'
                clientCode={node?.data?.code}
              />}

            <span className='font-bold'>{name}</span>
          </div>
        </div>

      )
    } else if (node?.data?.tagName) {
      const projectName = node?.data?.name?.split('-').length > 1 ? node?.data?.name?.split('-')[1] : node?.data?.name
      return (
        <div className='flex gap-2 align-items-center'>
          <ProjectChip
            className='w-fit'
            clientCode={node?.data?.clientCode}
            projectCode={node?.data?.tagName}
          />
          <span>{projectName}</span>
        </div>
      )
    } else {
      return <span>{name}</span>
    }
  },
  filter: true,
  filterField: 'data.name',
  filterMatchMode: 'in'
}

export const OVERFLOW_ELLIPSIS_COLUMN_PROPS = {
  body: (rowData) => (
    <div className='overflow-hidden white-space-nowrap text-overflow-ellipsis'>
      {rowData}
    </div>
  )
}
