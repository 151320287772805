import React from 'react'
import MultiSelect from '../../atoms/MultiSelect.atom'

const categoryFilterTemplate = ({
  options,
  categories,
  filterConfig,
  categoryFilterOptionsRef
}) => {
  const filterFields = filterConfig?.filterFields?.split(',')
  const filterValues = filterConfig?.filterValues?.split(',')
  let savedCategories = null
  if (filterFields?.includes('categoryId')) {
    savedCategories = filterValues?.filter((value, index) => filterFields[index] === 'categoryId')
  }
  if (!options.value && savedCategories) {
    options.value = savedCategories
  }
  categoryFilterOptionsRef.current = options
  return (
    <MultiSelect
      value={options.value}
      options={categories}
      optionLabel='label'
      optionValue='value'
      onChange={(e) => {
        options.filterCallback(e.value)
      }}
      placeholder='Filtrar categoría'
      emptyFilterMessage='Misión fallida... no encontramos nada'
      maxSelectedLabels={1}
      selectedItemsLabel='{0} seleccionados'
      disabled={categories.length === 0}
    />
  )
}

export default categoryFilterTemplate
