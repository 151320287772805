import React from 'react'
import Chip from '../../../components/atoms/misc/Chip.atom'
import PropTypes from 'prop-types'

const TendencyChip = ({ severity }) => {
  let icon = ''
  let background = ''
  switch (severity) {
  case 'success':
    icon = 'pi pi-arrow-up'
    background = 'bg-success'
    break
  case 'danger':
    icon = 'pi pi-arrow-down'
    background = 'bg-danger'
    break
  case 'warn':
    icon = 'pi pi-arrow-up'
    background = 'bg-warn'
    break
  default:
    break
  }
  return (
    <Chip
      className={`tendency-chip ${background}`}
      icon={`text-white ${icon}`}
    />
  )
}

export default TendencyChip

TendencyChip.propTypes = {
  severity: PropTypes.oneOf(['success', 'danger', 'warn']).isRequired
}
