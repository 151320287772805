import React from 'react'
import { Skeleton } from '../../atoms/misc/Skeleton.atom'
import { INDIVIDUAL_REPORT_SKELETON_COLUMNS } from '../../../consts/reports.consts'

const addedStyles = new Set()
const lazyLoadingTemplate = (options) => {
  const isColumnInSkeleton = !options.column.props.hidden // && INDIVIDUAL_REPORT_SKELETON_COLUMNS.includes(options.column.props.field)
  const id = `column-skeleton-${options.column.props.field}-${options.props.className}`
  if (!addedStyles.has(id) && isColumnInSkeleton) {
    // console.log(id)
    const styleSheet = document.createElement('style')
    styleSheet.textContent = `
      td:has(.${id}) {
        width: ${isColumnInSkeleton ? options.column.props.style.width ?? 'auto' : 'auto'};
        max-width: ${isColumnInSkeleton ? options.column.props.style.maxWidth ?? 'auto' : 'auto'};
        min-width: ${isColumnInSkeleton ? options.column.props.style.minWidth ?? 'auto' : 'auto'};
        flex: ${isColumnInSkeleton ? options.column.props.style.flex + '!important' ?? 'auto' : '1 0 auto'};
        height: 37px;
      }
    `
    document.head.appendChild(styleSheet)
    addedStyles.add(id)
  }
  return (
    <div
      className={`flex align-items-center ${id}`}
      style={{
        height: '37px',
        flexGrow: '1',
        overflow: 'hidden',
        padding: '0.5rem',
        width: '100%'
      }}
    >
      <Skeleton
        className='column-skeleton'
        height="1rem"
      />
    </div>
  )
}

export default lazyLoadingTemplate
