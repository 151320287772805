import React from 'react'
import SettingsHeader from './controllers/SettingsHeader.controller'
import SettingsContent from './controllers/SettingsContent.controller'

const Settings = () => {
  return (
    <>
      <SettingsHeader/>
      <SettingsContent/>
    </>
  )
}

export default Settings
