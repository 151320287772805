import React, { useEffect, useState } from 'react'
import Tabview from '../../../../../components/atoms/tabView/TabView.atom'
import TabPanel from '../../../../../components/atoms/tabView/TabPanel.atom'
import { createPortal } from 'react-dom'
import TabviewControllersContainer from '../../components/TabviewControllersContainer'
import PrimaryButton from '../../../../../components/molecules/buttons/PrimaryButton.molecule'
import ModalFormCreateProject from './ModalFormCreateProject.controller'
import FormSectionTitle from './FormSectionTitle'
import FormProject from './FormProject.controller'
import { isNotEmpty, validateFieldValue } from '../../../../../utils/validations.utils'
import FormClientModules from './FormClientModules.controller'
import Divider from '../../../../../components/atoms/Divider.atom'
import BlockUI from '../../../../../components/atoms/misc/BlockUI.atom'

const FormClientProjects = ({
  client,
  projects: createdProjects,
  setProjects: setCreatedProjects,
  setError
}) => {
  const [defaultProject, setDefaultProject] = useState(null)
  const [openProjectModal, setOpenProjectModal] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [shakeProject, setShakeProject] = useState(false)
  const [tabViewContainer, setTabViewContainer] = useState(null)

  useEffect(() => {
    if (
      createdProjects.length === 0 &&
      client?.name &&
      client?.code &&
      client?.code.length === 3 &&
      (!defaultProject || (client?.name === '' && client?.code === ''))
    ) {
      const defaultProject = [
        {
          code: client.code,
          name: client.name,
          formData: {
            name: client.name,
            code: client.code
          },
          validations: {
            name: {
              errors: [],
              value: null,
              typeValidations: [isNotEmpty]
            },
            code: {
              errors: [],
              value: null,
              typeValidations: [isNotEmpty]
            }
          },
          modules: []
        }
      ]
      setCreatedProjects(defaultProject)
      setDefaultProject(defaultProject)
    }
  }, [client, createdProjects.length, defaultProject, setCreatedProjects])

  useEffect(() => {
    const container = document.querySelector('#projects-tabview .p-tabview-nav')
    if (container) {
      setTabViewContainer(container)
    }
    setError(false)
    for (const project of createdProjects) {
      if (project?.modules?.length === 0) {
        setError(true)
      }
    }
  }, [createdProjects])

  const handleCreateProject = (project) => {
    setCreatedProjects([
      ...createdProjects,
      {
        ...project,
        formData: {
          name: project?.name || '',
          code: project?.code || ''
        },
        validations: {
          name: {
            errors: [],
            value: null,
            typeValidations: [isNotEmpty]
          },
          code: {
            errors: [],
            value: null,
            typeValidations: [isNotEmpty]
          }
        },
        modules: []
      }
    ])
  }

  const handleChangeProject = (index, name, value) => {
    // const project = createdProjects[index]
    // project.formData[name] = value
    // project[name] = value
    // console.log('project', [
    //   ...createdProjects.slice(0, index),
    //   project,
    //   ...createdProjects.slice(index + 1)
    // ])
    // setCreatedProjects([
    //   ...createdProjects.slice(0, index),
    //   project,
    //   ...createdProjects.slice(index + 1)
    // ])
    const project = createdProjects[index]
    project.formData[name] = value
    const validations = validateFieldValue(project.validations, name, value)
    project[name] = value
    project.validations = validations
    // console.log('handleChangeProject', [
    //   ...createdProjects.slice(0, index),
    //   project,
    //   ...createdProjects.slice(index + 1)
    // ])
    setCreatedProjects([
      ...createdProjects.slice(0, index),
      project,
      ...createdProjects.slice(index + 1)
    ])
  }

  const handleDeleteProject = ({ index }) => {
    setCreatedProjects([
      ...createdProjects.slice(0, index),
      ...createdProjects.slice(index + 1)
    ])
    setActiveIndex(0)
  }

  return (
    <BlockUI
      className='bg-transparent'
      containerClassName={`${shakeProject ? 'h-shake' : ''}`}
      blocked={client?.name === '' || client?.code === ''}
      onClick={() => {
        if (client?.name === '' || client?.code === '') {
          setShakeProject(true)
          setTimeout(() => {
            setShakeProject(false)
          }, 1000)
        }
      }}
    >
      <FormSectionTitle title="Proyectos" />
      <Tabview
        key={createdProjects.length}
        id='projects-tabview'
        activeIndex={activeIndex}
        onBeforeTabClose={(e) => {
          e.originalEvent.preventDefault()
          e.originalEvent.stopPropagation()
          // console.log('e 1', e)
        }}
        onTabClose={(e) => {
          e.originalEvent.preventDefault()
          e.originalEvent.stopPropagation()
          // console.log('e 2', e, createdProjects)
          handleDeleteProject({ index: e.index })
        }}
      >
        {
          createdProjects?.length > 0
            ? createdProjects.map((project, index) => (
              <TabPanel
                key={index}
                header={`${project.code} - ${project.name}`}
                className='deletable-project '
                closable
              >
                <FormProject
                  formData={project.formData}
                  handleChange={(e) => handleChangeProject(index, e.target.name, e.target.value)}
                  validations={project.validations}
                  layout='horizontal'
                />
                <Divider className='my-3' stroke='sm' />
                <FormClientModules
                  modules={project.modules}
                  setError={setError}
                  setModules={(modules) => {
                    const project = createdProjects[index]
                    project.modules = modules
                    setCreatedProjects([
                      ...createdProjects.slice(0, index),
                      project,
                      ...createdProjects.slice(index + 1)
                    ])
                  }}
                />
              </TabPanel>
            ))
            : <TabPanel header='No hay proyectos'>
              <div className='flex justify-content-center align-items-center h-10rem w-full'>
                <p>Crea un proyecto para poder agregar módulos</p>
              </div>
            </TabPanel>
        }
      </Tabview>
      {
        tabViewContainer &&
        createPortal(
          <TabviewControllersContainer>
            <PrimaryButton
              label='Crear proyecto'
              icon='pi pi-plus'
              onClick={() => setOpenProjectModal(true)}
              className='white-space-nowrap'
            />
          </TabviewControllersContainer>,
          tabViewContainer
        )
      }

      <ModalFormCreateProject
        visible={openProjectModal}
        setVisible={setOpenProjectModal}
        setProject={handleCreateProject}
        projects={createdProjects}
      />
    </BlockUI>
  )
}

export default FormClientProjects
