/* eslint-disable */
import React, { useEffect, useRef } from 'react'
import { Dropdown } from 'primereact/dropdown'
import Chip from '../atoms/misc/Chip.atom'
import DropdownCustomFilterSearch from '../molecules/DropdownCustomFilterSearch.molecule'
import {
  checkAllFields,
  fieldsDoesNotHaveErrors
} from '../../utils/validations.utils'
import DropdownInput from '../molecules/DropdownInput.molecule'

const DropdownModuleFilter = ({
  value,
  onChange,
  label = 'Elige un módulo',
  name = 'moduleTagName',
  options,
  optionValue = 'moduleID',
  optionLabel = 'moduleName',
  error,
  dropdownRef,
  isEditingModuleDropdown,
  setIsEditingModuleDropdown,
  onPressEnter,
  loading,
  formValidations,
  setFormValidations,
  handleFormSubmit,
  disabled,
  ...props
}) => {
  if (!options) return
  const moduleName = value ? options.find(option => option?.[optionValue] === value)?.[optionLabel] : ''

  return (
    <div className={`dropdown-module-filter-inplace ${disabled ? 'disabled' : ''}`}>
      {
        isEditingModuleDropdown
          ? <DropdownInput
            ref={dropdownRef}
            name={name}
            filter
            className='modules-dropdown'
            placeholder={label}
            options={options}
            optionValue={optionValue}
            optionLabel={optionLabel}
            value={value}
            onChange={(e) => {
              onChange(e)
            }}
            itemTemplate={(option) => {
              return (
                <div className='flex gap-2 align-items-center module-item'>
                  <span className='flex-1'>{option.moduleName}</span>
                </div>
              )
            }}
            emptyMessage='No hay modulos aquí... ¡este cliente es minimalista!'
            filterTemplate={(props) => <DropdownCustomFilterSearch 
              props={props} 
              inputProps={{ 
                autoFocus: true, 
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    checkAllFields(formValidations, setFormValidations)
                    if (fieldsDoesNotHaveErrors(formValidations)) {
                      handleFormSubmit()
                      return
                    }
                }

                if (e.key === 'Enter' || e.key === 'Tab') {
                  if (e.key === 'Tab') {
                    e.preventDefault()
                  }
                  onPressEnter(e)
                }
                  props.filterInputKeyDown(e)
                }
              }} 
            />}
            panelClassName={`${loading ? 'loading' : ''}`}
          />
          : moduleName
            ? <div
              className='module-selected pl-2'
              onClick={() => {
                setIsEditingModuleDropdown(true)
                setTimeout(() => {
                  dropdownRef.current?.show()
                }, 100)
              }}
            >
              {moduleName}
            </div>
            : <Chip
              className={`btn btn-primary w-full ${error ? 'error-chip' : ''}`}
              template={
                // eslint-disable-next-line
                <span className='p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem'>{moduleName || label} {error ? <i className='pi pi-exclamation-triangle' data-pr-tooltip="Este es un campo requerido" data-pr-position="bottom" /> : null}</span>
              }
              onClick={() => {
                setIsEditingModuleDropdown(true)
                setTimeout(() => {
                  dropdownRef.current?.show()
                }, 100)
              }}
            />
      }
    </div>

  )
}

export default DropdownModuleFilter
