import React, { useEffect, useState } from 'react'
import ModalForm from './modalForm/ModalForm.compunds'
import FormContainer from '../layouts/containers/FormContainer.container'
import InputTextForm from '../molecules/InputTextForm.molecule'
import FieldContainer from '../layouts/containers/FieldContainer.container'
import {
  checkAllFields,
  fieldsHaveErrors,
  isANumberGreaterThanZero,
  isDateNotGreaterThanToday,
  isNotEmpty,
  validateFieldValue,
  resetFieldErrors
} from '../../utils/validations.utils'
import DropdownInput from '../molecules/DropdownInput.molecule'
import { getProjectCategories } from '../../services/category.service'
import { getProjectModules } from '../../services/module.service'
import { getActiveProjects } from '../../services/project.service'
import InputTime from '../molecules/InputTime.molecule'
import InputSwitchForm from '../molecules/InputSwitchForm.molecule'
import { createDayTaskForTeamMemberIDByAdmin } from '../../services/task.service'
import DropdownWithTitleSubtitle from '../molecules/DropdownWithTitleSubtitle.molecule'
import { formatDropdownProjects } from '../../utils/project.utils'
import DropdownProjectFilter from './DropdownProjectFilter.compound'
import Label from '../atoms/Label.atom'
import DropdownCustomFilterSearch from '../molecules/DropdownCustomFilterSearch.molecule'
import InputCalendarForm from '../molecules/InputCalendarForm.molecule'
import { RANGES } from '../../consts/CompoundRangeDatePicker'
import { DateTime } from 'luxon'
import { CREATE_TASK_BY_ADMIN_DATA_TEMPLATE, CREATE_TASK_BY_ADMIN_INITIAL_STATE } from '../../consts/modules/createTaskByAdminForm.consts'
import DropdownCategoryFilter from './DropdownCategoryFilter.compound'
import { useMessage } from '../../hooks/useMessage.hook'
import useToast from '../../hooks/useToast.hook'

const ModalFormCreateTask = ({
  visible,
  setVisible,
  teamMember = null,
  onSubmit,
  onCreateCompleted = () => { },
  waitForResponse = true
}) => {
  const [projects, setProjects] = useState([])
  const [categories, setCategories] = useState([])
  const [modules, setModules] = useState([])
  const [loadingCategories, setLoadingCategories] = useState(false)
  const [loadingModules, setLoadingModules] = useState(false)
  const [loadingProjects, setLoadingProjects] = useState(false)
  const [adjustTime, setAdjustTime] = useState(false)
  const [hasSpecialCategory, setHasSpecialCategory] = useState(false)
  const [data, setData] = useState(CREATE_TASK_BY_ADMIN_DATA_TEMPLATE)
  const [validations, setValidations] = useState(CREATE_TASK_BY_ADMIN_INITIAL_STATE)
  const { setErrorMessage } = useToast()

  useEffect(() => {
    if (visible) {
      gettingProjectsData()
    }
  }, [visible])

  useEffect(() => {
    if (data?.projectId) {
      setValidations(prev => ({
        ...prev,
        categoryId: {
          value: '',
          errors: [],
          typeValidations: [isNotEmpty],
          validationsOptions: {}
        },
        moduleId: {
          value: '',
          errors: [],
          typeValidations: [isNotEmpty],
          validationsOptions: {}
        }
      }))
      setData(prev => ({
        ...prev,
        moduleId: '',
        categoryId: ''
      }))
      gettingCategoriesData()
      gettingModulesData()
    }
    if (data?.moduleId) {
      setValidations(prev => ({
        ...prev,
        moduleId: {
          value: '',
          errors: [],
          typeValidations: [],
          validationsOptions: {}
        },
        categoryId: {
          value: '',
          errors: [],
          typeValidations: [isNotEmpty],
          validationsOptions: {}
        }
      }))
    }
  }, [data?.projectId])

  useEffect(() => {
    setValidations(prev => ({
      ...prev,
      adjustTimeReason: {
        value: prev.adjustTimeReason.value,
        errors: adjustTime && prev.adjustTimeReason.errors.value ? [prev.adjustTimeReason.errors.value] : [],
        typeValidations: adjustTime ? [isNotEmpty] : [],
        validationsOptions: {}
      },
      billedTime: {
        value: prev.billedTime.value || 0,
        errors: adjustTime && prev.billedTime.value === prev.sisuTime.value ? [{ errorMessage: 'Las horas no pueden ser iguales' }] : [],
        typeValidations: [],
        validationsOptions: {}
      }
    }))
  }, [adjustTime])

  const gettingProjectsData = async () => {
    setLoadingProjects(true)
    try {
      const responseProjects = await getActiveProjects(teamMember?.id)
      if (!responseProjects.success) {
        throw new Error()
      }
      if (responseProjects?.result) {
        const orderedProjects = formatDropdownProjects(responseProjects.result)
        setProjects(orderedProjects)
      }
    } catch (error) {
      setProjects([])
      setModules([])
      setCategories([])
      setErrorMessage({ message: 'Hubo un error al cargar los proyectos' })
    } finally {
      setLoadingProjects(false)
    }
  }

  const gettingCategoriesData = async () => {
    setLoadingCategories(true)
    try {
      const responseCategories = await getProjectCategories(data?.projectId)
      setCategories(responseCategories?.result)
      if (!responseCategories.success) {
        throw new Error()
      }
      if (responseCategories?.result?.find(c => c.specialCategoryID)) {
        setHasSpecialCategory(true)
      } else {
        setHasSpecialCategory(false)
      }
    } catch (error) {
      setCategories([])
      setErrorMessage({ message: 'Hubo un error al cargar las categorías' })
    } finally {
      setLoadingCategories(false)
    }
  }

  const gettingModulesData = async () => {
    setLoadingModules(true)
    try {
      const responseModules = await getProjectModules(data?.projectId)
      const modules = responseModules.result
      setModules(modules)
      if (modules && modules.length > 0) {
        setValidations(prev => ({
          ...prev,
          moduleId: {
            ...prev.moduleId,
            typeValidations: [isNotEmpty]
          }
        }))
      } else {
        setValidations(prev => ({
          ...prev,
          moduleId: {
            ...prev.moduleId,
            typeValidations: []
          }
        }))
      }
    } catch (error) {
      setModules([])
      setErrorMessage({ message: 'Hubo un error al cargar los módulos' })
    } finally {
      setLoadingModules(false)
    }
  }

  function handleChange ({ target: { name, value } }) {
    setData({ ...data, [name]: value })
    setValidations(validateFieldValue(validations, name, value))
    if ((name === 'billedTime' || name === 'sisuTime') && adjustTime) {
      setValidations(prev => ({
        ...prev,
        billedTime: {
          ...prev.billedTime,
          errors: prev.billedTime.value === prev.sisuTime.value ? [{ errorMessage: 'Las horas no pueden ser iguales' }] : []
        }
      }))
    }
  }

  async function handleSubmit () {
    if (onSubmit && !waitForResponse) {
      onSubmit()
    }
    checkAllFields(validations, setValidations)
    if (fieldsHaveErrors(validations)) {
      return
    }
    if (adjustTime && data.sisuTime === data.billedTime) {
      return {
        success: false,
        message: 'Si ajustas las horas cliente, estas deben ser diferentes a las horas trabajadas'
      }
    }
    if (!waitForResponse) {
      setVisible(false)
    }
    try {
      const taskDate = data.date.toSeconds()
      const taskData = {
        ...data,
        taskName: data.name,
        id: data.taskID,
        teamMemberId: teamMember.id,
        comment: data.comment === '' ? null : data.comment,
        billedTime: (!adjustTime ? data.sisuTime : data.billedTime) || 0,
        timeDifferenceComment: !adjustTime || data.adjustTimeReason === '' ? null : data.adjustTimeReason,
        moduleId: data.moduleId === '' ? null : data.moduleId,
        date: taskDate
      }
      const res = await createDayTaskForTeamMemberIDByAdmin({
        taskData,
        date: taskDate
      })
      onCreateCompleted(!res.success ? 'Hubo un error al crear la tarea' : null)
      if (onSubmit && waitForResponse) {
        onSubmit()
      }
      return res
    } catch (error) {
      onCreateCompleted(error.message)
      return {
        success: false,
        message: error.message
      }
    }
  }
  const resetFormState = () => {
    setProjects([])
    setCategories([])
    setModules([])
    setAdjustTime(false)
    setHasSpecialCategory(false)
    setData(CREATE_TASK_BY_ADMIN_DATA_TEMPLATE)
    setValidations(CREATE_TASK_BY_ADMIN_INITIAL_STATE)
  }

  const ModalHeaderTemplate = ({
    title = 'Agregar tarea',
    subtitle = `Integrante: ${teamMember?.name} ${teamMember?.lastName}`,
    iconClassName = 'pi pi-plus'
  }) => {
    return (
      <div className="flex gap-3 align-items-center">
        <i className={`${iconClassName} text-4xl`} style={{ color: '#2896D8' }} />
        <div className='flex flex-column gap-2'>
          <span className="text-3xl font-semibold">{title}</span>
          <span className="text-base font-normal">{subtitle}</span>
        </div>
      </div>
    )
  }

  return (
    <ModalForm
      visible={visible}
      setVisible={setVisible}
      header={<ModalHeaderTemplate />}
      service={handleSubmit}
      disabledSaveBtn={fieldsHaveErrors(validations)}
      saveLabel='Agregar tarea'
      className='create-task-admin'
      cleanUp={resetFormState}
    >
      <FormContainer className='edit-task-form'>
        <FieldContainer md={6}>
          <InputCalendarForm
            label='Fecha'
            name='date'
            placeholder='Selecciona una fecha'
            value={data.date}
            period={RANGES.DAY}
            inputReadOnly
            allowClear={false}
            format='DD/MM/YYYY'
            onChange={(date) => {
              handleChange({ target: { name: 'date', value: date } })
            }}
            maxDate={DateTime.local().endOf('day')}
            error={validations.date.errors}
          />
        </FieldContainer>
        <FieldContainer md={6}>
          <DropdownProjectFilter
            name='projectId'
            label='Proyecto'
            placeholder="Selecciona un proyecto"
            value={data.projectId}
            onChange={({ target: { value } }) => {
              const { clientTagName } = projects.find(p => p.projectID === value)
              handleChange({ target: { name: 'projectId', value } })
              setData(prev => ({
                ...prev,
                clientCode: clientTagName,
                projectId: value
              }))
              if (clientTagName === 'INT') {
                setAdjustTime(false)
              }
            }}
            options={projects}
            error={validations.projectId.errors}
            badgeTemplate
            withoutErrorMessage={false}
            panelClassName={`${loadingProjects ? 'loading bg-red-100' : ''}`}
          />
        </FieldContainer>
        <FieldContainer md={6}>
          <DropdownInput
            filter
            className='modules-dropdown'
            placeholder="Selecciona un módulo"
            label="Módulo"
            name="moduleId"
            options={modules}
            optionLabel="moduleName"
            optionValue="moduleID"
            value={data.moduleId}
            error={validations.moduleId.errors}
            onChange={handleChange}
            disabled={!modules || modules?.length === 0}
            itemTemplate={(option) => {
              return (
                <div className='flex gap-2 align-items-center module-item'>
                  <span className='flex-1'>{option.moduleName}</span>
                </div>
              )
            }}
            panelClassName={`${loadingModules ? 'loading' : ''}`}
            emptyMessage='No hay modulos aquí... ¡este cliente es minimalista!'
            filterTemplate={(props) => <DropdownCustomFilterSearch props={props} inputProps={{
              autoFocus: true
            }} />}
          />
        </FieldContainer>
        <FieldContainer md={6}>
          <DropdownCategoryFilter
            label="Categoría"
            placeholder="Selecciona una categoría"
            options={categories}
            optionLabel={`${hasSpecialCategory ? 'categoryGroup' : 'categoryTitle'}`}
            optionValue={`${hasSpecialCategory ? 'specialCategoryID' : 'categoryID'}`}
            name="categoryId"
            value={
              categories?.find(c => c?.[hasSpecialCategory ? 'specialCategoryID' : 'categoryID'] === data.categoryId)?.[hasSpecialCategory ? 'specialCategoryID' : 'categoryID']
            }
            onChange={handleChange}
            titleKey={`${hasSpecialCategory ? 'categoryGroup' : 'categoryTitle'}`}
            subtitleKey='categorySubtitle'
            error={validations.categoryId.errors}
            fullWidth
            loading={loadingCategories}
          />
        </FieldContainer>
        <FieldContainer md={12}>
          <InputTextForm
            label='Nombre'
            name='name'
            placeholder='Nombre de la tarea'
            value={data.name}
            onChange={handleChange}
            error={validations.name.errors}
            onBlur={() => {
              handleChange({ target: { name: 'name', value: data.name?.trim() } })
            }}
          />
        </FieldContainer>
        <FieldContainer md={12}>
          <InputTextForm
            label='Comentario'
            name='comment'
            placeholder='Agrega un comentario'
            value={data.comment}
            onChange={handleChange}
            onBlur={() => {
              handleChange({ target: { name: 'comment', value: data.comment?.trim() } })
            }}
            error={validations.comment.errors}
            optional
          />
        </FieldContainer>
        <FieldContainer md={6} className='flex align-items-center'>
          <InputTime
            className='w-full'
            label='Duración'
            name='sisuTime'
            value={data.sisuTime || 0}
            onChange={({ value }) => {
              handleChange({ target: { name: 'sisuTime', value } })
            }}
            error={validations.sisuTime.errors}
            fullWidth
          />
        </FieldContainer>
        <FieldContainer md={6} className='flex align-items-center m-0 input-switch-adjust-time'>
          {data.clientCode !== 'INT'
            ? <InputSwitchForm
              label='Ajustar horas cliente'
              value={adjustTime}
              onChange={() => setAdjustTime(!adjustTime)}
            />
            : null
          }
        </FieldContainer>
        {
          adjustTime &&
          <>
            <FieldContainer md={6} className='flex align-items-center'>
              <InputTime
                label='Horas cliente'
                name='billedTime'
                value={data.billedTime || 0}
                onChange={({ value }) => {
                  resetFieldErrors(validations, 'billedTime')
                  handleChange({ target: { name: 'billedTime', value } })
                }}
                error={validations.billedTime.errors}
                fullWidth
              />
            </FieldContainer>
            <FieldContainer md={6}>
              <InputTextForm
                label='Motivo de la diferencia'
                name='adjustTimeReason'
                placeholder='Agrega una razón'
                value={data.adjustTimeReason}
                onChange={handleChange}
                error={validations.adjustTimeReason.errors}
                onBlur={() => {
                  handleChange({ target: { name: 'adjustTimeReason', value: data.adjustTimeReason?.trim() } })
                }}
              />
            </FieldContainer>
          </>
        }
      </FormContainer>
    </ModalForm>
  )
}

export default ModalFormCreateTask
