/* eslint-disable */
import React, { useState } from 'react'
import { classNames } from 'primereact/utils'
import { getAppMenuByRole } from '../../utils/menu.utils'
import { AppSubmenu } from './AppSubmenu'
import { useSession } from '../../hooks/auth/useSession.hook'
import { BREAKPOINTS } from '../../consts/responsiveBreakpoints.consts'
import useSidebarContext from '../../hooks/contextConsumers/useSidebarContext.hook'
import useScreenDimensionsContext from '../../hooks/contextConsumers/useScreenDimensionsContext.hook'
import AppMenuUserPreview from './AppMenuUserPreview'
import AikaLogoDark from '../../design/assests/images/aika-logo-dark.svg'
import ReleaseVersion from '../../components/molecules/ReleaseVersion.molecule'

export default function AppMenu(props) {
  const OPEN_CODE = 1
  const CLOSE_CODE = 2
  const [menuActive, setMenuActive] = useState(true)
  const {
    sidebarActive,
    setSidebarActive,
    bockOpenTheNormalMenu,
    setBockOpenTheNormalMenu
  } = useSidebarContext()
  const [activeInlineProfile, setActiveInlineProfile] = useState(false)
  const { user } = useSession()
  const { screenWidth, setHideMobileMenu } = useScreenDimensionsContext()
  const model = getAppMenuByRole(user.projectRole)
  function onMenuItemClick(event) {
    setSidebarActive(true)
    setMenuActive(true)
    // if (!event.item.items) {
    //   setMenuActive(false)
    // }
  }

  function onRootMenuItemClick() {
    // setMenuActive((prevState) => !prevState)
  }

  const handleOpenAndCloseNormalMenu = (value) => {
    if (!sidebarActive && value === OPEN_CODE) {
      if (bockOpenTheNormalMenu) return
      if (sidebarActive) return
      setSidebarActive(true)
      setBockOpenTheNormalMenu(true)
      setTimeout(() => {
        setBockOpenTheNormalMenu(false)
      }, 200)
    }
    if (sidebarActive && value === CLOSE_CODE) {
      if (!sidebarActive) return
      if (bockOpenTheNormalMenu) return
      setTimeout(() => {
        setSidebarActive(true)
        setBockOpenTheNormalMenu(true)
        setTimeout(() => {
          setBockOpenTheNormalMenu(false)
        }, 200)
      }, 200)
    }
  }

  function onChangeActiveInlineMenu(event) {
    setActiveInlineProfile((prevState) => !prevState)
    event.preventDefault()
  }
  handleOpenAndCloseNormalMenu(OPEN_CODE)

  const LogoTemplate = () => (
    <div
      className='sidebar-logo-container flex flex-column gap-2 flex justify-content-center align-items-center'
      style={{
        margin: '0 39px'
      }}
    >
      <img
        src={AikaLogoDark}
        alt="Aika"
        height={42}
        width={80}
        className="block"
      />
      <ReleaseVersion
        className='text-center'
      />
    </div>
  )
  return (
    <div
      className={classNames('layout-menu-wrapper', {
        'layout-sidebar-active': (sidebarActive || screenWidth < BREAKPOINTS.TABLET_BREAKPOINT)
      })}
    // onMouseOver={() => handleOpenAndCloseNormalMenu(OPEN_CODE)}
    // onMouseLeave={() => handleOpenAndCloseNormalMenu(CLOSE_CODE)}
    >
      {/* { screenWidth < BREAKPOINTS.TABLET_BREAKPOINT
        ? <div className="mobile-header-structure">
          <div onClick={() => setHideMobileMenu(true)} className="mobile-header-structure__button">
            <IconMaterial icon={COMMON_ICONS.CLEAR_ICON} sx={{ color: '#003E66' }}/>
          </div>
          <div className="menu-logo">
            <Link to="/" className="logo p-link">
              <img src={SISULogo} className="layout-sidebar-default-logo" alt="logo" />
              <img src={SISULogo} className="layout-sidebar-active-logo" alt="logo" />
            </Link>
            <Link to="/" className="app-name p-link">
              <img src={SISULogoAppName} alt="HTG" />
            </Link>
          </div>
        </div>
        : <div className="menu-logo">
          <Link to="/" className="logo p-link">
            <img src={SISULogo} className="layout-sidebar-default-logo" alt="logo" />
            <img src={SISULogo} className="layout-sidebar-active-logo" alt="logo" />
          </Link>
          <Link to="/" className="app-name p-link">
            <img src={SISULogoAppName} alt="HTG" />
          </Link>
        </div>
      } */}
      <LogoTemplate />
      <div className="layout-menu-container flex flex-column justify-content-between">
        <AppSubmenu
          items={model}
          className="layout-menu"
          menuMode={props.menuMode}
          menuActive={menuActive}
          root
          parentMenuItemActive
          onMenuClick={null}
          onMenuItemClick={onMenuItemClick}
          onRootMenuItemClick={onRootMenuItemClick}
        />
        <AppMenuUserPreview />
      </div>

      {/* <AppInlineMenu
        menuMode={props.menuMode}
        activeInlineProfile={activeInlineProfile}
        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
      /> */}
    </div>
  )
}
