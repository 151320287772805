import React, { createContext, useEffect, useState } from 'react'
import { onAuthStateChanged } from '../../services/auth.service'
import { getRoutesByRole } from '../../utils/routes.utils'
import { onIdTokenChanged } from 'firebase/auth'
import { auth } from '../../config/firebase.config'
/**
 * @typedef {import('../../services/auth.service.d.ts').UserData} UserData
 */

/** @type {React.Context<{user: UserData | undefined}>} */
export const SessionContext = createContext({ user: undefined })

export function SessionProvider ({ children }) {
  /** @type {ReturnType<typeof useState<UserData | undefined>>} */
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(false)
  const [routes, setRoutes] = useState([])

  useEffect(() => {
    setLoading(true)
    const unsubscribe = onAuthStateChanged(setUser)

    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (user) {
      setRoutes(getRoutesByRole(user?.projectRole))
    } else {
      setRoutes([])
    }
    setLoading(false)
  }, [user])

  return (
    <SessionContext.Provider value={{ user, routes, loading }}>
      {children}
    </SessionContext.Provider>
  )
}
