import React, { useEffect, useState, useRef } from 'react'
import { DateTime } from 'luxon'
import { getIndividualTasksOfClientId } from '../../../services/report.service'
import useToast from '../../../hooks/useToast.hook'
import useReportFiltersContext from '../../../hooks/contextConsumers/useReportFiltersContext.hook'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'
import ContextMenu from '../../../components/atoms/ContextMenu.atom'
import ModalFormEditTask from '../../../components/compounds/ModalFormEditTask.compound'
import RegisteredClientTaskTable from './tables/RegisteredClientTaskTable/RegisteredClientTaskTable.controller'
import ClientPicker from './components/ClientPicker'
import ResetButton from './components/ResetButton'
import useMountedState from '../../../hooks/useMountedState'
import { useNavigate } from 'react-router-dom'
import UnifiedDatePicker from '../../../components/compounds/UnifiedDatePicker.compound'
import { thisWeekRange } from '../../../utils/date.utils'
import { RANGES, UNIFIEDDATE_VALUES } from '../../../consts/CompoundRangeDatePicker'
import { calculateLazyScrollIndexOffset, getContextMenuItemsForIndividualReport, getExportFilename, getLazyUniqueDaysDisplayed } from '../../../utils/report.utils'
import lazyLoadingTemplate from '../../../components/compounds/registeredTasksTable/LazyLoadingTemplate.compound'
import { INDIVIDUAL_REPORT_ROW_HEIGHT } from '../../../consts/reports.consts'

const INITIAL_SELECTED_CLIENT = null

const HoursByClientReport = React.forwardRef((props, ref) => {
  const [reportBuffer, setReportBuffer] = useState([])
  const [tasks, setTasks] = useState([])
  const [selectedTask, setSelectedTask] = useState(null)
  const [loading, setLoading] = useState(true)
  const [lazyLoading, setLazyLoading] = useState(false)
  const [error, setError] = useState(null)
  const { setErrorMessage } = useToast()
  const [editTaskVisible, setEditTaskVisible] = useState(false)
  const [updatingTask, setUpdatingTask] = useState(null)
  const {
    tasksByClientClients,
    tasksByClientSelectedClient,
    setTasksByClientSelectedClient,
    tasksByClientPeriod,
    setTasksByClientPeriod,
    tasksByClientDateRange,
    setTasksByClientDateRange
  } = useReportFiltersContext()
  const isMounted = useMountedState()
  const navigate = useNavigate()
  const cm = useRef(null)
  const startScrollIndex = useRef(0)
  const endScrollIndex = useRef(0)
  let loadLazyTimeout = null
  //* Request identifier ref,
  //* to allow ignoring stale responses / prevent race conditions
  const currentRequestId = useRef(null)
  const cmItems = [
    {
      label: 'Editar',
      icon: 'pi pi-pencil',
      command: async () => {
        setEditTaskVisible(true)
      }
    }, {
      label: 'Historial de cambios',
      icon: 'pi pi-history',
      command: () => {
        navigate('/historial-tarea', {
          state: { taskId: selectedTask?.taskId }
        })
      }
    }
  ]

  useEffect(() => {
    setUpdatingTask(null)
    setLoading(true)

    gettingIndividualTasks(true)
      .catch(() => {
        setLoading(false)
      })
  }, [tasksByClientDateRange, tasksByClientSelectedClient, tasksByClientPeriod])

  useEffect(() => {
    if (error) {
      setErrorMessage({ message: error })
    }
  }, [error])

  const gettingIndividualTasks = async (globalLoad = false) => {
    if (globalLoad) {
      setLoading(true)
    }

    let response = {}
    if (tasksByClientSelectedClient) {
      const [startDate, endDate] = tasksByClientDateRange
      //* We generate & assign this promise's ID
      const requestId = DateTime.now().toISO()
      currentRequestId.current = requestId

      const resp = await getIndividualTasksOfClientId(tasksByClientSelectedClient, startDate, endDate)
      if (!isMounted()) return

      // ? could be done with an abort controller
      //* We check if this response is still relevant...
      if (currentRequestId.current !== requestId) {
        return //* ...and we ignore it if it's stale
      }
      response = resp
    }
    if (response.success) {
      const result = response.result
      setReportBuffer(result.tasks)
      setTasks({
        ...result
      })
    } else {
      setError(response.message)
      setErrorMessage({ message: error.message })
    }
    if (globalLoad) {
      setLoading(false)
    }
    setLazyLoading(true)
  }

  const onLazyLoad = async (event) => {
    console.log('onLazyLoad', event)
    !lazyLoading && setLazyLoading(true)
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout)
    }
    loadLazyTimeout = setTimeout(() => {
      const first = event?.first
      const last = event?.last
      const _virtualTasks = [...reportBuffer]
      const loadedTasks = reportBuffer.slice(first, last)
      Array.prototype.splice.apply(_virtualTasks, [
        ...[first, last - first],
        ...loadedTasks
      ])
      setTasks({
        ...tasks,
        tasks: _virtualTasks
      })
      setLazyLoading(false)
      setLoading(false)
    }, 200)
  }

  const controls =
    <div className='flex justify-content-between align-items-center pb-3 '>
      <div className="mr-0 ml-1 flex flex-1 align-items-center gap-3">
        {tasksByClientClients.length > 1
          ? <ClientPicker
            clients={tasksByClientClients}
            selectedClient={tasksByClientSelectedClient}
            setSelectedClient={setTasksByClientSelectedClient}
          />
          : null
        }
        <UnifiedDatePicker
          dateRange={tasksByClientDateRange}
          setDateRange={setTasksByClientDateRange}
          period={tasksByClientPeriod}
          setPeriod={setTasksByClientPeriod}
        />
      </div>
      {props.downloadCsvButton(tasksByClientSelectedClient === null)}
      <ResetButton onClick={() => {
        setTasksByClientSelectedClient(INITIAL_SELECTED_CLIENT)
        ref.current?.reset()
        setTasksByClientPeriod(UNIFIEDDATE_VALUES.SINGLEWEEK)
        setTasksByClientDateRange(thisWeekRange())
        setTasks([])
        setReportBuffer([])
      }} />
    </div>
  const changeSize = React.useMemo(() => {
    return tasks?.tasks?.length
  }, [tasks])
  if (loading) {
    return (
      <>
        {controls}
        <div className='w-full h-20rem flex align-items-center justify-content-center'>
          <ProgressSpinner />
        </div>
      </>
    )
  }

  const exportFunction = () => {
    const [startDate, endDate] = tasksByClientDateRange
    return getIndividualTasksOfClientId(tasksByClientSelectedClient, startDate, endDate)
  }

  const onScrollIndexChange = ({ first, last }) => {
    const tbodyEl = ref.current?.getTable()?.querySelector('tbody') ?? null
    if (tbodyEl === null) {
      return
    }
    const offset = calculateLazyScrollIndexOffset(tasks?.tasks, startScrollIndex.current)
    tbodyEl.style.transform = `translate3d(0px, ${offset}px, 0px)`

    if (endScrollIndex.current !== startScrollIndex.current) {
      endScrollIndex.current = first
      return
    }
    endScrollIndex.current = first
    const observer = new MutationObserver(() => {
      observer.disconnect()
      startScrollIndex.current = endScrollIndex.current
      const index = endScrollIndex.current > 10 ? endScrollIndex.current - 1 : 0
      const newOffset = calculateLazyScrollIndexOffset(tasks?.tasks, index)
      tbodyEl.style.transform = `translate3d(0px, ${newOffset}px, 0px)`
    })
    observer.observe(tbodyEl, { childList: true })
  }
  const averageItemSize = (INDIVIDUAL_REPORT_ROW_HEIGHT * tasks?.tasks?.length - 1 + getLazyUniqueDaysDisplayed(tasks?.tasks, tasks?.tasks?.length - 1) * 33) / tasks?.tasks?.length
  return (
    <>
      {controls}
      <RegisteredClientTaskTable
        // key={changeSize}
        ref={ref}
        data={tasks}
        cm={cm}
        exportFilename={getExportFilename(`REP_GEN_${tasksByClientSelectedClient}_TareasPorCliente`, tasksByClientDateRange, tasksByClientPeriod)}
        exportFunction={exportFunction}
        setSelectedTask={setSelectedTask}
        updatingTask={updatingTask}
        isDailyPeriod={tasksByClientPeriod === UNIFIEDDATE_VALUES.SINGLEDAY}
        period={tasksByClientPeriod}
        dateRange={tasksByClientDateRange}
        selectedClient={tasksByClientSelectedClient}
        virtualScrollerOptions={{
          className: 'virtual-scroller-client-tasks',
          lazy: true,
          // itemSize: INDIVIDUAL_REPORT_ROW_HEIGHT,
          itemSize: Math.max(averageItemSize, INDIVIDUAL_REPORT_ROW_HEIGHT),
          numToleratedItems: 50,
          onLazyLoad,
          delay: 200,
          showLoader: true,
          loading: lazyLoading,
          onScrollIndexChange,
          loadingTemplate: lazyLoadingTemplate
        }}
        setLazyLoading={setLazyLoading}
      />
      <ContextMenu
        ref={cm}
        model={getContextMenuItemsForIndividualReport(selectedTask, cmItems)}
      />
      <ModalFormEditTask
        visible={editTaskVisible}
        setVisible={setEditTaskVisible}
        task={selectedTask}
        teamMemberId={null}
        onSubmit={() => {
          setUpdatingTask(selectedTask)
        }}
        onEditCompleted={(errorMessage) => {
          if (errorMessage) {
            setError(errorMessage)
          }
          gettingIndividualTasks()
            .then(() => setLazyLoading(false))
          setEditTaskVisible(false)
          setUpdatingTask(null)
        }}
        onlyActiveProjects={false}
        isAdminEdit
        waitForResponse={false}
      />
    </>
  )
})

export default HoursByClientReport
