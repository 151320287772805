import React from 'react'
import MultiSelect from '../../atoms/MultiSelect.atom'
import ClientChip from '../../molecules/ClientChip.molecule'
import ProjectChip from '../../molecules/ProjectChip.molecule'

const clientFilterTemplate = ({
  options,
  projects,
  clientFilterOptionsRef
}) => {
  clientFilterOptionsRef.current = options
  const selectedClients = []
  return (
    <MultiSelect
      className='client-project-multiselect'
      panelClassName='max-w-10rem'
      value={options.value}
      options={projects}
      onChange={(e) => {
        options.filterCallback(e.value)
      }}
      optionLabel='name'
      optionValue='name'
      placeholder='Todos'
      optionGroupLabel='name'
      optionGroupChildren='items'
      optionGroupTemplate={(option) => {
        if (option.type === 'client') {
          return (
            <ClientChip
              clientCode={option.code}
            />
          )
        }
      }}
      itemTemplate={(option) => {
        return (
          <div className='project-item-template flex align-items-center gap-2'>
            <ProjectChip
              clientCode={option.clientCode}
              projectCode={option.code}
            />
            <span className='white-space-normal'>
              {option.name}
            </span>
          </div>
        )
      }}
      selectedItemTemplate={(option) => {
        if (!option) return 'Todos'
        const item = projects
          .flatMap(client => client.items)
          .find(item => item?.name === option)
        if (!selectedClients.includes(item?.clientCode)) {
          selectedClients.push(item?.clientCode)
          return (
            <>
              <ClientChip
                clientCode={item?.clientCode}
              />
              <ProjectChip
                clientCode={item?.clientCode}
                projectCode={item?.code}
              />
            </>
          )
        }
        return (
          <ProjectChip
            clientCode={item?.clientCode}
            projectCode={item?.code}
          />
        )
      }}
      emptyFilterMessage='Misión fallida... no encontramos nada'
      maxSelectedLabels={3}
      selectedItemsLabel='{0} seleccionados'
      disabled={projects.length === 0}
    />
  )
}

export default clientFilterTemplate
