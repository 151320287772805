import { useState, useEffect } from 'react'
import { getElementFullHeight } from '../utils/component.utils'

const useDynamicHeight = ({
  titleSelector = '.layout-main-content > div:first-child',
  layoutMainContentSelector = '.layout-main-content',
  minHeight = 600,
  maxHeight = 2000,
  ...props
}) => {
  const [fullHeight, setFullHeight] = useState(0)
  useEffect(() => {
    const updateHeight = () => {
      const mainLayout = document.querySelector(layoutMainContentSelector)
      // eslint-disable-next-line
      const mainLayoutPadding = (mainLayout && parseInt(window.getComputedStyle(mainLayout).paddingTop.replace('px', '')) + parseInt(window.getComputedStyle(mainLayout).paddingBottom.replace('px', ''))) || 0
      const title = document.querySelector(titleSelector)
      const restElementsOffset = Object.values(props).reduce((acc, selector) => {
        const element = document.querySelector(selector)
        return acc + (element ? getElementFullHeight(element) : 0)
      }, 0)
      const newHeight =
        getElementFullHeight(mainLayout) -
        mainLayoutPadding -
        getElementFullHeight(title) -
        restElementsOffset
      setFullHeight(Math.min(Math.max(newHeight, minHeight), maxHeight))
    }

    window.addEventListener('resize', updateHeight)
    updateHeight()

    return () => window.removeEventListener('resize', updateHeight)
  }, [])

  return fullHeight
}
export default useDynamicHeight
