import React from 'react'
import GeneralReportContent from './controllers/GeneralReportContent.controller'
import ContentLayoutHeader from '../../components/layouts/ContentLayoutHeader.layout'

const GeneralReport = () => {
  return (
    <>
      <ContentLayoutHeader title='Reporte General' />
      <GeneralReportContent />
    </>
  )
}

export default GeneralReport
