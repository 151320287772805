import React from 'react'
import { percentageBody } from '../../../../../utils/tables.utils'
import CellReportBadges, { TimeDifferenceBadge, UnexpectedTimeBadge } from '../../components/CellReportBadges'
import DifferenceReasonTable from '../../components/DifferenceReasonTable'
import { Link } from 'react-router-dom'
import { capitalizeFirstLetter, formatPercentageSuffix } from '../../../../../utils/strings.utils'
import { DateTime } from 'luxon'
import ClientProjectChip from '../../../../../components/molecules/ClientProjectChip.molecule'
import { UNIFIEDDATE_VALUES } from '../../../../../consts/CompoundRangeDatePicker'

const teamMemberNameTemplate = (rowData) => {
  const startDate = Object.keys(rowData?.week)[0]
  const endDate = DateTime.fromISO(startDate).endOf('week').toISODate()
  const diffInDays = DateTime.fromISO(endDate).diff(DateTime.fromISO(startDate), 'days').days
  let period = UNIFIEDDATE_VALUES.SINGLEWEEK
  if (diffInDays <= 1) {
    period = UNIFIEDDATE_VALUES.SINGLEDAY
  } else if (diffInDays <= 7) {
    period = UNIFIEDDATE_VALUES.SINGLEWEEK
  } else if (diffInDays <= 31) {
    period = UNIFIEDDATE_VALUES.SINGLEMONTH
  } else {
    period = UNIFIEDDATE_VALUES.RANGEDAY
  }
  return (
    <Link
      className='team-member-name'
      to='/reporte-individual'
      state={{ teamMemberId: rowData?.teamMemberId, startDate, endDate, period }}
      // /${rowData?.teamMemberId}/${startDate}/${endDate}/${period}`
      title='Ir al reporte individual ↗'
    >
      <div className='flex align-items-center'>
        <span className='white-space-nowrap px-2'>{rowData.teamMemberTagName} - {rowData.teamMemberName} {rowData.teamMemberLastName.split(' ')[0]}</span>
      </div>
    </Link>
  )
}

const ColumnContent = (
  WEEK_DATES,
  setModalData,
  setIsTimeDiffDetailsModalOpen
) => {
  return [
    // Team member column
    {
      body: teamMemberNameTemplate,
      style: { padding: '7px 7px 7px 0px', width: '400px', minWidth: '28ch' },
      field: 'teamMemberTagName',
      header: 'Integrante',
      filterMatchMode: 'in'
    },
    // Week days columns
    ...WEEK_DATES?.map((date) => {
      const isWeekend =
        new Date(`${date}T00:00:00`).toLocaleDateString('es-Mx', { weekday: 'long' }) === 'sábado' ||
        new Date(`${date}T00:00:00`).toLocaleDateString('es-Mx', { weekday: 'long' }) === 'domingo'
      let dayName = DateTime.fromISO(date).setLocale('es').startOf('day').toFormat('cccc')
      dayName = capitalizeFirstLetter(dayName)
      return {
        key: date,
        field: `week.${date}.totalSisuTime`,
        header: dayName,
        body: (rowData) => {
          return (
            <span className='flex align-items-center gap-1'>
              {rowData?.week?.[date]?.totalSisuTime || '-'}
              <CellReportBadges date={date} rowData={rowData} />
              {
                (isWeekend && rowData?.week?.[date]?.totalSisuTime) && (
                  <TimeDifferenceBadge
                    // timeDifference={isWeekend}
                    value={rowData?.week?.[date]?.totalSisuTime}
                    minTreshold={0}
                    maxTreshold={0}
                    tooltip={'Tiempos registrados fuera de la semana laboral recomendada'}
                    className='m-0'
                  />
                )
              }
            </span>
          )
        }
      }
    }),
    // Total column
    {
      field: 'weekSisuTime',
      header: 'Total',
      sortable: true,
      body: (rowData) => {
        const isBeforeCurrentWeek = DateTime.fromISO(Object.keys(rowData?.week)[0]).startOf('week') < DateTime.local().startOf('week')
        const teamMemberWeek = rowData?.week
        const nonWorkingDays = Object.keys(teamMemberWeek)?.reduce((acc, date) => {
          if (teamMemberWeek[date].totalSisuTime === 0) {
            acc.push(date)
          }
          return acc
        }, [])

        const NORMAL_WEEK_HOURS = Object.keys(teamMemberWeek)?.length * 8
        const realWorkedTimeAtWeek = NORMAL_WEEK_HOURS - nonWorkingDays.length * 8
        return (
          <span className='number-with-danger-badge flex align-items-center gap-1'>
            {rowData?.weekSisuTime || '-'}
            {
              (rowData?.weekSisuTime && isBeforeCurrentWeek)
                ? (
                  <UnexpectedTimeBadge
                    value={rowData?.weekSisuTime}
                    expectedValue={realWorkedTimeAtWeek}
                    minTreshold={0.5}
                    maxTreshold={1}
                    referenceValue={realWorkedTimeAtWeek}
                    tooltips={{
                      isLess: 'Se registraron menos horas de las esperadas',
                      isMore: 'Se registraron más horas de las esperadas'
                    }}
                    tooltipProps={{
                      position: 'bottom'
                    }}
                  />
                )
                : null
            }
          </span>
        )
      }
    },
    {
      field: 'weekClientBilledTime',
      header: 'Horas facturadas'
    },
    {
      field: 'weekTimeDifference',
      header: 'Diferencia',
      body: (rowData) => {
        let percentage = 0
        let total = 0
        let comments = []

        if (rowData?.taskTimeDifferenceComments !== undefined && rowData?.taskTimeDifferenceComments.length !== 0) {
          percentage = formatPercentageSuffix(rowData?.weekTimeDifference / rowData?.weekClientTime)
          total = rowData?.condensedTimeDifferenceData?.total

          const sortedData = rowData?.condensedTimeDifferenceData?.items.map((el) => ({ ...el, key: el.project + el.client }))
            .sort((a, b) => a.client.localeCompare(b.client) || a.key.localeCompare(b.key))
          comments = sortedData.map(({ client, project, time }) => {
            return {
              project: <div style={{ width: '85px', whiteSpace: 'nowrap' }}>
                <ClientProjectChip clientCode={client} projectCode={project} />
              </div>,
              hours: time
            }
          })
        }

        let onClick
        if (rowData?.taskTimeDifferenceComments.length !== 0) {
          const { teamMemberName, teamMemberLastName } = rowData
          const fullName = `${teamMemberName} ${teamMemberLastName}`
          const timeDifferences = rowData?.taskTimeDifferenceComments.map(el => {
            const [client, project] = el.project.split(' - ')
            return {
              client: client.trim(),
              project: project.trim(),
              time: el.time.trim(),
              comment: el.comment.trim()
            }
          })

          onClick = () => {
            setModalData({ fullName, timeDifferences, total })
            setIsTimeDiffDetailsModalOpen(true)
          }
        }

        return (
          <span className="flex justify-content-between align-items-center w-full">
            <span className="w-4 text-left">
              {rowData?.weekClientTime > 0 ? rowData?.weekTimeDifference : '0'}
            </span>
            {rowData?.weekTimeDifference !== 0 && (
              <span className="flex-grow-1 text-left">
                <TimeDifferenceBadge
                  value={Math.abs(rowData?.weekTimeDifference)}
                  minTreshold={0}
                  maxTreshold={0}
                  badgeProps={{
                    onClick,
                    style: {
                      cursor: onClick ? 'pointer' : 'default'
                    }
                  }}
                  referenceValue={Math.abs(rowData?.weekTimeDifference) - 1}
                  percentage={percentage}
                  tooltip={
                    <DifferenceReasonTable
                      header={['Proyecto', 'Horas']}
                      total={total}
                      body={comments}
                    />
                  }
                />
              </span>
            )}
          </span>
        )
      }
    },
    {
      field: 'ocupationRatio',
      header: 'Ocupación',
      body: (rowData) => percentageBody(rowData, 'ocupationRatio')
    }
  ]
}

export default ColumnContent
