/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomTreeTable as TreeTable } from '../../../components/atoms/table/CustomTreeTable.atom'
import Column from '../../../components/atoms/table/Column.atom'
import { getClients } from '../../../services/client.service'
import ClientChip from '../../../components/molecules/ClientChip.molecule'
import ProjectChip from '../../../components/molecules/ProjectChip.molecule'
import Dropdown from '../../../components/atoms/Dropdown.atom'
import ClientsTableContextMenu from './components/ClientsTableContextMenu'
import ColumnGroup from '../../../components/atoms/table/ColumnGroup.atom'
import Row from '../../../components/atoms/table/RowColumnGroup.atom'
import ActionModal from './ActionModal.controller'
import useClientColorsContext from '../../../hooks/contextConsumers/useClientColorsContext.hook'
import Card from '../../../components/atoms/Card.atom'
import { getBudgetPhaseFromPercentage } from '../../../utils/budget.utils'
import { CLIENT_BUDGET_PHASES } from '../../../consts/modules/budget.consts'
import ReportBadgeWithTooltip from '../../../components/molecules/ReportBadgeWithTooltip.molecule'
import { useSession } from '../../../hooks/auth/useSession.hook'

const ClientsTable = () => {
  const [loading, setLoading] = useState(false)
  const [prefilteredClients, setPrefilteredClients] = useState([])
  const [filter, setFilter] = useState(1)
  const [datatableFilter, setDatatableFilter] = useState({
    'data.active': {
      value: 1,
      matchMode: 'equals'
    }
  })
  const [clients, setClients] = useState([])
  const [expandedKeys, setExpandedKeys] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [modalAction, setModalAction] = useState(null)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const filterApplyCallbackRef = useRef()
  const cmRef = useRef()
  const { setRefresh } = useClientColorsContext()
  const navigate = useNavigate()
  const { user } = useSession()
  useEffect(() => {
    gettingClients()
    setRefresh(prev => !prev)
  }, [])

  useEffect(() => {
    if (isFirstRender && clients.length > 0 && filterApplyCallbackRef.current) {
      filterApplyCallbackRef.current(1)
      setIsFirstRender(false)
      setPrefilteredClients(clients.filter(client => client.data.active === 1))
    }
  }, [clients])

  useEffect(() => {
    if (modalAction && modalAction === 'reactivate') {
      gettingClients()
    }
  }, [modalAction])

  const gettingClients = async () => {
    setLoading(true)
    const response = await getClients()
    if (response.success) {
      setClients(response.result)
    }
    setLoading(false)
  }

  const statusFilterElement = (options) => {
    const id = `status-filter-${new Date().getTime()}`
    if (isFirstRender) {
      filterApplyCallbackRef.current = options.filterApplyCallback
    }
    return (
      <Dropdown
        id={id}
        inputId={`input-${id}`}
        value={options.value}
        options={[
          { label: 'Activo', value: 1 },
          { label: 'Inactivo', value: 0 }
        ]}
        onChange={(e) => {
          options.filterCallback(e.value)
          setFilter(e.value)
        }}
        optionLabel="label"
        optionValue="value"
        placeholder="Filtrar estatus"
      />
    )
  }

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Clientes / Proyectos" colSpan={2} sortable sortField='data.code' />
        <Column header="ID" sortable sortField='data.clientId' />
        <Column header="Etapa del presupuesto" sortable sortField='data.consumedTimePercentage' />
        <Column
          header="Estatus"
          filter
          filterField='data.active'
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterElement={statusFilterElement}
          filterMatchMode="equals"
          onFilterClear={() => setFilter(null)}
          onFilterApplyClick={() => {
            setDatatableFilter({
              'data.active': {
                value: filter,
                matchMode: 'equals'
              }
            })
          }}
        />
        <Column />
      </Row>
    </ColumnGroup>
  )

  const getProjectsAndClientsFromRowEvent = (node) => {
    let clientId = node?.data?.clientId
    let projectsId = [node?.data?.projectId]
    if (projectsId && !clientId) {
      clientId = clients.find(client => client.children.find(project => project.data.projectId === projectsId[0])).data.clientId
    } else if (!projectsId[0] && clientId) {
      projectsId = clients.find(client => client.data.clientId === clientId).children.map(project => project.data.projectId)
    }
    return { clientId, projectsId }
  }
  return (
    <Card id='clients-table' className='p-1 shadow-none'>
      <TreeTable
        value={isFirstRender ? prefilteredClients : clients}
        filters={datatableFilter}
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        removableSort
        headerColumnGroup={headerGroup}
        expanderConditions={(node) => {
          if (filter === null) {
            return node?.children?.length > 1
          } else if (filter === 0) {
            return (node?.children?.length > 1 && node?.children?.some(project => project.data.active === 0))
          } else {
            return (node?.children?.length > 1 && node?.children?.some(project => project.data.active === 1))
          }
        }}
        onContextMenu={({ originalEvent, data: node }) => {
          const { projectsId } = getProjectsAndClientsFromRowEvent(node)
          const isOptionEnabled = user.isAdmin() || (user.isTeamLeader() && user.leaderProjects.some(projectId => projectsId.includes(projectId)))
          if (!isOptionEnabled) {
            cmRef?.current.hide()
            return
          }
          cmRef?.current.show(originalEvent)
          setSelectedRow({
            ...node,
            id: node?.data?.clientId || node?.data?.projectId,
            type: node?.data?.projectId ? 'project' : 'client'
          })
        }}
        loading={loading}
        rowClassName={(node) => {
          const { projectsId } = getProjectsAndClientsFromRowEvent(node)
          const isOptionEnabled = user.isAdmin() || (user.isTeamLeader() && user.leaderProjects.some(projectId => projectsId.includes(projectId)))
          return !isOptionEnabled ? 'opacity-50' : ''
        }}
        loadingConfig={{
          qtyOfRows: 5
        }}
      >
        <Column
          field="data.code"
          body={(node) => {
            if (node?.children?.length) {
              return (
                <div className='flex gap-2 align-items-center'>
                  <ClientChip
                    className='w-fit'
                    clientCode={node?.data?.code}
                  />
                  <span className='font-bold'>{node?.data?.name}</span>
                </div>
              )
            } else {
              return (
                <div className='flex gap-2 align-items-center'>
                  <ProjectChip
                    className='w-fit'
                    clientCode={node?.data?.clientCode}
                    projectCode={node?.data?.code}
                  />
                  <span>{node?.data?.name?.split('-')[1]}</span>
                </div>
              )
            }
          }}
          expander
          sortable
          style={{ width: '45%' }}
        />
        <Column
          colSpan={1}
          style={{ width: '18%' }}
          body={(rowData) => {
            return rowData?.data?.projectId || rowData?.data?.clientId
          }}
        />
        <Column
          field="data.consumedTimePercentage"
          sortable
          colSpan={1}
          style={{ width: '18%' }}
          body={(rowData) => {
            const data = rowData.data
            if (data?.projectId || data?.
              clientBudgetTypeId !== 1) return
            if (data?.clientId === 1) return 'N/A'
            if (!data?.hasBudget) {
              return (
                <div className='flex'>
                  <span>{CLIENT_BUDGET_PHASES.SIN_PRESUPUESTO}</span>
                  <ReportBadgeWithTooltip
                    type='danger'
                    iconClassName='pi pi-exclamation-triangle text-white'
                    tooltipValue={'Hay horas registradas antes de la asignación del presupuesto'}
                    className={'ml-1'}
                    tooltipProps={{ position: 'bottom' }}
                  />
                </div>
              )
            }
            const remainingPercentage = 100 - data?.consumedTimePercentage
            const phase = getBudgetPhaseFromPercentage(remainingPercentage)
            return <span className={`${phase === CLIENT_BUDGET_PHASES.SOBREGIRADO ? 'clr-danger font-semibold' : ''}`}>{phase}</span>
          }}
        />
        <Column
          field="data.active"
          body={(node) => node?.data?.active ? 'Activo' : 'Inactivo'}
          filterMatchMode="equals"
          colSpan={1}
          style={{ width: '18%' }}
        />
        <Column
          body={(node) => {
            const { projectsId } = getProjectsAndClientsFromRowEvent(node)
            const isOptionEnabled = user.isAdmin() || (user.isTeamLeader() && user.leaderProjects.some(projectId => projectsId.includes(projectId)))
            if (!isOptionEnabled) return
            return (
              <i
                className='pi pi-ellipsis-v cursor-pointer px-3'
                onClick={(event) => {
                  cmRef?.current?.show(event)
                  setSelectedRow({
                    ...node,
                    id: node?.data?.clientId || node?.data?.projectId,
                    type: node?.data?.projectId ? 'project' : 'client',
                    // eslint-disable-next-line
                    isClientActive: node?.data?.projectId ? clients.find(client => client.data.clientId == node?.parentKey)?.data?.active : node?.data?.active
                  })
                }}
              />
            )
          }}
        />
      </TreeTable>
      <ClientsTableContextMenu
        selectedRow={selectedRow}
        ref={cmRef}
        setOpenModal={setOpenModal}
        setModalAction={setModalAction}
        onEdit={() => navigate(`/administracion/clientes/editar`, { state: { clientId: selectedRow?.data?.clientId } })}
      />
      <ActionModal
        row={selectedRow}
        visible={openModal}
        setVisible={setOpenModal}
        modalAction={modalAction}
        onFinishedSubmit={gettingClients}
      />
    </Card>
  )
}

export default ClientsTable
