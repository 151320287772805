import React from 'react'
import ContentLayoutHeader from '../../../components/layouts/ContentLayoutHeader.layout'

const OccupationReportHeader = () => {
  return (
    <ContentLayoutHeader
      title='Reporte de ocupación'
      buttonProps={{
        hidden: true
      }}
    />
  )
}

export default OccupationReportHeader
