import React from 'react'
import InputPassword from '../atoms/InputPassword.atom'
import PropTypes from 'prop-types'
import { hasAnyErrors } from '../../utils/validations.utils'
import ErrorMessageForm from '../atoms/ErrorMessageForm.atom'
import Label from '../atoms/Label.atom'

export default function InputPasswordForm (props) {
  const forId = `input-password-${props.label?.replace(/ /g, '-')}`
  return (
    <>
      <Label htmlFor={forId}>{props.label}</Label>
      <InputPassword
        inputId={forId}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        className={hasAnyErrors(props.error) ? 'p-invalid' : ''}
      />
      { hasAnyErrors(props.error) ? <ErrorMessageForm errorMessage={props.error[0]?.errorMessage }/> : null}
    </>
  )
}

InputPasswordForm.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.array
}
