import React, { useState, useEffect } from 'react'
import { ConfigProvider, Select } from 'antd'
import esES from 'antd/lib/locale/es_ES'
import { DateTime } from 'luxon'
import MyDatePicker from '../atoms/DatePicker.atom'
import MyRangePicker from '../atoms/RangePicker.atoms'
import {
  UNIFIEDDATE_VALUES,
  UNIFIEDDATE_SELECTOR,
  UNIFIEDDATE_RANGE
} from '../../consts/CompoundRangeDatePicker'
import useIsHoldingCtrlDown from '../../hooks/useIsHoldingCtrlDown'
const { Option } = Select

function getPeriodSelector (period) {
  const stringArray = period.split('-')
  if (stringArray[0] === UNIFIEDDATE_SELECTOR.SINGLE) {
    return UNIFIEDDATE_SELECTOR.SINGLE
  } else if (stringArray[0] === UNIFIEDDATE_SELECTOR.RANGE) {
    return UNIFIEDDATE_SELECTOR.RANGE
  } else {
    return 'N/A'
  }
}

function getPeriodType (period) {
  const stringArray = period.split('-')
  return stringArray[1]
}

function defaultGetFormat (period) {
  let format

  switch (period) {
  case UNIFIEDDATE_VALUES.SINGLEDAY:
  case UNIFIEDDATE_VALUES.RANGEDAY:
    format = 'DD/MM/YYYY'
    break
  case UNIFIEDDATE_VALUES.SINGLEWEEK:
  case UNIFIEDDATE_VALUES.RANGEWEEK:
    format = '\'Semana\' wo\'/\'YY'
    break
  case UNIFIEDDATE_VALUES.SINGLEMONTH:
  case UNIFIEDDATE_VALUES.RANGEMONTH:
    format = 'MMMM/YY'
    break
  case UNIFIEDDATE_VALUES.SINGLEYEAR:
  case UNIFIEDDATE_VALUES.RANGEYEAR:
    format = 'YYYY'
    break
  default:
    format = 'DD/MM/YYYY'
    break
  }

  return format
}

const customRangePresets = () => {
  const today = DateTime.local().set({ hour: 12 })

  return [
    {
      label: 'Hoy',
      value: [
        today,
        today
      ]
    },
    {
      label: 'Semana actual',
      value: [
        today.startOf('week'),
        today.endOf('week')
      ]
    },
    {
      label: 'Semana anterior',
      value: [
        today.minus({ weeks: 1 }).startOf('week').set({ hour: 12 }),
        today.minus({ weeks: 1 }).endOf('week').set({ hour: 12 })
      ]
    },
    {
      label: 'Este mes',
      value: [
        today.startOf('month'),
        today.endOf('month')
      ]
    },
    {
      label: 'Mes anterior',
      value: [
        today.minus({ months: 1 }).startOf('month').set({ hour: 12 }),
        today.minus({ months: 1 }).endOf('month').set({ hour: 12 })
      ]
    },
    {
      label: 'Este año ',
      value: [
        today.startOf('year'),
        today.endOf('year')
      ]
    }
  ]
}

const labelRender = (props) => {
  const { label } = props

  if (label) {
    return label
  }
  return <span>No option match</span>
}

const UnifiedDatePicker = (props) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false)

  const period = props.period ?? UNIFIEDDATE_VALUES.SINGLEDAY
  const [periodSelector, setPeriodSelector] = useState(getPeriodSelector(period))
  const [periodRange, setPeriodRange] = useState(getPeriodType(period))
  const calendarInputId = props.calendarInputId ?? `calendar-input-${Math.random().toString(36).substring(7)}`
  const format = props.format !== undefined
    ? props.format
    : (
      props.getFormat !== undefined
        ? props.getFormat(period)
        : defaultGetFormat(period)
    )

  const isHoldingCtrlDown = useIsHoldingCtrlDown()

  useEffect(() => {
    const newPeriodSelector = getPeriodSelector(period)
    const newPeriodRange = getPeriodType(period)

    setPeriodSelector(newPeriodSelector)
    setPeriodRange(newPeriodRange)
  }, [period])

  const getOptions = (hasSingleDay, hasSingleWeek, hasSingleMonth, hasSingleYear, hasDayPeriod, hasWeekPeriod, hasMonthPeriod, hasYearPeriod) => {
    const options = []
    if (hasSingleDay) options.push({ label: props.singleDayLabel, value: UNIFIEDDATE_VALUES.SINGLEDAY })
    if (hasSingleWeek) options.push({ label: props.singleWeekLabel, value: UNIFIEDDATE_VALUES.SINGLEWEEK })
    if (hasSingleMonth) options.push({ label: props.singleMonthLabel, value: UNIFIEDDATE_VALUES.SINGLEMONTH })
    if (hasSingleYear) options.push({ label: props.singleYearLabel, value: UNIFIEDDATE_VALUES.SINGLEYEAR })
    if (hasDayPeriod) options.push({ label: props.dayPeriodLabel, value: UNIFIEDDATE_VALUES.RANGEDAY })
    if (hasWeekPeriod) options.push({ label: props.weekPeriodLabel, value: UNIFIEDDATE_VALUES.RANGEWEEK })
    if (hasMonthPeriod) options.push({ label: props.monthPeriodLabel, value: UNIFIEDDATE_VALUES.RANGEMONTH })
    if (hasYearPeriod) options.push({ label: props.yearPeriodLabel, value: UNIFIEDDATE_VALUES.RANGEYEAR })
    return options
  }

  const selectOptions = getOptions(props.hasSingleDay, props.hasSingleWeek, props.hasSingleMonth, props.hasSingleYear,
    props.hasDayPeriod, props.hasWeekPeriod, props.hasMonthPeriod, props.hasYearPeriod)

  const getOrder = (hasSingleDay, hasSingleWeek, hasSingleMonth, hasSingleYear, hasDayPeriod, hasWeekPeriod, hasMonthPeriod, hasYearPeriod) => {
    const order = []
    if (hasSingleDay) order.push(UNIFIEDDATE_VALUES.SINGLEDAY)
    if (hasSingleWeek) order.push(UNIFIEDDATE_VALUES.SINGLEWEEK)
    if (hasSingleMonth) order.push(UNIFIEDDATE_VALUES.SINGLEMONTH)
    if (hasSingleYear) order.push(UNIFIEDDATE_VALUES.SINGLEYEAR)
    if (hasDayPeriod) order.push(UNIFIEDDATE_VALUES.RANGEDAY)
    if (hasWeekPeriod) order.push(UNIFIEDDATE_VALUES.RANGEWEEK)
    if (hasMonthPeriod) order.push(UNIFIEDDATE_VALUES.RANGEMONTH)
    if (hasYearPeriod) order.push(UNIFIEDDATE_VALUES.RANGEYEAR)
    return order
  }

  const nextInOrder = (currentPeriod) => {
    const index = order.indexOf(currentPeriod)
    return order[index + 1] ?? order[0]
  }

  const order = getOrder(props.hasSingleDay, props.hasSingleWeek, props.hasSingleMonth, props.hasSingleYear, props.hasDayPeriod, props.hasWeekPeriod, props.hasMonthPeriod, props.hasYearPeriod)

  const defaultOnPeriodChange = (newPeriod) => {
    const [startDate, endDate] = props.dateRange
    let newDateRange = [startDate, startDate]

    switch (newPeriod) {
    case UNIFIEDDATE_VALUES.SINGLEDAY:
      newDateRange = [startDate.set({ hour: 12 }), startDate.set({ hour: 12 })]
      break
    case UNIFIEDDATE_VALUES.SINGLEWEEK:
      newDateRange = [startDate.startOf('week').set({ hour: 12 }), startDate.endOf('week').set({ hour: 12 })]
      break
    case UNIFIEDDATE_VALUES.SINGLEMONTH:
      newDateRange = [startDate.startOf('month').set({ hour: 12 }), startDate.endOf('month').set({ hour: 12 })]
      break
    case UNIFIEDDATE_VALUES.RANGEDAY:
      newDateRange = [startDate.set({ hour: 12 }), endDate.set({ hour: 12 })]
      break
    case UNIFIEDDATE_VALUES.RANGEWEEK:
      newDateRange = [startDate.startOf('week').set({ hour: 12 }), endDate.endOf('week').set({ hour: 12 })]
      break
    case UNIFIEDDATE_VALUES.RANGEMONTH:
      newDateRange = [startDate.startOf('month').set({ hour: 12 }), endDate.endOf('month').set({ hour: 12 })]
      break
    case UNIFIEDDATE_VALUES.RANGEYEAR:
      newDateRange = [startDate.startOf('year').set({ hour: 12 }), endDate.endOf('year').set({ hour: 12 })]
      break
    default:
      console.error('Invalid period in UnifiedDatePicker', newPeriod)
    }

    props.setDateRange(newDateRange)
    props.setPeriod(newPeriod)
  }

  const onSingleDateChange = (newDate) => {
    let newDateRange = [newDate, newDate]

    switch (periodRange) {
    case UNIFIEDDATE_RANGE.DAY:
      newDateRange = [newDate.set({ hour: 12 }), newDate.set({ hour: 12 })]
      break
    case UNIFIEDDATE_RANGE.WEEK:
      newDateRange = [newDate.startOf('week').set({ hour: 12 }), newDate.endOf('week').set({ hour: 12 })]
      break
    case UNIFIEDDATE_RANGE.MONTH:
      newDateRange = [newDate.startOf('month').set({ hour: 12 }), newDate.endOf('month').set({ hour: 12 })]
      break
    case UNIFIEDDATE_RANGE.YEAR:
      newDateRange = [newDate.startOf('year').set({ hour: 12 }), newDate.endOf('year').set({ hour: 12 })]
      break
    default:
      console.error('Invalid period in UnifiedDatePicker', period)
    }
    props.setDateRange(newDateRange)
  }

  return (
    <ConfigProvider locale={esES}>
      {(props.hidePeriodSelector !== undefined && props.hidePeriodSelector) || props.setPeriod === undefined
        ? null
        : <Select
          className={`${props.overridePeriodClassName !== undefined ? props.overridePeriodClassName : 'range-date-calendar__select'} 
          ${isHoldingCtrlDown ? 'ctrl-selectable-range-selector' : ''}`}
          value={period}
          options={selectOptions}
          onChange={defaultOnPeriodChange}
          open={isSelectorOpen}
          onDropdownVisibleChange={(visible) => {
            if (!isHoldingCtrlDown) {
              setIsSelectorOpen(visible)
            }
          }}
          onClick={() => {
            if (isHoldingCtrlDown) {
              defaultOnPeriodChange(nextInOrder(period))
            }
          }}
          suffixIcon={<span className="p-dropdown-trigger-icon p-clickable pi pi-chevron-down"></span>}
          {...props.selectProps}
          labelRender={labelRender}
        >
          {props.hasSingleDay ? <Option value={UNIFIEDDATE_VALUES.SINGLEDAY} label={props.singleDayLabel} /> : null}
          {props.hasSingleWeek ? <Option value={UNIFIEDDATE_VALUES.SINGLEWEEK} label={props.singleWeekLabel} /> : null}
          {props.hasSingleMonth ? <Option value={UNIFIEDDATE_VALUES.SINGLEMONTH} label={props.singleMonthLabel} /> : null}
          {props.hasSingleYear ? <Option value={UNIFIEDDATE_VALUES.SINGLEYEAR} label={props.singleYearLabel} /> : null}
          {props.hasDayPeriod ? <Option value={UNIFIEDDATE_VALUES.RANGEDAY} label={props.dayPeriodLabel} /> : null}
          {props.hasWeekPeriod ? <Option value={UNIFIEDDATE_VALUES.RANGEWEEK} label={props.weekPeriodLabel} /> : null}
          {props.hasMonthPeriod ? <Option value={UNIFIEDDATE_VALUES.RANGEMONTH} label={props.monthPeriodLabel} /> : null}
          {props.hasYearPeriod ? <Option value={UNIFIEDDATE_VALUES.RANGEYEAR} label={props.yearPeriodLabel} /> : null}
        </Select>
      }
      {periodSelector === UNIFIEDDATE_SELECTOR.SINGLE
        ? (
          <MyDatePicker
            id={calendarInputId}
            inputReadOnly
            className={`${props.overrideDatePickerClassName !== undefined
              ? props.overrideDatePickerClassName
              : 'range-date-calendar__date-picker'}`}
            value={props.dateRange[0]}
            onChange={onSingleDateChange}
            picker={periodRange}
            format={format}
            allowClear={false}
            separator={props.separator !== undefined ? props.separator : <p className='clr-secondary'>-</p>}
            {...props.datePickerProps}
          />)
        : null
      }
      {periodSelector === UNIFIEDDATE_SELECTOR.RANGE
        ? (<MyRangePicker
          id={calendarInputId}
          inputReadOnly
          className={`${props.overrideRangePickerClassName !== undefined
            ? props.overrideRangePickerClassName
            : 'range-date-calendar__range-picker'}`}
          value={props.dateRange}
          onChange={props.setDateRange}
          picker={periodRange}
          format={format}
          disabledDate={props.disabledDate}
          allowClear={false}
          separator={props.separator !== undefined ? props.separator : <p className='clr-secondary'>-</p>}
          presets={periodRange === UNIFIEDDATE_RANGE.DAY ? customRangePresets() : undefined}
          {...props.datePickerProps}
        />)
        : null
      }
    </ConfigProvider>
  )
}

UnifiedDatePicker.defaultProps = {
  hasSingleDay: true,
  singleDayLabel: 'Diario',
  hasSingleWeek: true,
  singleWeekLabel: 'Semanal',
  hasSingleMonth: true,
  singleMonthLabel: 'Mensual',
  hasSingleYear: false,
  singleYearLabel: 'Anual',
  hasDayPeriod: true,
  dayPeriodLabel: 'Personalizado',
  hasWeekPeriod: false,
  weekPeriodLabel: 'Rango semanal',
  hasMonthPeriod: false,
  monthPeriodLabel: 'Rango mensual',
  hasYearPeriod: false,
  yearPeriodLabel: 'Rango anual'
}

export default UnifiedDatePicker
