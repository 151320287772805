import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'
import DataTable from '../../../components/atoms/table/DataTable.atom'
import Column from '../../../components/atoms/table/Column.atom'
import { getBudgetMovementsByClientID } from '../../../services/budget.service'
import { useLocation } from 'react-router-dom'
import Card from '../../../components/atoms/Card.atom'
import { DateTime } from 'luxon'
import { numberFormatter } from '../../../utils/numbers.utils'
import IconWithTooltip from '../../../components/molecules/IconWithTooltip.molecule'
import ColumnGroup from '../../../components/atoms/table/ColumnGroup.atom'
import RowColumnGroup from '../../../components/atoms/table/RowColumnGroup.atom'
import TendencyChip from '../components/TendencyChip'
import ModalFormAddBudget from '../../../components/compounds/ModalFormAddBudget.compound'
import { useSession } from '../../../hooks/auth/useSession.hook'

const BudgetMovements = ({
  refreshData
}) => {
  const [budgetMovements, setBudgetMovements] = useState([])
  const [currentBudget, setCurrentBudget] = useState(0)
  const [openModalForm, setOpenModalForm] = useState(false)
  const { state: selectedClient } = useLocation()
  const { user } = useSession()

  useEffect(() => {
    getBudgetMovements()
  }, [])

  const getBudgetMovements = async () => {
    try {
      const response = await getBudgetMovementsByClientID(selectedClient.id)
      if (!response.success) {
        throw new Error()
      }
      const data = response.result
      setBudgetMovements(data)
      setCurrentBudget(data.reduce((acc, curr) => {
        return acc + Number(curr.quantity)
      }, 0))
    } catch (error) {
      console.log(error)
    }
  }

  const HeaderTemplate = () => {
    return (
      <div className='flex justify-content-between align-items-center mb-3'>
        <span className='text-3xl font-cairo font-semibold'>Movimientos</span>
        {
          user.isAdmin() &&
          <PrimaryButton
            label='Agregar registro'
            icon='pi pi-plus'
            onClick={() => setOpenModalForm(true)}
          />
        }
      </div>
    )
  }

  const footerColumnGroup = () => {
    const totalSum = budgetMovements.reduce((acc, curr) => {
      return acc + Number(curr.quantity)
    }, 0)
    return (
      <ColumnGroup>
        <RowColumnGroup>
          <Column footer='Total' colSpan={4} />
          <Column footer={budgetMovements?.length === 0 ? '-' : numberFormatter(totalSum)} />
        </RowColumnGroup>
      </ColumnGroup>
    )
  }
  const MovementsTable = ({ data }) => {
    return (
      <DataTable
        className='budget-movements-table'
        scrollable
        scrollHeight='100%'
        value={data}
        footerColumnGroup={footerColumnGroup()}
        style={{
          minHeight: data?.length === 0 ? '275px' : '115px',
          height: '100%'
        }}
      >
        <Column
          field='createdAt'
          header='Fecha de registro'
          body={(rowData) => {
            const formattedDate = DateTime.fromISO(rowData.createdAt).toFormat('dd/LL/yyyy HH:mm')
            return formattedDate
          }}
        />
        <Column
          field='createdBy'
          header='Creador'
        />
        <Column
          field='description'
          header='Motivo'
        />
        <Column
          field='authorizationDate'
          header={
            <div className='flex gap-1 align-items-center'>
              <span>Fecha de autorización</span>
              <IconWithTooltip
                iconClassName='pi pi-exclamation-circle'
                tooltipValue='Fecha en la que se realizó el acuerdo con el cliente, no afecta la vigencia del presupuesto.'
                position='bottom'
              />
            </div>
          }
          body={(rowData) => {
            const formattedDate = DateTime.fromISO(rowData.createdAt).toFormat('dd/LL/yyyy')
            return formattedDate
          }}
        />
        <Column
          field='quantity'
          header={<>Monto <span className='font-normal'>(horas)</span></>}
          body={(rowData) => {
            const isPositive = rowData.quantity >= 0
            return (
              <div className='flex gap-1'>
                <TendencyChip
                  severity={isPositive ? 'success' : 'danger'}
                />
                <span>
                  {numberFormatter(Math.abs(rowData.quantity))}
                </span>
              </div>
            )
          }}
        />
      </DataTable>
    )
  }
  return (
    <Card className={'dashboard-card budget-movements-card shadow-2 min-h-0'}>
      <HeaderTemplate />
      <MovementsTable
        data={budgetMovements}
      />
      <ModalFormAddBudget
        visible={openModalForm}
        setVisible={setOpenModalForm}
        selectedClientID={selectedClient.id}
        onCreateCompleted={() => {
          getBudgetMovements()
          refreshData()
        }}
        currentBudget={currentBudget}
        currentConsumedTime={selectedClient.totalBilledTime}
      />
    </Card>
  )
}

export default BudgetMovements

BudgetMovements.propTypes = {
  refreshData: PropTypes.func
}
