import React from 'react'
import BasicTooltipFact from './BasicTooltipFact'
import { TYPE_OF_TOOLTIP_COLOR_VARIANTS } from '../../../../consts/modules/graphics.consts'

const TooltipMultipleDefault = ({
  data,
  tooltipUnit = '',
  yLabel = '',
  colorType = TYPE_OF_TOOLTIP_COLOR_VARIANTS.square,
  valueFormatter,
  nameFormatter
}) => {
  if (!data?.map) return null
  return (
    <div className="tooltip-container">
      {yLabel ? <h6 className='tooltip-label'>{yLabel || ''}</h6> : null}
      {
        data?.map((dataRow, index) => {
          return (
            <BasicTooltipFact
              className="tooltip-data-container-multiple"
              key={`${dataRow?.name}-${index}`}
              color={dataRow?.color}
              name={dataRow?.name}
              value={dataRow?.value}
              tooltipUnit={tooltipUnit}
              colorType={colorType}
              valueFormatter={valueFormatter || undefined}
              nameFormatter={nameFormatter || undefined}
            />
          )
        })
      }
    </div>
  )
}

export default TooltipMultipleDefault
