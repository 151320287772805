import React from 'react'
import PropTypes from 'prop-types'
import ClientChip from '../../../molecules/ClientChip.molecule'
import { numberFormatter } from '../../../../utils/numbers.utils'

const ClientBadgeListLegend = ({
  data,
  onMouseEnter,
  onMouseLeave
}) => {
  const uniqueElements = data.filter((item, index, self) =>
    index === self.findIndex((obj) => obj.name === item.name)
  )
  return (
    <div
      className='custom-recharts-legend flex flex-column gap-2'
      onMouseLeave={onMouseLeave}
    >
      {
        uniqueElements?.sort((a, b) => b.value - a.value).map(item => (
          <div
            key={item.name}
            className='flex gap-2 align-items-center'
            onMouseEnter={onMouseEnter}
            data-name={item.name}
          >
            <ClientChip
              clientCode={item.name}
            />
            {numberFormatter(item.value)} horas
          </div>
        ))
      }
    </div>
  )
}

export default ClientBadgeListLegend

ClientBadgeListLegend.propTypes = {
  data: PropTypes.array,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
}
