import React from 'react'
import Chip from '../atoms/misc/Chip.atom'
import Tooltip from '../atoms/Tooltip.atom'
import useClientColorsContext from '../../hooks/contextConsumers/useClientColorsContext.hook'
import { backwardCompatibleClientProjectLabel, formatToHtmlId } from '../../utils/strings.utils'

/**
 * @typedef {JSX.IntrinsicAttributes & JSX.IntrinsicElements['div']} JSXDivElement
 */

/**
 * @typedef {import("primereact/chip").ChipProps & JSXDivElement & {
*   code: string,
*   labelClassname?: string,
*   className?: string
* }} ClientChipProps
*/

/**
* @param {ClientChipProps} props
* @returns {JSX.Element}
*/
const ClientProjectChip = ({
  clientCode,
  projectCode,
  onClientClick,
  onProjectClick,
  labelClassname = 'my-0 white-space-nowrap',
  className = '',
  showTooltip = true,
  ...props
}) => {
  if (!clientCode || !projectCode) return null
  const { colors } = useClientColorsContext()
  const client = colors.find(client => client.code === clientCode)
  const project = client?.projects.find(project => project.code === projectCode)
  const randomId = formatToHtmlId(`${clientCode}-${projectCode}-${Math.random().toString(36).substring(7)}`)

  return (
    <>
      <Chip
        className={`client-project-chip text-white overflow-hidden ${className} ${randomId}`}
        style={{
          ...props.style,
          borderColor: client?.color
        }}
        template={
          <>
            <div
              className='client flex-1 flex align-items-center justify-content-center'
              style={{
                backgroundColor: client?.color,
                padding: '0 6px',
                width: '50%'
              }}
              onClick={onClientClick}
            >
              <div
                className={`${labelClassname} font-bold m-0 ${clientCode?.length > 3 && 'overflow-hidden text-overflow-ellipsis'}`} style={{ fontSize: '12px', maxWidth: '4ch' }}
                title={clientCode?.length > 3 ? clientCode : ''}
              >{backwardCompatibleClientProjectLabel(clientCode)}</div>
            </div>
            <div
              className='project flex-1 flex align-items-center justify-content-start'
              style={{
                color: client?.color,
                padding: '0 6px',
                backgroundColor: '#FFF',
                width: '50%'
              }}
              onClick={onProjectClick}
            >
              <div
                className={`${labelClassname} font-bold m-0 ${projectCode?.length > 3 && 'overflow-hidden text-overflow-ellipsis'}`} style={{ fontSize: '12px', maxWidth: '4ch' }}
              >{backwardCompatibleClientProjectLabel(projectCode)}</div>
            </div>
          </>
        }
        {...props}
      />
      <Tooltip
        target={`.${randomId}`}
        position='top'
        style={{
          display: showTooltip ? 'block' : 'none'
        }}
      >
        <span className='font-bold'>{client?.name}</span>
        :
        <span>{project?.name}</span>
      </Tooltip>
    </>
  )
}

export default ClientProjectChip
