import React from 'react'
import Card from '../../../atoms/Card.atom'
import { Skeleton } from '../../../atoms/misc/Skeleton.atom'

const ContainerOfChart = ({
  withWhiteBackgroundColor = true,
  title = '',
  icon,
  iconClassName,
  children,
  className,
  shadow = true,
  style,
  loading
}) => {
  const DEFAULT_SHADOW = 'shadow-2'
  if (loading) {
    return (
      <Card className={`pie-container ${shadow ? DEFAULT_SHADOW : 'shadow-none'} ${className}`} style={style}>
        <Skeleton
          id='charts-skeleton'
          height={380}
        />
      </Card>
    )
  }
  if (withWhiteBackgroundColor) {
    return (
      <Card className={`pie-container ${shadow ? DEFAULT_SHADOW : 'shadow-none'} ${className}`} style={style}>
        {
          title &&
          (
            <div className='flex gap-2 align-items-center'>
              {
                iconClassName
                  ? <i className={`${iconClassName} clr-primary-highlight`} />
                  : icon
              }
              <span style={{ fontSize: 'min(3.25vw, 18px)', lineHeight: '1' }} className='font-semibold'>{title}</span>
            </div>
          )
        }
        {children}
      </Card>
    )
  }
  return children
}

export default ContainerOfChart
