import React from 'react'
import { formatDecimals, numberFormatter } from '../../../utils/numbers.utils'
import { SHORT_TO_LONG_MONTHS } from '../../../consts/date.consts'
import PropTypes from 'prop-types'
import { OCCUPATION_PERCENTAGE_RANGE, OCCUPATION_PERCENTAGE_RANGE_COLORS } from '../../../consts/reports.consts'

const CustomTooltipTemplate = ({
  data,
  showOccupationBGcolor = false
}) => {
  const totalBilledTime = data?.find((item) => item?.name === 'Facturadas')?.value ?? data[0]?.extraInfo?.Facturadas
  const totalSisuTime = data?.find((item) => item?.name === 'Trabajadas')?.value ?? data[0]?.extraInfo?.Trabajadas
  const occupationPercentage = totalBilledTime / totalSisuTime * 100

  const xAxisValue = data?.[0]?.xAxisValue
  const month = xAxisValue?.split('/')[0]
  const year = xAxisValue?.split('/')[1]

  let occupationPercentageColor = OCCUPATION_PERCENTAGE_RANGE_COLORS.OPTIMO
  if (occupationPercentage >= OCCUPATION_PERCENTAGE_RANGE.OPTIMO) {
    occupationPercentageColor = OCCUPATION_PERCENTAGE_RANGE_COLORS.OPTIMO
  } else if (occupationPercentage >= OCCUPATION_PERCENTAGE_RANGE.ESTABLE) {
    occupationPercentageColor = OCCUPATION_PERCENTAGE_RANGE_COLORS.ESTABLE
  } else if (occupationPercentage >= OCCUPATION_PERCENTAGE_RANGE.INESTABLE) {
    occupationPercentageColor = OCCUPATION_PERCENTAGE_RANGE_COLORS.INESTABLE
  } else {
    occupationPercentageColor = OCCUPATION_PERCENTAGE_RANGE_COLORS.CRITICO
  }

  return (
    <div className='custom-hours-type-tooltip'>
      <h2>{SHORT_TO_LONG_MONTHS[month]} {year}</h2>
      <hr />
      <div className='badges-container'>
        {
          data?.filter(e => e?.name).map((item, index) => (
            <div key={index} className='flex justify-content-between'>
              <span className='hours-type-badge' style={{ backgroundColor: item.color, color: item.textColor }}>{item.name}</span>
              <span>{numberFormatter(item.value)}hs</span>
            </div>
          ))
        }
      </div>
      <hr />
      <div className='flex justify-content-between align-items-center'>
        <span>Ocupación</span>
        <span
          style={{
            backgroundColor: showOccupationBGcolor ? occupationPercentageColor : '',
            borderRadius: showOccupationBGcolor ? '4px' : '',
            padding: showOccupationBGcolor ? '4px' : ''
          }}
        >{numberFormatter(occupationPercentage)}%</span>
      </div>
    </div>
  )
}

export default CustomTooltipTemplate

CustomTooltipTemplate.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired
    })
  ),
  showOccupationBGcolor: PropTypes.bool
}
