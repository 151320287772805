import React from 'react'
import OccupationReportHeader from './controllers/OccupationReportHeader.controller'
import OccupationReportContent from './controllers/OccupationReportContent.controller'

const OccupationReport = () => {
  return (
    <>
      <OccupationReportHeader/>
      <OccupationReportContent/>
    </>
  )
}

export default OccupationReport
