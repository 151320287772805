import React from 'react'
import PropTypes from 'prop-types'
import Card from '../../../components/atoms/Card.atom'
import { DateTime } from 'luxon'
import ProgressBar from '../../../components/atoms/misc/ProgressBar.atom'
import { floatToPercentage, formatDecimals, numberFormatter } from '../../../utils/numbers.utils'
import { getBudgetProgressBarLabelFromPercentage } from '../../../utils/budget.utils'
import Chip from '../../../components/atoms/misc/Chip.atom'
import { ValueWithLabelContainer, ValueWithLabelTemplate } from '../components/ValueWithLabelElements'
import { Skeleton } from '../../../components/atoms/misc/Skeleton.atom'

const GeneralBudgetInformation = ({
  data,
  loading
}) => {
  const HeaderTemplate = () => {
    return (
      <div className='flex justify-content-between align-items-center'>
        <span className='text-3xl font-cairo font-semibold'>Información general</span>
      </div>
    )
  }

  const BudgetPhaseProgress = ({ value, hasBudget, loading, hasBilledTime }) => {
    const phaseInfo = getBudgetProgressBarLabelFromPercentage(value, hasBudget, hasBilledTime)
    const LegendTemplate = ({ color, label }) => {
      return (
        <div className='flex align-items-centera gap-2'>
          <Chip
            className='tendency-chip'
            style={{ backgroundColor: color }}
          />
          <span className='font-semibold'>{label}</span>
        </div>
      )
    }
    if (loading) {
      return (
        <div className='flex flex-column gap-2'>
          <span className='font-semibold'>Etapa del presupuesto</span>
          <Skeleton height={15}/>
          <Skeleton height={15} width={120} className='m-0'/>
        </div>
      )
    }
    return (
      <div className='flex flex-column gap-2' style={{ color: 'light-dark(var(--secondary-color-dark), var(--text-color)' }}>
        <span className='font-semibold'>Etapa del presupuesto</span>
        <ProgressBar
          style={{ height: '15px' }}
          value={formatDecimals(value)}
          showValue={false}
          color={phaseInfo.color}
        />
        <LegendTemplate
          color={phaseInfo.color}
          label={phaseInfo.label}
        />
      </div>
    )
  }
  return (
    <Card className={'dashboard-card shadow-2 min-h-fit max-h-fit'}>
      <div className='flex flex-column gap-3'>
        <HeaderTemplate />
        <ValueWithLabelContainer>
          <ValueWithLabelTemplate
            label='Presupuesto total'
            value={numberFormatter(data?.totalBudget) ?? '-'}
            loading={loading}
          />
          <ValueWithLabelTemplate
            label='Horas consumidas'
            value={numberFormatter(data?.totalBilledTime)}
            loading={loading}
          />
          <ValueWithLabelTemplate
            label='Horas disponibles'
            value={numberFormatter(data?.remainingBudget)}
            showWarningBadge={data?.remainingBudget < 0}
            loading={loading}
          />
          <ValueWithLabelTemplate
            label='Porcentaje consumido'
            value={data?.consumedTimePercentage ? floatToPercentage(data?.consumedTimePercentage / 100, 2, true) : null}
            loading={loading}
          />
          <ValueWithLabelTemplate
            label='Última modificación'
            value={data?.lastUpdate ? DateTime.fromISO(data?.lastUpdate).toFormat('dd/LL/yyyy') : null}
            loading={loading}
          />
        </ValueWithLabelContainer>
        <BudgetPhaseProgress
          value={Number(data?.totalBudget) ? 100 - data?.consumedTimePercentage : 0}
          hasBudget={data?.hasBudget}
          loading={loading}
          hasBilledTime={data?.totalBilledTime > 0}
        />
      </div>
    </Card>
  )
}

export default GeneralBudgetInformation

GeneralBudgetInformation.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool
}
