import React from 'react'

const ReleaseVersion = ({ ...props }) => {
  if (!process.env.REACT_APP_RELEASE_VERSION) return null
  return (
    <div
      {...props}
      className={`release-version-note ${props.className}`}
    >
      {process.env.REACT_APP_RELEASE_VERSION}
    </div>
  )
}

export default ReleaseVersion
