import React from 'react'
import PropTypes from 'prop-types'

const CustomLegendBadge = ({
  item,
  isDashed = false
}) => {
  return (
    <div className='custom-legend-badge-container flex align-items-center relative'>
      <div className={`lateral-line ${isDashed ? 'border-dash' : ''}`} style={{ borderColor: item.color }}/>
      <span className='hours-type-badge' style={{ backgroundColor: item.color, color: item?.textColor }}>{item.name}</span>
      <div className={`lateral-line ${isDashed ? 'border-dash' : ''}`} style={{ borderColor: item.color }}/>
    </div>
  )
}

export default CustomLegendBadge

CustomLegendBadge.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  }).isRequired,
  isDashed: PropTypes.bool
}
