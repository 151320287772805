import React from 'react'
import InputNumber from '../atoms/InputNumber.atom'
import { formatHours } from '../../utils/time.utils'
import Label from '../atoms/Label.atom'
import { hasAnyErrors } from '../../utils/validations.utils'
import ErrorMessageForm from '../atoms/ErrorMessageForm.atom'
import { formatToHtmlId } from '../../utils/strings.utils'

export default function InputTime ({
  value = 0,
  label,
  onChange,
  fullWidth = false,
  ...props
}) {
  const forId = formatToHtmlId(`input-time-input-${label}-${new Date().getTime()}`)
  return (
    <div className={`input-time relative flex flex-column ${fullWidth ? 'w-full' : ''}`}>
      <Label className={`${label ? 'mb-2' : ''}`} htmlFor={forId}> {label}{props.require ? ' *' : ''}{props.optional ? ' (Opcional)' : ''}</Label>
      <div className='relative'>
        <InputNumber
          inputId={forId}
          inputStyle={{ color: 'transparent' }}
          value={value || 0}
          onChange={(e) => {
            const eventValue = e.value
            if (eventValue === 'ArrowUp') {
              e.preventDefault()
              onChange({ value: value + 0.25 })
            } else if (eventValue === 'ArrowDown') {
              e.preventDefault()
              onChange({ value: value - 0.25 })
            } else {
              const roundedValue = Math.round(Number(eventValue) * 4) / 4
              onChange({ value: roundedValue })
            }
          }}
          className='w-full relative'
          min={0}
          step={0.25}
          format={true}
          showButtons
          minFractionDigits={0}
          maxFractionDigits={2}
          {...props}
        />
        <span
          key={value}
          className='custom-placeholder absolute flex gap-2 flex align-items-center'
          style={{
            top: '50%',
            transform: 'translateY(-50%)',
            left: '0.5rem'
          }}
        >
          <i className="pi pi-stopwatch"></i>
          {formatHours(value)}
        </span>
      </div>
      {hasAnyErrors(props.error) && !props.withoutErrorMessage ? <ErrorMessageForm errorMessage={props.error[0]?.errorMessage} /> : null}
    </div>
  )
}
