/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  getTaskChangelog as getTaskChangelogService
} from '../../../services/task.service'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'
import DataTable from '../../../components/atoms/table/DataTable.atom'
import Column from 'antd/es/table/Column'
import ClientProjectChip from '../../../components/molecules/ClientProjectChip.molecule'
import { DateTime } from 'luxon'
import Card from '../../../components/atoms/Card.atom'
import useDynamicHeight from '../../../hooks/useDynamicHeight2.hook'

const TaskChangeLogTable = ({
  task
}) => {
  const [loading, setLoading] = useState(true)
  const [taskChangelog, setTaskChangelog] = useState([])
  const [oldestTask, setOldestTask] = useState()
  const [newestTasks, setNewestTask] = useState()
  const fullHeight = useDynamicHeight({
    minHeight: 300,
    // maxHeight: 786
  })
  useEffect(() => {
    getTaskChangelog(task?.id)
  }, [task])
  const getTaskChangelog = async (taskId) => {
    setLoading(true)
    try {
      const response = await getTaskChangelogService(taskId)
      if (!response.success) {
        throw new Error()
      }
      setTaskChangelog(response.result)
      setOldestTask(response.result[response.result.length - 1])
      setNewestTask(response.result[0])
    } finally {
      setLoading(false)
    }
  }

  const groupHeaderTemplate = (data) => {
    const date = DateTime.fromISO(data.updatedAt).setLocale('es').toFormat("dd/MM/yyyy HH:mma")
    return (
      <div className='flex align-items-center pl-2 gap-2'>
        <i className="pi pi-users text-xl"/>
        <span className='font-bold'>{data.id === oldestTask?.id ? 'Creador' : 'Editor'}:</span>
        <span className='font-bold'>{data.teamMemberName} {data.teamMemberLastName}</span>
        <span style={{ fontSize: '12px' }}>{date}</span>
        <span className='font-bold' style={{ color: '#00B4FF', fontSize: '12px' }}>{data.id === oldestTask?.id ? '(Original)' : data.id === newestTasks?.id ? '(Actual)' : null}</span>
      </div>
    )
  }
  const renderHighlightedTimeChanges = (rowData, { rowIndex: index }, type) => {
    const previous = index < taskChangelog.length - 1 ? taskChangelog[index + 1][type] : null
    const isChanged = previous !== null && rowData[type] !== previous
    return (
      <div className={`${isChanged ? 'clr-danger font-bold' : '' }`}>
        {rowData[type]}
      </div>
    )
  }

  if (loading) {
    return (
      <div className='w-full h-20rem flex align-items-center justify-content-center'>
        <ProgressSpinner />
      </div>
    )
  }
  return (
    <Card>
      <DataTable
        className='task-change-log-table'
        value={taskChangelog}
        groupRowsBy='id'
        rowGroupMode='subheader'
        rowGroupHeaderTemplate={groupHeaderTemplate}
        responsiveLayout='scroll'
        scrollable
        scrollHeight={`${fullHeight - 42}px`}
      >
        <Column
          body={(rowData) => {
            return (
              <div className='flex gap-2 align-items-center'>
                <ClientProjectChip
                  clientCode={rowData.clientCode}
                  projectCode={rowData.projectCode}
                />
              </div>
            )
          }}
          field='projectCode'
          header='Proyecto'
          style={{ minWidth: '100px', maxWidth: '120px' }}
          colSpan={1}
        />
        <Column
          header='Módulo'
          style={{ minWidth: '120px', maxWidth: '150px' }}
          field='moduleName'
          body={(rowData) => rowData.moduleName || '-'}
        />
        <Column
          header='Categoría'
          style={{ minWidth: '120px', maxWidth: '150px' }}
          field='categoryTitle'
        />
        <Column
          header='Tarea'
          style={{ minWidth: '200px' }}
          field='name'
        />
        <Column
          header='Comentario de la tarea'
          style={{ minWidth: '200px' }}
          field='comment'
          body={(rowData) => rowData.comment || '-'}
        />
        <Column
          header='Horas trabajadas'
          style={{ maxWidth: '90px' }}
          body={(rowData, col) => renderHighlightedTimeChanges(rowData, col, 'sisuTime')}
        />
        <Column
          header='Horas facturadas'
          style={{ maxWidth: '90px' }}
          body={(rowData, col) => renderHighlightedTimeChanges(rowData, col, 'billedTime')}
        />
        <Column
          header='Motivo de diferencia'
          field='timeDifferenceComment'
          style={{ minWidth: '105px', maxWidth: '140px' }}
          body={(rowData) => rowData.timeDifferenceComment || '-'}
        />
      </DataTable>
    </Card>
  )
}

export default TaskChangeLogTable
