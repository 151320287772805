import { AppRoutes } from '../../consts/appRoutes.consts'
const DUMMY_FILTER_MENU = {}
// import Temporal from '../filtersMenuViews/Temporal.filtersMenu' // Sample filterMenu

export const APP_FILTERS_MENU_ROUTES = {
  [AppRoutes.home.index]: DUMMY_FILTER_MENU,
  [AppRoutes.auth.index]: DUMMY_FILTER_MENU,
  [AppRoutes.usuarios.index]: DUMMY_FILTER_MENU,
  [AppRoutes.registroTiempos.index]: DUMMY_FILTER_MENU,
  [AppRoutes.reporteGeneral.index]: DUMMY_FILTER_MENU,
  [AppRoutes.reporteIndividual.index]: DUMMY_FILTER_MENU,
  [AppRoutes.reporteOcupacion.index]: DUMMY_FILTER_MENU,
  [AppRoutes.clientes.index]: DUMMY_FILTER_MENU,
  [AppRoutes.sandbox.index]: DUMMY_FILTER_MENU,
  [AppRoutes.configuracion.index]: DUMMY_FILTER_MENU,
  [AppRoutes.listaPendientes.index]: DUMMY_FILTER_MENU,
  [AppRoutes.historialCambios.index]: DUMMY_FILTER_MENU,
  [AppRoutes.dashboard.index]: DUMMY_FILTER_MENU
}
