import { API_CLIENT } from '../consts/apiRoutes.consts'
import SERVICE_CONSTS from '../consts/services.consts'
import { api } from './base/api.service'

const {
  RESPONSE_BASIC_STRUCTURE,
  MESSAGES
} = SERVICE_CONSTS

export const getClients = async () => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.get(API_CLIENT)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getActiveClients = async () => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.get(`${API_CLIENT}/active-only`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getActiveClientsOfTeamLeader = async () => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.get(`${API_CLIENT}/active-team-leader`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getActiveClientsWithProjects = async () => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.get(`${API_CLIENT}/active-with-projects`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getClient = async (clientId) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.get(`${API_CLIENT}/${clientId}`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const createClient = async (client, projects) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(API_CLIENT, { client, projects })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const editClient = async (client, projects) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.put(`${API_CLIENT}/${client?.id}`, { client, projects })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const deactivateClient = async (clientId) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.put(`${API_CLIENT}/${clientId}/deactivate`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const reactivateClient = async (clientId) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.put(`${API_CLIENT}/${clientId}/reactivate`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const deleteClient = async (clientId) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.delete(`${API_CLIENT}/${clientId}`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}
