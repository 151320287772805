/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import { DateTime } from 'luxon'
import { generateHoursByProjectAndTeamMemberReport } from '../../../services/report.service'
import useReportFiltersContext from '../../../hooks/contextConsumers/useReportFiltersContext.hook'
import { useSession } from '../../../hooks/auth/useSession.hook'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'
import TeamHoursConsumptionTable from './tables/TeamHoursConsumption/TeamHoursConsumptionTable'
import ProjectPicker from './components/ProjectPicker'
import UnifiedDatePicker from '../../../components/compounds/UnifiedDatePicker.compound'
import { UNIFIEDDATE_VALUES } from '../../../consts/CompoundRangeDatePicker'
import ResetButton from './components/ResetButton'

const INITIAL_PERIOD = UNIFIEDDATE_VALUES.RANGEWEEK
const INITIAL_SELECTED_CLIENT = null
const INITIAL_REPORT_DATA = {
  data: [
    {
      key: 1,
      data: {
        code: 'UX/UI',
        name: 'UX/UI',
        billedTime: 0
      },
      children: []
    },
    {
      key: 2,
      data: {
        code: 'DEVS',
        name: 'DEVS',
        billedTime: 0
      },
      children: []
    },
    {
      key: 3,
      data: {
        code: 'LEADS',
        name: 'LEADS',
        billedTime: 0
      },
      children: []
    }
  ],
  totals: {
    averageTime: 0,
    billedTime: 0,
    weeksToConsider: [],
    weekData: {
        1: { sisuTime: 0 },
        2: { sisuTime: 0 },
        3: { sisuTime: 0 },
        4: { sisuTime: 0 },
        5: { sisuTime: 0 },
        6: { sisuTime: 0 },
        7: { sisuTime: 0 },
        8: { sisuTime: 0 },
        9: { sisuTime: 0 },
        10: { sisuTime: 0 },
        11: { sisuTime: 0 },
        12: { sisuTime: 0 },
        13: { sisuTime: 0 },
        14: { sisuTime: 0 },
        15: { sisuTime: 0 },
        16: { sisuTime: 0 },
        17: { sisuTime: 0 },
        18: { sisuTime: 0 },
        19: { sisuTime: 0 },
        20: { sisuTime: 0 },
        21: { sisuTime: 0 },
        22: { sisuTime: 0 },
        23: { sisuTime: 0 },
        24: { sisuTime: 0 },
        25: { sisuTime: 0 },
        26: { sisuTime: 0 },
        27: { sisuTime: 0 },
        28: { sisuTime: 0 },
        29: { sisuTime: 0 },
        30: { sisuTime: 0 },
        31: { sisuTime: 0 },
        32: { sisuTime: 0 },
        33: { sisuTime: 0 },
        34: { sisuTime: 0 },
        35: { sisuTime: 0 },
        36: { sisuTime: 0 },
        37: { sisuTime: 0 },
        38: { sisuTime: 0 },
        39: { sisuTime: 0 },
        40: { sisuTime: 0 },
        41: { sisuTime: 0 },
        42: { sisuTime: 0 },
        43: { sisuTime: 0 },
        44: { sisuTime: 0 },
        45: { sisuTime: 0 },
        46: { sisuTime: 0 },
        47: { sisuTime: 0 },
        48: { sisuTime: 0 },
        49: { sisuTime: 0 },
        50: { sisuTime: 0 },
        51: { sisuTime: 0 },
        52: { sisuTime: 0 },
        53: { sisuTime: 0 }
    }
  }
}

const getYearMonth = (date) => date.year * 12 + date.month;

const getYearOfDatePeriod = (date) => {
  return { 
    startYear: date[0].toFormat('yyyy'),
    endYear: date[1].toFormat('yyyy')
  }
}

const getDateFormat = (period) => {
  let format

  switch (period) {
  case UNIFIEDDATE_VALUES.RANGEWEEK:
    // eslint-disable-next-line
    format = `wo'/'YY`
    break
  case UNIFIEDDATE_VALUES.RANGEMONTH:
    format = 'MMMM'
    break
  }

  return format
}

const TeamHoursConsumption = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [reportData, setReportData] = useState(INITIAL_REPORT_DATA)
  const [tabNavElement, setTabNavElement] = useState(null)
  const {
    teamHoursConsumptionProjects,
    teamHoursConsumptionSelectedProject,
    setTeamHoursConsumptionSelectedProject,
    teamHoursConsumptionPeriod,
    setTeamHoursConsumptionPeriod,
    teamHoursConsumptionDateRange,
    setTeamHoursConsumptionDateRange,
    teamHoursConsumptionExpandedKeys,
    setTeamHoursConsumptionExpandedKeys
  } = useReportFiltersContext()
  const auth = useSession()

  const DEBOUNCE_DELAY = 500
  const debounceTimeout = useRef(null)

  useEffect(() => {
    const element = document.querySelector('#general-report .p-tabview-nav')
    if (element) {
      setTabNavElement(element)
    }
  }, [])

  useEffect(() => {
    console.log('New Date Range: ', teamHoursConsumptionDateRange)
    getTeamMemberTasks()
  }, [teamHoursConsumptionDateRange, teamHoursConsumptionSelectedProject, teamHoursConsumptionPeriod])

  const getTeamMemberTasks = async () => {
    let response;
    let startDate;
    let startOfWeek;
    let endOfWeek;
    let endDate;
    let ignore = false;
    setError(null)
    setLoading(true)

    if (teamHoursConsumptionPeriod === UNIFIEDDATE_VALUES.RANGEWEEK) {
      startOfWeek = teamHoursConsumptionDateRange[0].startOf('week')
      endOfWeek = teamHoursConsumptionDateRange[1].startOf('week')

      if (Math.abs(startOfWeek.diff(endOfWeek, 'weeks').toObject().weeks) >= 6) {
        ignore = true
        endOfWeek = startOfWeek.plus({ weeks: 5 })
        setTeamHoursConsumptionDateRange([startOfWeek, endOfWeek])
      }
    }

    if (!ignore) {
      if (teamHoursConsumptionPeriod === UNIFIEDDATE_VALUES.RANGEMONTH) {
        startDate = teamHoursConsumptionDateRange[0].startOf('month').toISODate()
        endDate = teamHoursConsumptionDateRange[1].endOf('month').toISODate()
      } else if (teamHoursConsumptionPeriod === UNIFIEDDATE_VALUES.RANGEWEEK) {
        startDate = teamHoursConsumptionDateRange[0].startOf('week').toISODate()
        endDate = teamHoursConsumptionDateRange[1].endOf('week').toISODate()
      }

      const backendPeriod = teamHoursConsumptionPeriod.split('-')[1]

      clearTimeout(debounceTimeout.current)
      debounceTimeout.current = setTimeout(() => {
        response = generateHoursByProjectAndTeamMemberReport(startDate, endDate, backendPeriod, teamHoursConsumptionSelectedProject)
        .then((response) => {
          if (response.success) {
            setReportData({
              data: response.result.data,
              totals: response.result.totals
            })
          } else {
            setError(response.message)
          }
          setLoading(false)
        })
      }, DEBOUNCE_DELAY)
      return () => clearTimeout(debounceTimeout.current)
    }
  }

  const disabledWeeksDate = (current, from) => {
    if (from) {
      const currentDate = current.startOf('week');
      const fromDate = from.startOf('week');

      return Math.abs(currentDate.diff(fromDate, 'weeks').toObject().weeks) >= 6;
    }
  
    return false;
  };  

  const disabledMonthsDate = (current, from) => {
    if (from) {
      const minDate = from.plus({ months: -5});
      const maxDate = from.plus({ months: 5});

      return (
        getYearMonth(current) < getYearMonth(minDate) ||
        getYearMonth(current) > getYearMonth(maxDate)
      );
    }

    return false;
  };

  const disabledRangeDate = (current, { from }) => {
    if (teamHoursConsumptionPeriod === UNIFIEDDATE_VALUES.RANGEWEEK) {
      return disabledWeeksDate(current, from)
    } else if (teamHoursConsumptionPeriod === UNIFIEDDATE_VALUES.RANGEMONTH) {
      return disabledMonthsDate(current, from)
    }
  }

  const controls = 
    <div className='flex justify-content-between align-items-center pb-3 '>
      <div className="mr-0 ml-1 flex flex-1 align-items-center gap-3">
        {teamHoursConsumptionProjects.length > 1
          ? <ProjectPicker
            projects={teamHoursConsumptionProjects}
            selectedProject={teamHoursConsumptionSelectedProject}
            setSelectedProject={setTeamHoursConsumptionSelectedProject}
          />
          : null
        }
        <UnifiedDatePicker
          dateRange={teamHoursConsumptionDateRange}
          setDateRange={setTeamHoursConsumptionDateRange}
          period={teamHoursConsumptionPeriod}
          setPeriod={setTeamHoursConsumptionPeriod}
          hasSingleDay={false}
          hasSingleWeek={false}
          hasSingleMonth={false}
          hasDayPeriod={false}
          hasWeekPeriod={true}
          hasMonthPeriod={true}
          disabledDate={disabledRangeDate}
          getFormat={getDateFormat}
        />
      </div>
      <ResetButton onClick={() => {
        setTeamHoursConsumptionPeriod(INITIAL_PERIOD)
        setTeamHoursConsumptionSelectedProject(INITIAL_SELECTED_CLIENT)
        ref.current?.reset()
        setTeamHoursConsumptionDateRange([
          DateTime.local().startOf('week'),
          DateTime.local().endOf('week')
        ])
        setReportData(INITIAL_REPORT_DATA)
      }} />
    </div>

  return (
    <>
      {controls}
      {loading
        ? (
          <div className='w-full h-20rem flex align-items-center justify-content-center'>
            <ProgressSpinner />
          </div>
        )
        : error
          ? (<div>Error: {error}</div>)
          : <TeamHoursConsumptionTable
            ref={ref}
            reportData={reportData}
            startYear={getYearOfDatePeriod(teamHoursConsumptionDateRange).startYear}
            endYear={getYearOfDatePeriod(teamHoursConsumptionDateRange).endYear}
            period={teamHoursConsumptionPeriod}
            expandedKeys={teamHoursConsumptionExpandedKeys}
            setExpandedKeys={setTeamHoursConsumptionExpandedKeys}
          />
      }
    </>
  )
})

export default TeamHoursConsumption
