import React, { useLayoutEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { StickyFooter } from '../views/individualReport/controllers/tables/RegisteredTasksTable/GroupTemplates.controller'

const useStickyFooter = (dtValue, totals, deps, StickyFooterElement) => {
  const [hasMounted, setHasMounted] = useState(false)
  useLayoutEffect(() => {
    setTimeout(() => {
      const datatableElement = document.querySelector('.p-virtualscroller > .p-datatable-table')
      if (datatableElement && dtValue?.length) {
        const uniqueDays = [...new Set(dtValue.map(item => item?.date))]
        const newTableHeight = 37 * dtValue?.length + (uniqueDays?.length * 33) + 50
        datatableElement.style.minHeight = `${newTableHeight}px`
        // console.log(`Adjusting table height ${37 * dtValue?.length} -> ${newTableHeight}`, uniqueDays?.length)
      } else if (datatableElement && !dtValue?.length) {
        datatableElement.style.minHeight = 'fit-content'
      }
    }, 200)
    if (dtValue?.length) {
      const dataTable = document.querySelector('.p-virtualscroller')
      const footerDiv = document.createElement('table')
      footerDiv.className = 'custom-datatable-footer'
      dataTable.appendChild(footerDiv)
      if (!StickyFooterElement) {
        StickyFooterElement = StickyFooter
      }
      createRoot(footerDiv).render(<StickyFooterElement totals={totals} />)
      setHasMounted(true)
      return () => {
        const dataTable = document.querySelector('.p-virtualscroller')
        const footerDiv = document.querySelector('.custom-datatable-footer')
        if (footerDiv) {
          dataTable.removeChild(footerDiv)
        }
      }
    }
  }, [hasMounted, ...deps])

  return hasMounted
}
export default useStickyFooter
