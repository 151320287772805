import React from 'react'
import AikaLogo from '../../design/assests/images/aika-logo.svg'
import AikaLogoDark from '../../design/assests/images/aika-logo-dark.svg'
import BannerLogo from '../../design/assests/images/home-aika.jpeg'
import useThemeContext from '../../hooks/contextConsumers/useThemeContext.hook'
import ReleaseVersion from '../molecules/ReleaseVersion.molecule'

const styles = {
  banner: {
    backgroundImage: `url(${BannerLogo})`
  },
  container: {
    maxWidth: 1050,
    minHeight: 540
  }
}

export function AuthLayout ({ title = '', subtitle = '', float = false, children }) {
  const { theme } = useThemeContext()
  return (
    <main className="flex flex-column gap-2 justify-content-center align-items-center h-screen w-screen">
      <div className="auto-layout relative flex w-full shadow-6" style={styles.container}>
        <div className={`surface-section w-full md:w-6 p-4 md:p-6 border-round-left-md ${float ? 'flex align-items-center' : ''}`}>
          <div className="content-wrapper w-full">
            <div className="mb-4">
              <img
                src={theme === 'dark' ? AikaLogoDark : AikaLogo}
                alt="Aika logo"
                height={79}
                className="block mx-auto mb-4"
              />
              <h1 className="text-4xl font-bold py-2 my-0 mb-3">{title}</h1>
              <h2 className="text-3xl font-semibold my-0 mb-3" style={styles.subtitle}>{subtitle}</h2>
            </div>
            {children}
          </div>
        </div>
        <ReleaseVersion
          className='clr-secondary'
          style={{
            position: 'absolute',
            bottom: '-25px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 100,
            fontSize: '14px'
          }}
        />
        <div
          className="hidden md:block w-6 bg-no-repeat bg-cover border-round-right-md"
          style={styles.banner}
        ></div>
      </div>
    </main>
  )
}
