import React, { useEffect, useMemo } from 'react'
import LineChart from '../../../components/compounds/graphics/LineChart.compound'
import { formatDecimals } from '../../../utils/numbers.utils'
import CustomTooltipTemplate from './CustomTooltipTemplate'
import { OCCUPATION_PERCENTAGE_RANGE, OCCUPATION_PERCENTAGE_RANGE_COLORS, OCCUPATION_PERCENTAGE_RANGE_LABELS } from '../../../consts/reports.consts'
import { ENG_SHORT_MONTHS_ARRAY } from '../../../consts/date.consts'
import CustomLegendBadge from './CustomLegendBadge'
import EmptyDataMessage from '../../../components/molecules/EmptyDataPieChartMessage.molecule'

const PercentageOccupationChart = ({
  data,
  emptyMessageTitle = 'Sin información',
  emptyMessageMessage = 'No hay horas registradas durante el periodo seleccionado, intenta cambiando los filtros.'
}) => {
  if (!data.length) {
    return (
      <EmptyDataMessage
        title={emptyMessageTitle}
        message={emptyMessageMessage}
      />
    )
  }

  const formattedData = useMemo(() => {
    return data.map((item) => ({
      lineData: {
        Facturadas: formatDecimals(item.billedProportion, 4) * 100
      },
      date: `${ENG_SHORT_MONTHS_ARRAY[item.month - 1]}/${item.year.toString().slice(-2)}`,
      extraInfo: {
        Facturadas: {
          Trabajadas: item.totalSisuTime,
          Facturadas: item.totalBilledTime,
          Perdonadas: item.totalUnbilledHours,
          year: item.year,
          month: ENG_SHORT_MONTHS_ARRAY[item.month - 1]
        }
      }
    }))
  }, [data])

  const customDotTemplate = (data, rechartsData) => {
    const { key, cx, cy, value } = rechartsData
    let color = OCCUPATION_PERCENTAGE_RANGE_COLORS.OPTIMO
    if (value >= OCCUPATION_PERCENTAGE_RANGE.OPTIMO) {
      color = OCCUPATION_PERCENTAGE_RANGE_COLORS.OPTIMO
    } else if (value >= OCCUPATION_PERCENTAGE_RANGE.ESTABLE) {
      color = OCCUPATION_PERCENTAGE_RANGE_COLORS.ESTABLE
    } else if (value >= OCCUPATION_PERCENTAGE_RANGE.INESTABLE) {
      color = OCCUPATION_PERCENTAGE_RANGE_COLORS.INESTABLE
    } else {
      color = OCCUPATION_PERCENTAGE_RANGE_COLORS.CRITICO
    }
    return (
      <circle
        key={key}
        cx={cx}
        cy={cy}
        r={6}
        stroke='white'
        strokeWidth={1}
        fill={color}
      />
    )
  }

  const getCustomYAxisDomain = () => {
    const MIN_VALUE = data.reduce((acc, item) => {
      const value = item.billedProportion
      return value < acc ? value : acc
    }, 1)
    const CUSTOM_MIN_VALUE = Math.floor(MIN_VALUE * 100 / 10) * 10
    return [
      CUSTOM_MIN_VALUE < 40 ? CUSTOM_MIN_VALUE : 40,
      100
    ]
  }

  const tooltipTemplate = (data) => {
    return (
      <CustomTooltipTemplate
        data={[
          {
            name: 'Trabajadas',
            value: data[0].extraInfo.Trabajadas,
            color: 'var(--clr-info)',
            xAxisValue: `${data[0].extraInfo.month}/${data[0].extraInfo.year.toString()}`
          },
          {
            name: 'Facturadas',
            value: data[0].extraInfo.Facturadas,
            color: 'light-dark(var(--primary-color-dark), white)',
            textColor: 'light-dark(white, var(--primary-color-dark))',
            xAxisValue: data[0].xAxisValue
          }
        ]}
        showOccupationBGcolor
      />
    )
  }

  const legendTemplate = (data) => {
    data[0].textColor = 'light-dark(white, var(--primary-color-dark))'
    const OCCUPATION_PERCENTAGE_RANGE_NAMES = ['Óptimo', 'Estable', 'Inestable', 'Crítico']
    return (
      <div className='flex justify-content-between mt-5 flex-wrap gap-4'>
        <div className='flex gap-3 flex-wrap'>
          {
            OCCUPATION_PERCENTAGE_RANGE_NAMES?.map((item, index) => {
              const value = item.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()
              return (
                <CustomLegendBadge
                  key={index}
                  item={{
                    name: item,
                    color: OCCUPATION_PERCENTAGE_RANGE_COLORS[value]
                  }}
                  isDashed
                />
              )
            })
          }
        </div>
        {
          data?.map((item, index) => (
            <CustomLegendBadge
              key={index}
              item={item}
            />
          ))
        }
      </div>
    )
  }

  return (
    <>
      <LineChart
        key={data}
        responsiveContainerConfig={{
          minWidth: '200px',
          minHeight: '500px',
          maxHeight: '1000px'
        }}
        chartContainerConfig={{
          shadow: false,
          className: 'h-full'
        }}
        data={formattedData}
        dataKey='date'
        margin={{ top: 20, bottom: 20 }}
        colors={{
          Facturadas: 'light-dark(var(--primary-color-dark), white)'
        }}
        yAxiesTicksStep={10}
        yAxisDomain={getCustomYAxisDomain()}
        yAxisFormatter={(value) => `${value}%`}
        cartesian={false}
        tooltip
        tooltipTemplate={tooltipTemplate}
        lineConfig={{
          dotTemplate: customDotTemplate,
          activeDotTemplate: customDotTemplate
        }}
        referenceLinesY={[
          {
            y: 100,
            stroke: OCCUPATION_PERCENTAGE_RANGE_COLORS.OPTIMO,
            strokeDasharray: '5 5',
            strokeWidth: 2,
            fillOpacity: '0.4',
            id: 'OPTIMO'
          },
          {
            y: 80,
            stroke: OCCUPATION_PERCENTAGE_RANGE_COLORS.ESTABLE,
            strokeDasharray: '5 5',
            strokeWidth: 2,
            id: 'ESTABLE'
          },
          {
            y: 70,
            stroke: OCCUPATION_PERCENTAGE_RANGE_COLORS.INESTABLE,
            strokeDasharray: '5 5',
            strokeWidth: 2,
            id: 'INESTABLE'
          },
          {
            y: 60,
            stroke: OCCUPATION_PERCENTAGE_RANGE_COLORS.CRITICO,
            strokeDasharray: '5 5',
            strokeWidth: 2,
            id: 'CRITICO'
          }
        ]}
        legend
        legendTemplate={legendTemplate}

        brushConfig={{
          initial: 0,
          className: 'custom-brush-hide-labels'
        }}
        brush
      />
    </>
  )
}

export default PercentageOccupationChart
