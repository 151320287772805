import _ from 'lodash'

export default class ObjectUtils {
  static isFunction (obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply)
  }

  /* static findDiffKeys (obj1, obj2) {
    if (!obj1 || !obj2) {
      return {}
    }

    return Object.keys(obj1)
      .filter((key) => !obj2.hasOwnProperty(key))
      .reduce((result, current) => {
        result[current] = obj1[current]

        return result
      }, {})
  } */

  static getJSXElement (obj, ...params) {
    return this.isFunction(obj) ? obj(...params) : obj
  }
}

/**
 * Compare 2 objects deep equality
 *
 * @param {object} obj1
 * @param {object} obj2
 * @returns
 */
export const areObjectsEqual = (obj1 = {}, obj2 = {}) => {
  if (!obj1 || !obj2) {
    throw new Error('Invalid objects')
  }
  return _.isEqual(obj1, obj2)
}

export const isObjectEmpty = (obj) => {
  if (!obj) {
    throw new Error('Invalid object')
  }
  return _.isEmpty(obj)
}
