import React from 'react'
import { TYPE_OF_TOOLTIP_COLOR_VARIANTS } from '../../../../consts/modules/graphics.consts'

const ColorFigure = ({
  color = '#424242',
  colorType = TYPE_OF_TOOLTIP_COLOR_VARIANTS.square
}) => {
  const showCircle = color && colorType === TYPE_OF_TOOLTIP_COLOR_VARIANTS.circle
  const showSquareRounded = color && colorType === TYPE_OF_TOOLTIP_COLOR_VARIANTS.squareRounded
  if (showCircle) {
    return (
      <span className='tooltip-color-circle' style={{ backgroundColor: color }}></span>
    )
  }
  if (showSquareRounded) {
    return (
      <span className='tooltip-color-square-rounded' style={{ backgroundColor: color }}></span>
    )
  }
  return (
    <span className='tooltip-color' style={{ backgroundColor: color }}></span>
  )
}

export default ColorFigure
