import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

export const VALIDATE_DATE_RANGE = PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
  if (!(propValue[key] instanceof DateTime)) {
    return new Error(
      `Invalid prop '${propFullName}[${key}]' supplied to '${componentName}'. Expected an instance of DateTime.`
    )
  }
  if (propValue.length !== 2) {
    return new Error(
      `Invalid prop '${propFullName}' supplied to '${componentName}'. Expected an array with exactly 2 DateTime instances.`
    )
  }
})
