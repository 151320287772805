import React from 'react'
import ContentLayoutHeader from '../../../components/layouts/ContentLayoutHeader.layout'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'
import { OverlayPanel } from '../../../components/atoms/OverlayPanel.atom'
import { Ripple } from 'primereact/ripple'
import { useSession } from '../../../hooks/auth/useSession.hook'
import { INDIVIDUAL_REPORT_TABS } from '../../../consts/reports.consts'
import { useGetIfUserHasRole } from '../../../utils/roles.utils'
import { Rol } from '../../../consts/roles.consts'

const IndividualReportHeader = ({
  currentTab,
  openCreateTaskForTeamMemberModal,
  setOpenCreateTaskForTeamMemberModal,
  registeredTasksRef
}) => {
  const { user } = useSession()
  return (
    <ContentLayoutHeader
      title='Reporte individual'
      extraContent={
        user.isAdmin() && currentTab === INDIVIDUAL_REPORT_TABS.INDIVIDUAL_TASKS &&
        <IndividualRegisteredTasksSettings
          openCreateTaskForTeamMemberModal={openCreateTaskForTeamMemberModal}
          setOpenCreateTaskForTeamMemberModal={setOpenCreateTaskForTeamMemberModal}
          registeredTasksRef={registeredTasksRef}
        />
      }
    />
  )
}

const IndividualRegisteredTasksSettings = ({
  openCreateTaskForTeamMemberModal,
  setOpenCreateTaskForTeamMemberModal,
  registeredTasksRef
  // handleDownloadReport = () => registeredTasksRef.current?.exportCSV()
}) => {
  const opRef = React.useRef()

  const OverlayOptionTemplate = ({
    iconClassName,
    label,
    onClick,
    ripple = true,
    disabled,
    hidden
  }) => {
    if (hidden) return null
    return (
      <div className={`panel-option p-ripple ${disabled ? 'p-disabled' : ''}`} onClick={onClick}>
        <i className={iconClassName} />
        <span>{label}</span>
        {ripple && <Ripple />}
      </div>
    )
  }
  const userCanDownload = useGetIfUserHasRole([Rol.ADMIN, Rol.ADMIN_WITHOUT_REPORT])
  const handleDownloadReport = () => {
    // getIndividualTasksOfTeamMemberId()
    registeredTasksRef.current?.exportCSV()
  }
  const handleOpenCreateTaskForTeamMemberModal = () => {
    setOpenCreateTaskForTeamMemberModal(true)
    opRef.current.hide()
  }
  return (
    <>
      <PrimaryButton
        className='individual-registered-tasks-setting-btn border-none'
        icon='pi pi-cog'
        onClick={(e) => opRef.current.toggle(e)}
      />
      <OverlayPanel
        className='individual-registered-tasks-setting-overlay'
        ref={opRef}
      >
        <div className='flex flex-column'>
          {
            process.env.REACT_APP_ENVIROMENT !== 'production' &&
            <OverlayOptionTemplate
              label='Agregar tarea'
              iconClassName='pi pi-plus'
              onClick={handleOpenCreateTaskForTeamMemberModal}
              disabled={openCreateTaskForTeamMemberModal}
            />
          }
          <OverlayOptionTemplate
            label='Descargar reporte'
            iconClassName='pi pi-download'
            onClick={handleDownloadReport}
            hidden={!userCanDownload}
          />
        </div>
      </OverlayPanel>
    </>
  )
}

export default IndividualReportHeader
