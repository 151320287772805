/* eslint-disable */
import React, { useRef, useState } from 'react'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'
import TeamMemberSortFilter from '../../../../../components/compounds/columnFilters/TeamMemberSortFilter.compound'
import { dateFormat, getDayName } from '../../../../../utils/date.utils'

const headerColumnGroup = (
  WEEK_SIZE,
  WEEK_DATES,
  teamMembersFilterOptions,
  sortReportData,
  setSortField,
  setSortOrder,
  sortOrder,
  filteredUsers,
  setRecalculateFooter
) => {
  const filteredTeamMembers = useRef([])

  return (
    <ColumnGroup>
      <Row>
        <Column
          header={<p>Integrante <span className='team-members-count-badge'>{filteredTeamMembers.current.length || teamMembersFilterOptions.length}</span></p>}
          field='teamMemberTagName'
          filter
          filterElement={(options) => {
            filteredTeamMembers.current = options.value || []
            return (
              <TeamMemberSortFilter
                filterOptions={options}
                teamMembers={teamMembersFilterOptions}
                selectedTeamMembers={options.value}
                sortOrder={sortOrder}
                onSortChange={(e) => {
                  setSortField('teamMemberTagName')
                  setSortOrder(e.value)
                }}
                onTeamMemberChange={(e) => options.filterCallback(e.value)}
              />
            )
          }}
          onFilterApplyClick={(props) => {
            sortReportData()
            setRecalculateFooter(true)
            filteredUsers(props.constraints.constraints[0].value)
          }}
          onFilterClear={(props) => {
            setRecalculateFooter(true)
            setSortOrder(1)
            setSortField('teamMemberTagName')
            filteredUsers([])
            sortReportData(1)
          }}
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
        />
        {
          WEEK_DATES?.map((date) => {
            const dayName = getDayName(date)
            return (
              <Column key={date} header={
                <div className='flex flex-column gap-2'>
                  <span>{dayName}</span>
                  <span className='font-normal text-sm white-space-nowrap'>{dateFormat(date)}</span>
                </div>
              } />
            )
          })
        }
        <Column header='Horas totales trabajadas' style={{ width: '100px' }} />
        <Column header="Horas facturadas" style={{ width: '120px' }} />
        <Column
          header="Perdonadas a clientes"
          field="weekTimeDifference"
          style={{ width: '120px' }}
        />
        <Column header="Ocupación"
          style={{ width: '100px' }}
        />
      </Row>
    </ColumnGroup>
  )
}

export default headerColumnGroup
