import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import DataTable from '../../../../../components/atoms/table/DataTable.atom'
import Column from '../../../../../components/atoms/table/Column.atom'
import ColumnContent from './ColumnContent.controller'
import { groupHeaderTemplate, StickyFooter } from './GroupTemplates.controller'
import { arraysContainSameStrings } from '../../../../../utils/arrays.utils'
import useIsHoldingCtrlDown from '../../../../../hooks/useIsHoldingCtrlDown'
import { DateTime } from 'luxon'
import { useSession } from '../../../../../hooks/auth/useSession.hook'
import { getCategoriesOfTasksOfTeamMemberId, getModulesOfTasksOfTeamMemberId, getProjectsOfTasksOfTeamMemberId } from '../../../../../services/report.service'
import { calculateTotalsByDayForIndividualTasksReport, calculateTotalsIndividualTasksReport, convertToCSV, downloadCSV } from '../../../../../utils/report.utils'
import { INDIVIDUAL_REPORT_CSV_HEADERS } from '../../../../../consts/reports.consts'
import useHandleDuplicateGroupsDataTable from '../../../../../hooks/useHandleDuplicateGroupsDataTable.hook'
import useStickyFooter from '../../../../../hooks/useStickyFooter.hook'
import InputText from '../../../../../components/atoms/InputText.atom'
import categoryFilterTemplate from '../../../../../components/compounds/registeredTasksTable/CategoryFilterTemplate.compound'
import moduleFilterTemplate from '../../../../../components/compounds/registeredTasksTable/ModuleFilterTemplate.compound'
import clientFilterTemplate from '../../../../../components/compounds/registeredTasksTable/ClientFilterTemplate.compound'
import useToast from '../../../../../hooks/useToast.hook'

const RegisteredTasksDataTable = React.forwardRef(({
  setSelectedTask,
  data,
  cm,
  dateRange,
  updatingTask,
  exportFilename,
  error,
  virtualScrollerOptions,
  exportFunction,
  filterConfig,
  teamMemberId,
  setLazyLoading
}, ref) => {
  if (!data || Object.keys(data).length === 0) return null
  const {
    tasks,
    totals: originalTotals,
    totalsByDay: originalTotalsByDay
  } = useMemo(() => data, [data])

  const dtValue = tasks?.map((task) => {
    if (task) {
      return {
        ...task,
        date: DateTime.fromISO(task?.date?.split('T')[0]).set({ hour: 12 }).toISODate(),
        categoryId: task?.specialCategoryId || task?.categoryId,
        categoryTitle: task?.specialCategoryTitle || task?.categoryTitle,
        moduleId: task?.moduleId ?? undefined
      }
    }
    return undefined
  })
  const [totalsByDay, setTotalsByDay] = useState(originalTotalsByDay)
  const [totals, setTotals] = useState(originalTotals)
  const [categories, setCategories] = useState([])
  const [modules, setModules] = useState([])
  const [projects, setProjects] = useState([])
  const [filteredData, setFilteredData] = useState()
  const overridenRef = useRef()
  const categoryFilterOptionsRef = useRef(null)
  const moduleFilterOptionsRef = useRef(null)
  const clientFilterOptionsRef = useRef(null)
  const isHoldingCtrlDown = useIsHoldingCtrlDown()
  const { user } = useSession()
  const { setErrorMessage } = useToast()

  useEffect(() => {
    if (filteredData) {
      const dynamicTotalsByDay = calculateTotalsByDayForIndividualTasksReport(filteredData)
      setTotalsByDay(dynamicTotalsByDay)
      const dynamicTotals = calculateTotalsIndividualTasksReport(filteredData)
      setTotals(dynamicTotals)
      setLazyLoading(false)
    }
  }, [filteredData])

  useEffect(() => {
    setTotalsByDay(calculateTotalsByDayForIndividualTasksReport(dtValue))
  }, [originalTotalsByDay])

  const formatCategories = (tasks) => {
    if (!tasks) return
    return [...new Map(tasks.map(item => {
      return [item?.categoryId, { label: item?.specialCategoryTitle || item?.categoryTitle, value: item?.specialCategoryId || item?.categoryId, isSpecialCategory: false }]
    })).values()].filter(c => c.label)
  }
  const formatModules = (tasks) => {
    return [...new Map(tasks?.map(item => {
      return [item.module, { label: item.module, value: item.moduleId }]
    })).values()]
  }
  const formatProjects = (tasks) => {
    const projects = []
    tasks?.forEach(item => {
      let project = projects.find(project => project.name === item?.clientName)
      if (!project) {
        project = {
          name: item?.clientName,
          code: item?.clientCode,
          type: 'client',
          items: []
        }
        projects.push(project)
      }
      let projectItem = project.items.find(project => project.name === item?.projectName)
      if (!projectItem) {
        projectItem = {
          id: item?.projectId,
          name: item?.projectName,
          code: item?.projectCode,
          clientCode: item?.clientCode,
          type: 'project'
        }
        project.items.push(projectItem)
      }
    })
    return projects
  }
  const hasSpecialCategories = (tasks) => {
    return tasks?.some(task => task?.specialCategoryId)
  }
  useEffect(() => {
    const [startDate, endDate] = dateRange
    getCategoriesOfTasksOfTeamMemberId(teamMemberId, startDate, endDate)
      .then((response) => {
        const result = response.result
        const categories = formatCategories(result)
        setCategories(categories)
      })
    getModulesOfTasksOfTeamMemberId(teamMemberId, startDate, endDate)
      .then((response) => {
        const result = response.result
        const modules = formatModules(result)
        setModules(modules)
      })
    getProjectsOfTasksOfTeamMemberId(teamMemberId, startDate, endDate)
      .then((response) => {
        const result = response.result
        const projects = formatProjects(result)
        setProjects(projects)
      })
  }, [teamMemberId, dateRange])

  const categoryFilterTemplateWrapper = (options) => {
    return categoryFilterTemplate({
      options,
      categories,
      filterConfig,
      categoryFilterOptionsRef
    })
  }

  const moduleFilterTemplateWrapper = (options) => {
    return moduleFilterTemplate({
      options,
      modules,
      filterConfig,
      moduleFilterOptionsRef
    })
  }

  const clientFilterTemplateWrapper = (options) => {
    return clientFilterTemplate({
      options,
      projects,
      filterConfig,
      clientFilterOptionsRef
    })
  }

  const taskNameFilterTemplate = (options) => {
    return (
      <InputText
        value={options.value || ''}
        onChange={(e) => {
          const value = e.target.value
          options.filterCallback(value?.trim())
        }}
      />
    )
  }
  const [sortConfig, setSortConfig] = useState({ sortField: null, sortOrder: null })
  useStickyFooter(filteredData || dtValue, totals, [dtValue, teamMemberId], StickyFooter)
  useHandleDuplicateGroupsDataTable(virtualScrollerOptions.loading, sortConfig.sortField, [virtualScrollerOptions.loading, dtValue])
  useImperativeHandle(ref, () => ({
    ...overridenRef.current,
    exportCSV: async () => {
      const response = await exportFunction()
      if (!response.success) {
        setErrorMessage({ message: response.message })
      }
      const result = response.result
      const tasks = result.tasks.map(task => ({
        ...task,
        date: DateTime.fromISO(task?.date?.split('T')[0]).set({ hour: 12 }).toISODate()
      }))
      const tasksCSV = convertToCSV(tasks, INDIVIDUAL_REPORT_CSV_HEADERS)
      downloadCSV(tasksCSV, exportFilename)
    }
  }), [exportFilename])

  const onLeftClickFilter = (filterOptionsRef, value) => {
    setLazyLoading(true)
    const currValue = filterOptionsRef.current.value
    if (currValue?.length === 1) {
      filterOptionsRef.current.filterApplyCallback()
    } else {
      filterOptionsRef.current.filterApplyCallback([value])
    }
  }
  return (
    <>
      <DataTable
        key={dtValue}
        ref={overridenRef}
        exportFilename={exportFilename}
        className='individual-report-table'
        value={dtValue}
        groupRowsBy='date'
        rowGroupMode='subheader'
        rowGroupHeaderTemplate={(rowData, options) => groupHeaderTemplate(rowData, options, totalsByDay)}
        onValueChange={(e) => setFilteredData(e)}
        onContextMenu={({ originalEvent, data }) => {
          if (user?.isAdmin()) {
            setSelectedTask(data)
            cm.current.show(originalEvent)
          }
        }}
        scrollable
        style={{ height: '100%' }}
        tableStyle={{ tableLayout: 'fixed' }}
        scrollHeight='100%'
        emptyMessageTitle={error ? 'Error' : 'No se encontró información'}
        emptyMessageContent={error || 'Intenta aplicar otros filtros'}
        virtualScrollerOptions={virtualScrollerOptions}
        removableSort
        sortField={sortConfig.sortField}
        sortOrder={sortConfig.sortOrder}
        onSort={(e) => setSortConfig(e)}
      >
        {ColumnContent({
          clientFilterTemplate: clientFilterTemplateWrapper,
          moduleFilterTemplate: moduleFilterTemplateWrapper,
          categoryFilterTemplate: categoryFilterTemplateWrapper,
          taskNameFilterTemplate,
          cm,
          setSelectedTask,
          isHoldingCtrlDown,
          hasSpecialCategories: hasSpecialCategories(tasks),
          onLeftClickModuleName: (moduleId) => onLeftClickFilter(moduleFilterOptionsRef, moduleId),
          onLeftClickCategoryName: (categoryId) => onLeftClickFilter(categoryFilterOptionsRef, categoryId),
          onLeftClickProjectName: (projectName) => onLeftClickFilter(clientFilterOptionsRef, projectName),
          onLeftClickClientCode: (clientCode) => {
            setLazyLoading(true)
            const currValues = clientFilterOptionsRef.current.value
            const filteredClients = Array.from(new Set(tasks.filter(task => task?.clientCode === clientCode).map(client => client.projectName)))
            if (currValues && arraysContainSameStrings(currValues, filteredClients)) {
              clientFilterOptionsRef.current.filterApplyCallback()
            } else {
              clientFilterOptionsRef.current.filterApplyCallback(filteredClients)
            }
          },
          updatingTask,
          setLazyLoading
        })?.map((column, index) => (
          <Column
            key={index}
            {...column}
          />
        ))}
      </DataTable>
    </>
  )
})

export default React.memo(RegisteredTasksDataTable)
