import React from 'react'
import PropTypes from 'prop-types'
import {
  Line,
  ComposedChart as ComposedChartRecharts,
  Brush,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Area,
  Bar
} from 'recharts'
import {
  getBrushStartIndex,
  getColor,
  getComposedDataRechartsFromData,
  getIndexOfColor,
  getUniqueResourcesFromData
} from '../../../utils/graphics.utils'
import TooltipMultipleDefault from './components/TooltipMultipleDefault'
import { DEFAULT_TICK_STYLE, defaultColors, DOMAIN_DEFAULT } from '../../../consts/modules/graphics.consts'
import useYTicksCustom from '../../../hooks/graphics/useYTicksCustom.hook'

const ComposedChart = ({
  className,
  width = 730,
  height = 250,
  margin = {
    top: 5, right: 30, left: 20, bottom: 5
  },
  dataKey = 'dataKey',
  data,
  colorsArray = defaultColors,
  tooltipTemplate,
  tooltip = true,
  tooltipConfig = {},
  legend = true,
  legendTemplate,
  cartesian = true,
  brush,
  brushConfig = {},
  lineConfig = {},
  areaConfig = {},
  barConfig = {},
  responsiveContainerConfig = {},
  yAxisDomain = DOMAIN_DEFAULT,
  yAxiesTicksStep = undefined,
  xAxisTickStyle = DEFAULT_TICK_STYLE,
  yAxisTickStyle = DEFAULT_TICK_STYLE,
  cartesianGridProps = {
    strokeDasharray: '3 3'
  }
}) => {
  const uniqueResourcesArea = getUniqueResourcesFromData(data, 'areaData')
  const uniqueResourcesBar = getUniqueResourcesFromData(data, 'barData')
  const uniqueResourcesLine = getUniqueResourcesFromData(data, 'lineData')
  const limit = uniqueResourcesArea.length + uniqueResourcesBar.length + uniqueResourcesLine.length

  const iteratorColorIndex = getIndexOfColor(0, limit)
  const getNewIndexColorArray = () => iteratorColorIndex.next().value

  const DEFAULT_BRUSH_CONFIG = {
    initial: 35,
    height: 30,
    stroke: '#8884d8'
  }
  const brushInitial = brushConfig?.initial || DEFAULT_BRUSH_CONFIG.initial
  const brushStroke = brushConfig?.stroke || DEFAULT_BRUSH_CONFIG.stroke
  const brushHeight = brushConfig?.height || DEFAULT_BRUSH_CONFIG.height
  const brushCustomStartIndex = brushConfig?.customStartIndex
  const brushClassName = brushConfig?.className

  const DEFAULT_LINE_CONFIG = {
    type: 'monotone',
    colors: {}
  }
  const lineType = lineConfig?.type || DEFAULT_LINE_CONFIG.type
  const lineColors = lineConfig?.colors || DEFAULT_LINE_CONFIG.colors

  const DEFAULT_AREA_CONFIG = {
    type: 'monotone',
    colors: {},
    dotTemplates: {},
    opacity: 0.5,
    dot: false
  }
  const areaType = areaConfig?.type || DEFAULT_AREA_CONFIG.type
  const areaColors = areaConfig?.colors || DEFAULT_AREA_CONFIG.colors
  const areaOpacity = areaConfig?.opacity || DEFAULT_AREA_CONFIG.opacity
  // const areaDot = areaConfig?.dot || DEFAULT_AREA_CONFIG.dot
  const areaDotTemplates = areaConfig?.dotTemplates || DEFAULT_AREA_CONFIG.dotTemplates

  const DEFAULT_BAR_CONFIG = {
    colors: {},
    stack: false
  }
  const barColors = barConfig?.colors || DEFAULT_BAR_CONFIG.colors
  const barStack = barConfig?.stack || DEFAULT_BAR_CONFIG.stack

  const DEFAULT_RESPONSIVE_CONTAINER_CONFIG = {
    width: '100%',
    height: '85%',
    minWidth: '500px',
    minHeight: '500px',
    maxHeight: '2000px'
  }
  const responseContainerWidth = responsiveContainerConfig?.width || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.width
  const responseContainerHeight = responsiveContainerConfig?.height || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.height
  const responseContainerMinWidth = responsiveContainerConfig?.minWidth || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.minWidth
  const responseContainerMinHeight = responsiveContainerConfig?.minHeight || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.minHeight
  const responseContainerMaxHeight = responsiveContainerConfig?.maxHeight || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.maxHeight

  const DEFAULT_TOOLTIP_CONFIG = {
    unit: ''
  }
  const tooltipUnit = tooltipConfig?.unit ?? DEFAULT_TOOLTIP_CONFIG.unit

  const tooltipRender = (rawTooltipData) => {
    if (!rawTooltipData?.active || !rawTooltipData?.payload?.length) return null
    const payloadData = rawTooltipData?.payload?.[0]

    const recordData = payloadData?.payload ? payloadData?.payload : {}

    const composedData = rawTooltipData?.payload?.map((point) => {
      const extraInfo = recordData?.extraInfo?.[point?.name] || null
      return {
        name: point?.name,
        value: point?.value,
        color: point?.color,
        xAxisValue: recordData?.[dataKey],
        extraInfo
      }
    })

    if (!tooltipTemplate) {
      return <TooltipMultipleDefault
        data={composedData}
        tooltipUnit={tooltipUnit}
        yLabel={recordData?.[dataKey]}
      />
    }

    return (
      <div className="container-tooltip-graphic">
        {tooltipTemplate(composedData, recordData)}
      </div>
    )
  }

  const startIndexUsed = Boolean(brushCustomStartIndex) && brushCustomStartIndex >= 0
    ? brushCustomStartIndex
    : getBrushStartIndex(data, brushInitial)

  const legendRender = (rawLegendsData) => {
    const legendsData = rawLegendsData.payload.map((legendData) => {
      return {
        name: legendData.value,
        value: legendData.payload.value,
        color: legendData.color,
        extraInfo: legendData.payload.extraInfo
      }
    })
    return (
      <div className="container-legend-graphic">
        {legendTemplate(legendsData)}
      </div>
    )
  }

  const yAxiesTicks = useYTicksCustom({
    yAxiesTicksStep,
    data,
    yAxisDomain
  })
  return (
    <ResponsiveContainer
      className={className}
      width={responseContainerWidth}
      height={responseContainerHeight}
      minWidth={responseContainerMinWidth}
      minHeight={responseContainerMinHeight}
      maxHeight={responseContainerMaxHeight}
    >
      <ComposedChartRecharts width={width} height={height} margin={margin} data={getComposedDataRechartsFromData(data)} >
        <XAxis
          dataKey={dataKey}
          style={xAxisTickStyle}
        />
        <YAxis
          ticks={yAxiesTicks}
          style={yAxisTickStyle}
        />

        {tooltip && <Tooltip content={tooltipRender} />}

        {legend && <Legend
          content={legendTemplate ? legendRender : null}
        />}

        {cartesian && <CartesianGrid {...cartesianGridProps} />}

        {
          uniqueResourcesArea.map((areaLabel, index) => {
            const key = `composed-area-${index}-${areaLabel}`
            const colorCode = areaColors?.[areaLabel] || getColor(getNewIndexColorArray(), colorsArray)
            const dotTemplate = areaDotTemplates?.[areaLabel]
            return (
              <Area
                key={key}
                type={areaType}
                dataKey={areaLabel}
                fill={colorCode}
                stroke={colorCode}
                fillOpacity={areaOpacity}
                dot={dotTemplate}
              />
            )
          })
        }

        {uniqueResourcesBar.map((barLabel, index) => {
          const key = `composed-bar-${index}-${barLabel}`
          const colorCode = barColors?.[barLabel] || getColor(getNewIndexColorArray(), colorsArray)
          return (
            <Bar key={key} dataKey={barLabel} fill={colorCode} stackId={barStack ? '1' : null} />
          )
        })}

        {uniqueResourcesLine.map((lineLabel, index) => {
          const key = `composed-line-${index}-${lineLabel}`
          const colorCode = lineColors?.[lineLabel] || getColor(getNewIndexColorArray(), colorsArray)
          return (
            <Line type={lineType} key={key} dataKey={lineLabel} stroke={colorCode} />
          )
        })}

        {brush && <Brush
          dataKey={dataKey}
          height={brushHeight}
          stroke={brushStroke}
          startIndex={startIndexUsed}
          alwaysShowText={false}
          className={brushClassName}
        />}

      </ComposedChartRecharts>
    </ResponsiveContainer>
  )
}

ComposedChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number,
    bottom: PropTypes.number
  }),
  dataKey: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    barData: PropTypes.object,
    areaData: PropTypes.object,
    lineData: PropTypes.object,
    dataKey: PropTypes.string,
    extraInfo: PropTypes.object
  })),
  colorsArray: PropTypes.arrayOf(PropTypes.string),
  tooltipTemplate: PropTypes.func,
  tooltip: PropTypes.bool,
  tooltipConfig: PropTypes.shape({
    unit: PropTypes.string
  }),
  legend: PropTypes.bool,
  cartesian: PropTypes.bool,
  barConfig: PropTypes.shape({
    colors: PropTypes.object,
    stack: PropTypes.bool
  }),
  areaConfig: PropTypes.shape({
    type: PropTypes.string,
    colors: PropTypes.object
  }),
  lineConfig: PropTypes.shape({
    type: PropTypes.string,
    colors: PropTypes.object
  }),
  brushConfig: PropTypes.shape({
    customStartIndex: PropTypes.number,
    initial: PropTypes.number,
    height: PropTypes.number,
    stroke: PropTypes.string
  }),
  brush: PropTypes.bool,
  responsiveContainerConfig: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string
  })
}

export default ComposedChart
