import React from 'react'
import SelectButton from '../../../components/atoms/SelectButton.atom'
import { OCCUPATION_CHART_TYPES, OCCUPATION_HOUR_TYPES_OPTIONS } from '../../../consts/reports.consts'
import DatePicker from '../../../components/atoms/DatePicker.atom'
import MultiSelect from '../../../components/atoms/MultiSelect.atom'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { VALIDATE_DATE_RANGE } from '../../../consts/propTypes.consts'
import ResetButton from '../../generalReport/controllers/components/ResetButton'
import SecondaryButton from '../../../components/molecules/buttons/SecondaryButton.molecule'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'
import UnifiedDatePicker from '../../../components/compounds/UnifiedDatePicker.compound'
import { UNIFIEDDATE_VALUES } from '../../../consts/CompoundRangeDatePicker'

const { RangePicker } = DatePicker

const OccupationReportChartsControls = ({
  dateRange,
  setDateRange,
  chartType,
  setChartType,
  hoursTypeFilter,
  setHoursTypeFilter
}) => {
  const OccupationChartSelectButton = () => (
    <SelectButton
      value={chartType}
      options={[
        { label: 'Absoluta', value: OCCUPATION_CHART_TYPES.ABSOLUTO },
        { label: 'Porcentual', value: OCCUPATION_CHART_TYPES.PORCENTUAL }
      ]}
      onChange={(e) => {
        setChartType(e.value ?? chartType)
      }}
    />
  )

  const OccupationHourTypesFilter = () => {
    const [temporalHoursTypeFilter, setTemporalHoursTypeFilter] = React.useState(hoursTypeFilter)
    const id = `hours-type-filter-${new Date().getTime()}`
    React.useEffect(() => {
      setTemporalHoursTypeFilter(hoursTypeFilter)
    }, [hoursTypeFilter])
    if (chartType !== OCCUPATION_CHART_TYPES.ABSOLUTO) return null
    return (
      <MultiSelect
        inputId={id}
        key={hoursTypeFilter}
        options={OCCUPATION_HOUR_TYPES_OPTIONS}
        placeholder='Selecciona tipo de horas'
        onChange={(data) => {
          setTemporalHoursTypeFilter(data.value)
        }}
        value={temporalHoursTypeFilter}
        itemTemplate={(option) => {
          return (
            <span className='hours-type-badge' style={{ backgroundColor: option.color, color: option.textColor }}>
              {option.label}
            </span>
          )
        }}
        maxSelectedLabels={1}
        selectedItemsLabel='{0} tipos de horas seleccionados'
        style={{
          minWidth: '263px'
        }}
        panelStyle={{
          maxWidth: '263px'
        }}
        filter
        emptyFilterMessage={'Misión fallida... no encontramos nada'}
        panelFooterTemplate={(props, hide) => {
          return (
            <div className='flex gap-2 justify-content-end' style={{ padding: '16px', paddingTop: '32px' }}>
              <SecondaryButton
                type='text'
                label='Cancelar'
                icon='pi pi-times'
                onClick={() => {
                  setTemporalHoursTypeFilter(hoursTypeFilter)
                  hide()
                }}
              />
              <PrimaryButton
                label='Guardar'
                icon='pi pi-check'
                onClick={() => {
                  setHoursTypeFilter(temporalHoursTypeFilter)
                  hide()
                }}
              />
            </div>
          )
        }}
      />
    )
  }
  const OccupationChartRangePicker = () => (
    <UnifiedDatePicker
      dateRange={dateRange}
      setDateRange={setDateRange}
      period={UNIFIEDDATE_VALUES.RANGEMONTH}
      overrideRangePickerClassName={'month-range-picker'}
      maxDate={DateTime.local()}
      format={'YYYY-MM'}
      separator={<i className='pi pi-arrow-right text-xs clr-secondary' />}
    />
  )

  const onFilterResetClick = () => {
    setDateRange([
      DateTime.local().startOf('year'),
      DateTime.local()
    ])
    if (chartType === OCCUPATION_CHART_TYPES.ABSOLUTO) {
      setHoursTypeFilter([])
    }
  }

  return (
    <div className='occupation-report-controls flex justify-content-between align-items-center'>
      <div className='flex gap-3 h-fit'>
        <OccupationChartSelectButton />
        {OccupationHourTypesFilter()}
        <OccupationChartRangePicker />
      </div>
      <ResetButton
        key={chartType}
        tooltipPosition='top'
        onClick={onFilterResetClick}
      />
    </div>
  )
}

export default OccupationReportChartsControls

OccupationReportChartsControls.propTypes = {
  dateRange: VALIDATE_DATE_RANGE,
  setDateRange: PropTypes.func.isRequired,
  chartType: PropTypes.oneOf(Object.values(OCCUPATION_CHART_TYPES)),
  setChartType: PropTypes.func.isRequired,
  hoursTypeFilter: PropTypes.array,
  setHoursTypeFilter: PropTypes.func.isRequired
}
