import React from 'react'

const EmptyDataMessage = ({
  height = 'auto',
  width = 'auto',
  title = 'Sin información',
  message = 'Intenta cambiar los filtros aplicados para ver la información.'
}) => {
  return (
    <div className='empty-data-piechart-message py-6 text-center flex flex-column justify-content-center my-auto' style={{ height: height, width: width }}>
      <i className="pi pi-folder-open text-3xl"></i>
      <h3 className='text-xl title'>{title}</h3>
      <p className='message'>{message}</p>
    </div>
  )
}

export default EmptyDataMessage
