/* eslint-disable */
import React, { createContext, useEffect, useState } from 'react'
import { getClients } from '../../services/client.service'

export const ClientColorsContext = createContext()
export function ClientColorsProvider ({ children }) {
  const [colors, setColors] = useState([])
  const [refresh, setRefresh] = useState(false)
  useEffect(() => {
    gettingClients()
  }, [refresh])
  const gettingClients = async () => {
    const response = await getClients()
    const clients = response.result
    const colors = clients.map(client => {
      return {
        name: client?.data?.name,
        code: client?.data?.code,
        color: client?.data?.color,
        projects: client?.children.map(p => ({ code: p.data.code, name: p.data.name?.split('-')[1] }))
      }
    })
    setColors(colors)
  }
  return (
    <>
      <ClientColorsContext.Provider value={{ colors, setRefresh }}>
        {children}
      </ClientColorsContext.Provider>
    </>
  )
}
