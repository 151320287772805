import React, { useEffect, useState } from 'react'
import { CustomTreeTable as TreeTable } from '../../../components/atoms/table/CustomTreeTable.atom'
import ColumnGroup from '../../../components/atoms/table/ColumnGroup.atom'
import Row from '../../../components/atoms/table/RowColumnGroup.atom'
import Column from '../../../components/atoms/table/Column.atom'
import OccupationPercentageChip from '../../../components/molecules/OccupationPercentageChip.molecule'
import { roundReportData } from '../../../utils/report.utils'
import { getMonthLongNameByNumber } from '../../../utils/date.utils'
import { floatToPercentage, formatDecimals, numberFormatter } from '../../../utils/numbers.utils'

const OccupationReportMultipleYearsTable = React.forwardRef(({
  reportData: originalData,
  expandedKeys,
  setExpandedKeys
}, ref) => {
  const reportData = roundReportData(originalData)

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Fecha"
          rowSpan={2}
          colSpan={2}
          field='data.name'
        />
        <Column
          header="Horas totales"
          rowSpan={1}
          colSpan={4}
        />
        <Column
          header="Porcentaje de ocupación"
          rowSpan={2}
          colSpan={1}
        />
      </Row>
      <Row>
        <Column
          header="Trabajadas"
          colSpan={1}
          rowSpan={1}
        />
        <Column
          header="Internas"
          colSpan={1}
          rowSpan={1}
        />
        <Column
          header="Facturadas"
          colSpan={1}
          rowSpan={1}
        />
        <Column
          header="Perdonadas"
          colSpan={1}
          rowSpan={1}
        />
      </Row>
    </ColumnGroup>
  )

  const footerGroup = (
    <ColumnGroup key={reportData.totals}>
      <Row>
        <Column footer="Totales" colSpan={2} />
        <Column footer={numberFormatter(reportData?.totals?.totalSisuTime)} />
        <Column footer={numberFormatter(reportData?.totals?.totalSisuClientTime)} />
        <Column footer={numberFormatter(reportData?.totals?.totalBilledTime)} />
        <Column footer={numberFormatter(reportData?.totals?.totalUnbilledHours)} />
        <Column footer={<OccupationPercentageChip
          percentageValue={reportData?.totals?.ocupationPercentage}
        />} />
      </Row>
    </ColumnGroup>
  )

  const yearOrMonthData = (node) => {
    if (node?.children && node?.children.length > 0) {
      return node?.data?.year
    } else {
      return getMonthLongNameByNumber(node?.data?.month)
    }
  }

  const teamMemberExpandCondition = (node) => {
    if (node?.children && node?.children.length > 0) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <TreeTable
        ref={ref}
        value={reportData.data}
        headerColumnGroup={headerGroup}
        footerColumnGroup={footerGroup}
        responsiveLayout='scroll'
        className='week-hours-report-data-table'
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        expanderConditions={(node) => teamMemberExpandCondition(node)}
      >
        <Column
          field="data.distributionSisuTime"
          body={(node) => <span>{yearOrMonthData(node)}</span>}
        />
        <Column
          field="data.totalSisuTime"
          body={(node) => <span>{numberFormatter(node?.data?.totalSisuTime)}</span>}
        />
        <Column
          field="data.totalSisuClientTime"
          body={(node) => <span>{numberFormatter(node?.data?.totalSisuClientTime)}</span>}
        />
        <Column
          field="data.totalBilledTime"
          body={(node) => <span>{numberFormatter(node?.data?.totalBilledTime)}</span>}
        />
        <Column
          field="data.totalUnbilledHours"
          body={(node) => <span>{numberFormatter(node?.data?.totalUnbilledHours)}</span>}
        />
        <Column
          body={(node) => <span>
            <OccupationPercentageChip
              percentageValue={node?.data?.ocupationPercentage}
            />
          </span>}
        />
      </TreeTable>
    </>
  )
})

export default OccupationReportMultipleYearsTable
