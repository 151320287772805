/* eslint-disable */
import React, { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { APP_FILTERS_MENU_ROUTES } from '../routers/routes/appFiltersMenuRoutes.router'
import { APP_MENU } from '../consts/appMenu.consts'
import { defineRoute } from '../utils/menu.utils'

export const SideBarContextValues = createContext()
export default function SidebarContext ({ children }) {
  const location = useLocation()
  const [keyPath, setKeyPath] = useState(null)
  const [screenTitle, setScreenTitle] = useState(null)
  const [mobileTitle, setMobileTitle] = useState({
    name: 'Inicio',
    icon: 'pi pi-home',
    typeOfIcon: 'iconClass'
  })
  const [sidebarActive, setSidebarActive] = useState(true)
  const [bockOpenTheNormalMenu, setBockOpenTheNormalMenu] = useState(false)

  useEffect(() => {
    const { pathToUse } = defineRoute(location.pathname)
    if (location.pathname === '/') return
    if (pathToUse !== keyPath && APP_FILTERS_MENU_ROUTES[pathToUse]) {
      console.log('Ruta: ', pathToUse)
      setKeyPath(pathToUse)
    }
  }, [location.pathname])

  useEffect(() => {
    for (let i = 0; i < APP_MENU.length; i++) {
      if (APP_MENU[i].modules) {
        for (let j = 0; j < APP_MENU[i].modules.length; j++) {
          if (APP_MENU[i].modules[j].name === screenTitle) {
            if (APP_MENU[i].modules[j].icon) {
              setMobileTitle({
                name: APP_MENU[i].modules[j].name,
                icon: APP_MENU[i].modules[j].icon,
                typeOfIcon: 'icon'
              })
            } else if (APP_MENU[i].modules[j].iconClass) {
              setMobileTitle({
                name: APP_MENU[i].modules[j].name,
                icon: APP_MENU[i].modules[j].iconClass,
                typeOfIcon: 'iconClass'
              })
            } else if (APP_MENU[i].modules[j].iconSvg) {
              setMobileTitle({
                name: APP_MENU[i].modules[j].name,
                icon: APP_MENU[i].modules[j].iconSvg,
                typeOfIcon: 'iconSvg'
              })
            }
            return
          }
        }
      } else if (APP_MENU[i].name === screenTitle) {
        if (APP_MENU[i].icon) {
          setMobileTitle({
            name: APP_MENU[i].name,
            icon: APP_MENU[i].icon,
            typeOfIcon: 'icon'
          })
        } else if (APP_MENU[i].iconClass) {
          setMobileTitle({
            name: APP_MENU[i].name,
            icon: APP_MENU[i].iconClass,
            typeOfIcon: 'iconClass'
          })
        } else if (APP_MENU[i].iconSvg) {
          setMobileTitle({
            name: APP_MENU[i].name,
            icon: APP_MENU[i].iconSvg,
            typeOfIcon: 'iconSvg'
          })
        }
        return
      }
    }
  }, [screenTitle])

  return (
    <SideBarContextValues.Provider value={{
      keyPath,
      setKeyPath,
      screenTitle,
      setScreenTitle,
      mobileTitle,
      setMobileTitle,
      sidebarActive,
      setSidebarActive,
      bockOpenTheNormalMenu,
      setBockOpenTheNormalMenu
    }}>
      {children}
    </SideBarContextValues.Provider>
  )
}
