import React from 'react'
import { ProgressBar as PrimeProgressBar } from 'primereact/progressbar'

/** @param {import('primereact/progressbar').ProgressBarProps} props */
const ProgressBar = (props) => {
  return (
    <PrimeProgressBar {...props} />
  )
}

export default ProgressBar
