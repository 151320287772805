import React from 'react'
import ClientBudgetHeader from './controllers/ClientBudgetHeader.controller'
import ClientBudgetContent from './controllers/ClientBudgetContent.controller'

const ClientBudget = () => {
  return (
    <>
      <ClientBudgetHeader/>
      <ClientBudgetContent/>
    </>
  )
}

export default ClientBudget
