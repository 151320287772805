import React from 'react'
import { SelectButton as SelectButtonPrime } from 'primereact/selectbutton'

const SelectButton = (props) => {
  return (
    <SelectButtonPrime {...props}/>
  )
}

export default SelectButton
