import React from 'react'
import ClientChip from '../../../../components/molecules/ClientChip.molecule'
import ProjectChip from '../../../../components/molecules/ProjectChip.molecule'
import Dropdown from '../../../../components/atoms/Dropdown.atom'

const ProjectPicker = ({
  projects,
  selectedProject,
  setSelectedProject
}) => {
  const itemTemplate = (option) => {
    return (
      <div className='flex gap-2 align-items-center'>
        <ClientChip
          clientCode={option.clientTagName}
        />
        <ProjectChip
          clientCode={option.clientTagName}
          projectCode={option.projectTagName}
        />
        <span>{option.projectName}</span>
      </div>
    )
  }

  const valueTemplate = (option) => {
    if (option) {
      return (
        <div className='flex gap-2 align-items-center' style={{ marginTop: '-3px' }}>
          <ClientChip
            clientCode={option.clientTagName}
          />
          <span>{option.projectName}</span>
        </div>
      )
    } else {
      return (
        <span>
          Elige un proyecto
        </span>
      )
    }
  }

  return (
    <Dropdown
      className='w-17rem'
      value={selectedProject}
      options={projects}
      onChange={(e) => setSelectedProject(e.value)}
      optionLabel="projectName"
      optionValue="projectID"
      placeholder="Elige un proyecto"
      style={{ height: '40px' }}
      itemTemplate={itemTemplate}
      valueTemplate={valueTemplate}
    />
  )
}

export default ProjectPicker
