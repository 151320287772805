/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Tabview from '../../../../../components/atoms/tabView/TabView.atom'
import TabPanel from '../../../../../components/atoms/tabView/TabPanel.atom'
import { createPortal } from 'react-dom'
import TabviewControllersContainer from '../../components/TabviewControllersContainer'
import PrimaryButton from '../../../../../components/molecules/buttons/PrimaryButton.molecule'
import ModalFormCreateProject from './ModalFormCreateProject.controller'
import FormSectionTitle from './FormSectionTitle'
import FormProject from './FormProject.controller'
import { isNotEmpty, validateFieldValue, isNotMaxLength } from '../../../../../utils/validations.utils'
import FormClientModules from './FormClientModules.controller'
import BlockUI from '../../../../../components/atoms/misc/BlockUI.atom'
import Divider from '../../../../../components/atoms/Divider.atom'
import ProjectChip from '../../../../../components/molecules/ProjectChip.molecule'
import { useSession } from '../../../../../hooks/auth/useSession.hook'

const FormClientProjects = ({
  client,
  projects: createdProjects,
  setProjects: setCreatedProjects,
  error,
  setError
}) => {
  createdProjects = createdProjects.map(project => ({
    ...project,
    formData: {
      name: project?.name || '',
      code: project?.code || '',
      active: undefined || project?.active
    },
    validations: {
      name: {
        errors: [],
        value: project?.name || '',
        typeValidations: [isNotEmpty]
      },
      code: {
        errors: [],
        value: project?.code || '',
        typeValidations: [isNotEmpty, isNotMaxLength],
        validationOptions: { isNotMaxLength: { max: 3 } }
      },
      active: {
        errors: [],
        value: project?.active || false,
        typeValidations: [isNotEmpty]
      }
    }
  })) || []
  createdProjects?.forEach(project => {
    if (!Object.keys(project?.validations)) return
    Object.keys(project?.validations)?.forEach(key => {
      const validation = validateFieldValue(project.validations, key, project.validations[key].value)
      project.validations[key] = validation[key]
    })
  })

  const [isTabViewLoaded, setIsTabViewLoaded] = useState(false)
  const [openProjectModal, setOpenProjectModal] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [tabViewContainer, setTabViewContainer] = useState(null)
  const ref = React.useRef()
  const { user } = useSession()
  useEffect(() => {
    if (ref.current) {
      setIsTabViewLoaded(true)
    }
  }, [ref.current])
  useEffect(() => {
    const container = document.querySelector('#projects-tabview.p-tabview-nav-content')
    if (container) {
      setTabViewContainer(container)
    }
    if (createdProjects && createdProjects?.some(project => project?.validations?.name?.errors?.length || project?.validations?.code?.errors?.length || project?.validations?.active?.errors?.length)) {
      setError(true)
    } else {
      setError(false)
    }
    setError(false)
    // for (const project of createdProjects) {
    //   if (project?.modules?.length === 0) {
    //     setError(true)
    //   }
    // }
  }, [createdProjects])
  useEffect(() => {
    if (createdProjects.length > 0) {
      const editableIndex = createdProjects.findIndex(project => {
        return user?.isAdmin() || (user.isTeamLeader() && user.leaderProjects.includes(project.id))
      })
      if (editableIndex !== -1) {
        setActiveIndex(editableIndex)
      }
    }
  }, [createdProjects, user])
  useEffect(() => {
    console.log('activeIndex', activeIndex)
  }, [activeIndex])
  const handleCreateProject = (project) => {
    user.leaderProjects.push(project.id)
    setCreatedProjects([
      ...createdProjects,
      {
        ...project,
        isDeletable: true,
        formData: {
          name: project?.name || '',
          code: project?.code || '',
          active: project?.active || 1
        },
        validations: {
          name: {
            errors: [],
            value: project?.name || '',
            typeValidations: [isNotEmpty]
          },
          code: {
            errors: [],
            value: project?.code || '',
            typeValidations: [isNotEmpty, isNotMaxLength],
            validationOptions: { isNotMaxLength: { max: 3 } }
          },
          active: {
            errors: [],
            value: project?.active || null,
            typeValidations: [isNotEmpty]
          }
        },
        modules: []
      }
    ])
  }

  const handleChangeProject = (index, name, value) => {
    const project = createdProjects[index]
    project.formData[name] = value
    const validations = validateFieldValue(project.validations, name, value)
    project[name] = value
    project.validations = validations
    // console.log('handleChangeProject', [
    //   ...createdProjects.slice(0, index),
    //   project,
    //   ...createdProjects.slice(index + 1)
    // ])
    setCreatedProjects([
      ...createdProjects.slice(0, index),
      project,
      ...createdProjects.slice(index + 1)
    ])
  }

  const handleDeleteProject = ({ index }) => {
    setCreatedProjects([
      ...createdProjects.slice(0, index),
      ...createdProjects.slice(index + 1)
    ])
  }
  const TabHeaderTemplate = ({ project }) => {
    return (
      <div className='flex justify-content-between align-items-center gap-2'>
        <ProjectChip
          projectCode={project.code}
          clientCode={client.code}
        />
        <span>{project.name}</span>
      </div>
    )
  }
  return (
    <>
      <FormSectionTitle title="Proyectos" />
      <Tabview
        key={createdProjects.length + activeIndex}
        id='projects-tabview'
        activeIndex={activeIndex}
        onBeforeTabClose={(e) => {
          e.originalEvent.preventDefault()
          e.originalEvent.stopPropagation()
          // console.log('e 1', e)
        }}
        onTabClose={(e) => {
          e.originalEvent.preventDefault()
          e.originalEvent.stopPropagation()
          // console.log('e 2', e, createdProjects)
          handleDeleteProject({ index: e.index })
        }}
      >
        {
          createdProjects?.length
            ? createdProjects.map((project, index) => {
              const isProjectEditable = user?.isAdmin() || (user.isTeamLeader() && user.leaderProjects.includes(project.id))
              return (
                <TabPanel
                  key={index}
                  // header={`${project.code} - ${project.name}`}
                  header={<TabHeaderTemplate project={project}/>}
                  closable={project?.isDeletable || false}
                  className='deletable-project'
                  disabled={!isProjectEditable}
                >
                  <BlockUI
                    className='bg-transparent'
                    blocked={!client?.active || !isProjectEditable}
                  >
                    <FormProject
                      client={client}
                      formData={project.formData}
                      handleChange={(e) => {
                        handleChangeProject(index, e.target.name, e.target.value)
                      }}
                      validations={project.validations}
                      layout='horizontal'
                    />
                    <Divider className='my-3' stroke='sm' />
                    <FormClientModules
                      client={client}
                      project={project}
                      modules={project.modules}
                      error={error}
                      setError={setError}
                      setModules={(modules) => {
                        const project = createdProjects[index]
                        project.modules = modules?.sort((a, b) => a.order - b.order)
                        setCreatedProjects([
                          ...createdProjects.slice(0, index),
                          project,
                          ...createdProjects.slice(index + 1)
                        ])
                      }}
                    />
                  </BlockUI>
                </TabPanel>
              )
            })
            : <TabPanel header='No hay proyectos'>
              <div className='flex justify-content-center align-items-center h-10rem w-full'>
                <p>Crea un proyecto para poder agregar módulos</p>
              </div>
            </TabPanel>
        }
      </Tabview>
      {
        tabViewContainer &&
        createPortal(
          <TabviewControllersContainer>
            <PrimaryButton
              label='Crear proyecto'
              icon='pi pi-plus'
              onClick={() => setOpenProjectModal(true)}
              className='white-space-nowrap'
            />
          </TabviewControllersContainer>,
          tabViewContainer
        )
      }
      <ModalFormCreateProject
        visible={openProjectModal}
        setVisible={setOpenProjectModal}
        setProject={handleCreateProject}
        projects={createdProjects}
      />
    </>
  )
}

export default FormClientProjects
