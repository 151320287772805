import React, { useEffect, useState } from 'react'
import Calendar from '../../../../../components/atoms/Calendar.atom'
import { capitalizeFirstLetter } from '../../../../../utils/strings.utils'
import { compareDates } from '../../../../../utils/date.utils'
import { DateTime } from 'luxon'
import PrimaryButton from '../../../../../components/molecules/buttons/PrimaryButton.molecule'
import useTimeRegisterContext from '../../../hooks/useTimeRegisterContext.hook'
/**
 * @typedef {JSX.IntrinsicAttributes & JSX.IntrinsicElements['div']} JSXDivElement
 */

/**
* @typedef {Omit<import("primereact/chip").ChipProps & JSXDivElement, 'key' | 'inputStyle' | 'label' | 'value' | 'onChange' | 'maxDate' >} CalendarProps
*/

/**
 * @typedef {{
*   calendarProps: CalendarProps,
* }} DatePickerProps
*/

/**
* @param {DatePickerProps} props
* @returns {JSX.Element}
*/
const DatePicker = ({
  selectedDate,
  setSelectedDate,
  uncompleteDay,
  calendarProps
}) => {
  const {
    editingMissingDay
  } = useTimeRegisterContext()
  const [labelRef, setLabelRef] = useState()
  const [inputCalendarWidth, setInputCalendarWidth] = useState(150)
  const todayDate = DateTime.local().set({ hour: 12 }).toJSDate()
  const yesterdayDate = DateTime.local().startOf('day').minus({ days: 1 }).toJSDate()
  // alert('todayDate', todayDate, 'selectedDate', selectedDate)
  let label
  if (compareDates(selectedDate, todayDate)) {
    label = 'Hoy'
  } else if (compareDates(selectedDate, yesterdayDate)) {
    label = 'Ayer'
  } else {
    label = DateTime.fromJSDate(selectedDate).setLocale('es').toFormat('cccc')
    label = capitalizeFirstLetter(label)
  }

  useEffect(() => {
    if (labelRef) {
      const spanWidth = labelRef?.getBoundingClientRect()?.width + 20
      if (spanWidth > 150) {
        setInputCalendarWidth(spanWidth)
      }
    }
  }, [labelRef])
  // React.useLayoutEffect(() => {
  //   console.log('uncompleteDay', selectedDate, DateTime.fromISO(uncompleteDay?.date?.split('T')[0]).toJSDate())
  // }, [])
  return (
    <div className='flex gap-3' style={{ height: '38px' }}>
      <div className='flex gap-2'>
        <PrimaryButton
          disabled={(Boolean(label === 'Hoy') || Boolean(uncompleteDay) || Boolean(editingMissingDay))}
          label='Hoy'
          onClick={() => {
            setSelectedDate(todayDate)
          }}
        >
          Hoy
        </PrimaryButton>
      </div>
      <div className='relative'>
        <Calendar
          key={inputCalendarWidth}
          name='date'
          inputStyle={{ color: 'transparent', cursor: 'pointer', width: `${inputCalendarWidth}px`, height: '38px' }}
          label={label}
          value={DateTime.fromJSDate(selectedDate).set({ hour: 12 }).toJSDate()}
          onChange={(e) => {
            const selectedLocalDate = DateTime.fromJSDate(e.value).startOf('day')
            setSelectedDate(selectedLocalDate.toJSDate())
            // console.log('date-picker-cahnge', e.value)
            // setSelectedDate(e.value)
          }}
          maxDate={
            uncompleteDay
              ? DateTime.fromISO(uncompleteDay?.date?.split('T')[0]).toJSDate()
              : editingMissingDay
                ? DateTime.fromJSDate(selectedDate).set({ hour: 12 }).toJSDate()
                : DateTime.local().toJSDate()
          }
          minDate={
            uncompleteDay
              ? DateTime.fromISO(uncompleteDay?.date?.split('T')[0]).toJSDate()
              : editingMissingDay ? DateTime.fromJSDate(selectedDate).set({ hour: 12 }).toJSDate() : undefined
          }
          {...calendarProps}
        />

        <span
          ref={setLabelRef}
          className='absolute flex gap-2 flex align-items-center pointer-events-none cursor white-space-nowrap'
          style={{
            top: '50%',
            transform: 'translateY(-50%)',
            left: '0.5rem',
            color: 'light-dark(var(--secondary-color), var(--text-color))'
          }}
        >
          <i className="pi pi-calendar"></i>
          {label} ({DateTime.fromJSDate(selectedDate).toFormat('dd/MM/yyyy')})
        </span>
      </div>
    </div>
  )
}

export default DatePicker
