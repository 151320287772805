import React from 'react'
import ReportBadgeWithTooltip from '../../../../components/molecules/ReportBadgeWithTooltip.molecule'

export const CommentBadge = ({ comment }) => {
  return (
    <ReportBadgeWithTooltip
      type='info'
      iconClassName='pi pi-comment text-white'
      tooltipValue={
        <>
          <span className='font-bold'>Comentario: </span>
          <span>{comment}</span>
        </>
      }
    />
  )
}

export const EditedAfterFinishedBadge = () => {
  return (
    <ReportBadgeWithTooltip
      type='warning'
      iconClassName='pi pi-history'
      tooltipValue='El día fue editado después de haber sido finalizado'
    />
  )
}

export const TimeDifferenceBadge = ({
  value,
  percentage,
  tooltip,
  minTreshold = 0.5,
  maxTreshold = 1,
  referenceValue = 40,
  ...props
}) => {
  const isDanger = value < referenceValue - minTreshold || value > referenceValue + maxTreshold
  return (
    isDanger &&
    <ReportBadgeWithTooltip
      type='danger'
      iconClassName='pi pi-exclamation-triangle text-white'
      legend={percentage}
      legendProps={{
        style: {
          fontSize: '12px',
          fontWeight: 'bold'
        }
      }}
      tooltipValue={tooltip}
      className={'ml-1 ' + props.className}
      {...props}
    />
  )
}

export const UnexpectedTimeBadge = ({
  value,
  expectedValue,
  tooltips = {},
  minTreshold = 0.5,
  maxTreshold = 1,
  ...props
}) => {
  const isDanger = value < expectedValue - minTreshold || value > expectedValue + maxTreshold
  const isLess = value < expectedValue
  const legendProps = {
    style: {
      fontSize: '12px',
      fontWeight: 'bold'
    }
  }
  if (!isDanger) return null
  return (
    <ReportBadgeWithTooltip
      type='danger'
      iconClassName={`pi pi-${isLess ? 'arrow-down' : 'arrow-up'} text-white`}
      legendProps={legendProps}
      tooltipValue={tooltips[isLess ? 'isLess' : 'isMore']}
      {...props}
    />
  )
}

const CellReportBadges = ({ rowData, date }) => {
  const isDayEditedAfterFinished = rowData?.week?.[date]?.isDayEditedAfterFinished
  const dayFinishedComment = rowData?.week?.[date]?.workDayComment
  const timeDifference = rowData?.week?.[date]?.sisuTime > rowData?.week?.[date]?.billedTime
  return (
    <span className='flex gap-1'>
      {
        isDayEditedAfterFinished &&
        <ReportBadgeWithTooltip
          type='warning'
          iconClassName='pi pi-history'
          tooltipValue='El día fue editado después de haber sido finalizado'
        />
      }
      {
        dayFinishedComment &&
        <ReportBadgeWithTooltip
          type='info'
          iconClassName='pi pi-comment text-white'
          tooltipValue={
            <>
              <span className='font-bold'>Comentario: </span>
              <span>{dayFinishedComment}</span>
            </>
          }
        />
      }
      {
        timeDifference &&
        <ReportBadgeWithTooltip
          type='danger'
          iconClassName='pi pi-exclamation-triangle text-white'
          lagend={timeDifference}
        />
      }
    </span>
  )
}

export default CellReportBadges
