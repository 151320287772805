import React from 'react'
import PropTypes from 'prop-types'
import InputSwitch from '../atoms/InputSwitch.atom'
import { hasAnyErrors } from '../../utils/validations.utils'
import ErrorMessageForm from '../atoms/ErrorMessageForm.atom'

export default function InputSwitchForm (props) {
  const forId = `input-switch-${props.label?.replace(/ /g, '-')}-${new Date().getTime()}`
  return (
    <>
      <div className="flex align-items-center">
        <InputSwitch
          disabled={props.disabled || props.viewOnly}
          inputId={forId}
          name={props.name}
          checked={props.value}
          onChange={props.onChange}
          className={`${props.className} ${hasAnyErrors(props.error) ? 'p-invalid' : ''}`}

        />
        <label className="ml-2 mb-0" htmlFor={forId}>
          {props.label}{props.require ? ' * Requerido' : ''}{props.optional ? ' (Opcional)' : ''}
        </label>
      </div>
      { hasAnyErrors(props.error) ? <ErrorMessageForm errorMessage={props.error[0]?.errorMessage }/> : null}
    </>
  )
}

InputSwitchForm.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.array]),
  require: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
  optional: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool])
}
