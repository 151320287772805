import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Column from '../../../../../components/atoms/table/Column.atom'
import ColumnGroup from '../../../../../components/atoms/table/ColumnGroup.atom'
import Row from '../../../../../components/atoms/table/RowColumnGroup.atom'
import { CustomTreeTable as TreeTable } from '../../../../../components/atoms/table/CustomTreeTable.atom'
import { roundReportData } from '../../../../../utils/report.utils'
import { CLIENT_CHIP_COLUMN_PROPS } from '../../../../../consts/tables.consts'
import MultiSelectClientProjectFilter from '../../../../../components/compounds/MultiSelectClientProjectFilter.compound'
import { formatDecimals } from '../../../../../utils/numbers.utils'
import { RANGES, UNIFIEDDATE_VALUES } from '../../../../../consts/CompoundRangeDatePicker'
import { displayValueOrPlaceholder } from '../../../../../utils/strings.utils'
import { DateTime } from 'luxon'

const HoursByTeamAndTeamMemberReportDataTable = React.forwardRef(({
  reportData: originalData,
  teamMembers,
  dateRange,
  period
}, ref) => {
  const [expandedKeys, setExpandedKeys] = useState({})
  const [filters, setFilters] = useState({
    'data.name': { value: null, matchMode: 'in' }
  })
  const reportData = roundReportData(originalData)
  // const teamMembers = reportData.teamMembers.map(tm => tm.teamMemberTagName)
  if (!teamMembers?.length) {
    teamMembers = reportData.teamMembers.map(tm => tm.teamMemberTagName)
  }
  const navigate = useNavigate()

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Proyecto"
          rowSpan={3}
          colSpan={2}
          field='data.name'
          filter
          filterElement={(options) =>
            <MultiSelectClientProjectFilter
              key={reportData.data?.length}
              options={options}
              data={reportData.data}
            />
          }
          filterField='data.name'
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          filterMatchMode="in"
          onFilterApplyClick={(e) => {
            if (!e.constraints?.value) {
              collapseAll()
            } else {
              expandAll()
              setFilters({
                'data.name': { value: e.constraints?.value, matchMode: 'in' }
              })
            }
          }}
          onFilterClear={() => {
            collapseAll()
            setFilters({
              'data.name': { value: null, matchMode: 'in' }
            })
          }}
          frozen={true}
          alignFrozen='left'
        />
      </Row>
      <Row>
        <Column header="Integrantes del equipo" colSpan={teamMembers?.length} rowSpan={1} />
      </Row>
      <Row>
        {
          teamMembers?.map((tm) => {
            const teamMember = reportData.teamMembers.find((t) => t.teamMemberTagName === tm)
            if (!teamMember) return null
            return (
              <Column
                key={teamMember}
                headerTooltip={teamMember?.teamMemberName}
                headerTooltipOptions={{ position: 'bottom' }}
                headerClassName='cursor-pointer'
                header={() => {
                  const [startDate, endDate] = dateRange
                  return (
                    <Link
                      className='team-member-name'
                      to='/reporte-individual'
                      state={{
                        teamMemberId: teamMember?.teamMemberId,
                        startDate: startDate.toISODate(),
                        endDate: endDate.toISODate(),
                        period
                      }}
                      title='Ir al reporte individual ↗'
                    >
                      {teamMember?.teamMemberTagName}
                    </Link>
                  )
                }}
              />
            )
          })
        }
      </Row>
    </ColumnGroup>
  )

  const footerGroup = () => {
    const projects = reportData.data.map(client => client.children.map(project => project)).flat(1)
    const totals = reportData.teamMembers.reduce((acc, teamMember) => {
      if (!acc[teamMember]) {
        acc[teamMember?.teamMemberTagName] = 0
      }
      projects.forEach((project) => {
        if (filters['data.name'].value?.length > 0 && !filters['data.name'].value?.includes(project.data.name)) {
          return acc
        }
        acc[teamMember?.teamMemberTagName] += project.data[teamMember?.teamMemberTagName] || 0
      })
      acc[teamMember?.teamMemberTagName] = acc[teamMember?.teamMemberTagName].toFixed(2)
      return acc
    }, {})
    reportData.totals = totals
    return (
      <ColumnGroup>
        <Row>
          <Column footer="Totales" colSpan={2} frozen alignFrozen="left" />
          {
            teamMembers?.length > 0
              ? (
                teamMembers?.map((tm) => {
                  const teamMember = reportData.teamMembers.find((t) => t.teamMemberTagName === tm)
                  if (!teamMember) return null
                  return (
                    <Column key={teamMember} footer={displayValueOrPlaceholder(reportData.totals[teamMember?.teamMemberTagName])} />
                  )
                })
              )
              : (
                <Column footer={'-'} />
              )
          }
        </Row>
      </ColumnGroup>
    )
  }

  const collapseAll = () => {
    setExpandedKeys({})
  }

  const expandAll = () => {
    const newExpandedKeys = {}
    reportData.data.forEach((node) => {
      newExpandedKeys[node.key] = true
    })
    setExpandedKeys(newExpandedKeys)
  }

  return (
    <>
      <TreeTable
        ref={ref}
        className='hours-by-team-and-team-member-report'
        value={reportData.data}
        headerColumnGroup={headerGroup}
        footerColumnGroup={footerGroup()}
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        filters={filters}
        globalFilterFields={['data.name']}
      >
        <Column
          {...CLIENT_CHIP_COLUMN_PROPS}
          frozen={true}
          alignFrozen='left'
        />
        {
          teamMembers?.length > 0
            ? (
              teamMembers?.map((tm) => {
                const teamMember = reportData.teamMembers.find((t) => t.teamMemberTagName === tm)
                if (!teamMember) return null
                return (
                  <Column
                    key={teamMember}
                    field={`data.${teamMember?.teamMemberTagName}`}
                    header={`${teamMember?.teamMemberTagName}`}
                    body={(node) => <span>{displayValueOrPlaceholder(node.data[teamMember?.teamMemberTagName])}</span>}
                  />
                )
              })
            )
            : (
              <Column
                body={'-'}
                footer={'-'}
              />
            )
        }
      </TreeTable>
    </>
  )
})

export default HoursByTeamAndTeamMemberReportDataTable
