/* eslint-disable */
import React from 'react'
import AppMenu from './controllers/AppMenu'
import { colorSchemeSystem, menuMode } from '../consts/common.const'
import MainLayout from '../components/layouts/MainLayout.layout'
import MainContentLayout from '../components/layouts/MainContentLayout.layout'
import SidebarContext from '../contexts/SidebarContext'
import { ApplicationRoutes } from './ApplicationRoutes'
import { ScreenDimensionsContextProvider } from '../contexts/ScreenDimensionsContext.context'
import { ReportFiltersContextProvider } from '../contexts/ReportFiltersContext.context'
import { ClientColorsProvider } from '../contexts/data/ClientColors.context'
import useDebugKeybinds from '../hooks/useDebugKeybinds'

export function AuthorizedRoutes () {
  if (process.env.NODE_ENV !== 'production') {
    useDebugKeybinds()
  }

  return (
    <ScreenDimensionsContextProvider>
      <SidebarContext>
        <ReportFiltersContextProvider>
          <ClientColorsProvider>
            <MainLayout>
              <AppMenu
                menuMode={menuMode}
                colorScheme={colorSchemeSystem}
              />

              <MainContentLayout>
                <ApplicationRoutes />
              </MainContentLayout>
            </MainLayout>
          </ClientColorsProvider>
        </ReportFiltersContextProvider>
      </SidebarContext>
    </ScreenDimensionsContextProvider>
  )
};
