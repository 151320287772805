import React, { useEffect, useState } from 'react'
import AbsoluteOccupationChart from '../components/AbsoluteOccupationChart'
import { getAbsoluteOccupationChart, getPercentageOccupationChart } from '../../../services/report.service'
import OccupationReportChartsControls from '../components/OccupationReportChartsControls'
import { OCCUPATION_CHART_TYPES } from '../../../consts/reports.consts'
import PercentageOccupationChart from '../components/PercentageOccupationChart'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'
import useReportFiltersContext from '../../../hooks/contextConsumers/useReportFiltersContext.hook'

const OccupationReportCharts = () => {
  const {
    occupationReportChartsDateRange,
    setOccupationReportChartsDateRange,
    occupationReportChartsTemporalHoursTypeFilter,
    setOccupationReportChartsTemporalHoursTypeFilter
  } = useReportFiltersContext()
  const [chartType, setChartType] = useState(OCCUPATION_CHART_TYPES.ABSOLUTO)
  const [chartData, setChartData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getChartData()
  }, [occupationReportChartsDateRange, chartType])

  const renderChart = () => {
    if (loading) {
      return (
        <div className='flex justify-content-center align-items-center h-full' style={{ minHeight: '500px' }}>
          <ProgressSpinner/>
        </div>
      )
    }
    switch (chartType) {
    case OCCUPATION_CHART_TYPES.ABSOLUTO:
      return (
        <AbsoluteOccupationChart
          data={chartData}
          hoursTypeFilter={occupationReportChartsTemporalHoursTypeFilter}
        />
      )
    case OCCUPATION_CHART_TYPES.PORCENTUAL:
      return (
        <PercentageOccupationChart
          data={chartData}
          hoursTypeFilter={occupationReportChartsTemporalHoursTypeFilter}
        />
      )
    default:
      throw new Error('Occupation chart not available, valid options: ' + Object.keys(OCCUPATION_CHART_TYPES))
    }
  }

  const getChartData = async () => {
    setLoading(true)
    let response
    try {
      switch (chartType) {
      case OCCUPATION_CHART_TYPES.ABSOLUTO:
        response = await getAbsoluteOccupationChart(occupationReportChartsDateRange[0], occupationReportChartsDateRange[1])
        break
      case OCCUPATION_CHART_TYPES.PORCENTUAL:
        response = await getPercentageOccupationChart(occupationReportChartsDateRange[0], occupationReportChartsDateRange[1])
        break
      default:
        throw new Error('Occupation chart not available, valid options: ' + Object.keys(OCCUPATION_CHART_TYPES))
      }
      if (!response.success) {
        throw new Error()
      }
      setChartData(response.result)
    } catch (error) {
      console.error('Error cargando datos de la grafica: ', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className='flex flex-column h-full'>
      <OccupationReportChartsControls
        dateRange={occupationReportChartsDateRange}
        setDateRange={setOccupationReportChartsDateRange}
        chartType={chartType}
        setChartType={setChartType}
        hoursTypeFilter={occupationReportChartsTemporalHoursTypeFilter}
        setHoursTypeFilter={setOccupationReportChartsTemporalHoursTypeFilter}
        loading={loading}
      />
      {renderChart()}
    </div>
  )
}

export default OccupationReportCharts
