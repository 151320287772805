import React from 'react'
import Dropdown from '../atoms/Dropdown.atom'
import Label from '../atoms/Label.atom'
import { hasAnyErrors } from '../../utils/validations.utils'
import ErrorMessageForm from '../atoms/ErrorMessageForm.atom'

const DropdownColorInput = (props) => {
  const className = `${props.className || ''} color-dropdown`
  const forId = `dropdown-input-color-${props.label?.replace(/ /g, '-')}`
  let value = props.value ? props.value?.[props.optionLabel] : '-'
  if (!value) {
    value = props.options?.find(option => option[props.optionValue] === props.value)?.[props.optionLabel] || '-'
  }
  const ColorBadge = ({ color }) => (
    <div className={`color-badge-${color} border-round`} style={{ width: '22px', height: '22px', background: color }}>

    </div>
  )
  const itemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <ColorBadge color={option?.value} />
        <div>{option?.label}</div>
      </div>
    )
  }
  const selectedItemTemplate = (option) => {
    if (!option) return props?.placeholder
    return (
      <div className="flex align-items-center gap-2">
        <ColorBadge color={option?.value} />
        <div>{option?.label}</div>
      </div>
    )
  }
  return (
    <>
      <Label htmlFor={forId}> {props.label}{props.require ? ' *' : ''}{props.optional ? ' (Opcional)' : ''}</Label>
      <Dropdown
        inputId={forId}
        className={hasAnyErrors(props.error) ? `p-invalid ${className}` : className}
        options={props.colors}
        itemTemplate={itemTemplate}
        valueTemplate={selectedItemTemplate}
        {...props}
      />
      { hasAnyErrors(props.error) && !props.withoutErrorMessage ? <ErrorMessageForm errorMessage={props.error[0]?.errorMessage }/> : null}
    </>
  )
}

export default DropdownColorInput
