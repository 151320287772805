import React, { useEffect, useState } from 'react'
import PieChart from '../../../components/compounds/graphics/PieChart.compound'
import useScreenDimensionsContext from '../../../hooks/contextConsumers/useScreenDimensionsContext.hook'
import ClientBadgeListLegend from '../../../components/compounds/graphics/components/ClientBadgeListLegend'
import ContainerOfChart from '../../../components/compounds/graphics/components/ContainerOfChart'
import { OPACITY_HIGHLIGHT_LEVELS } from '../../../consts/modules/graphics.consts'
import { debounce } from 'lodash'

const HoursByClientPieChart = ({
  data: chartData,
  loading,
  error
}) => {
  const [data, setData] = useState(chartData)
  useEffect(() => {
    setData(chartData)
  }, [chartData])
  const { screenWidth } = useScreenDimensionsContext()
  const isMobile = screenWidth < 720

  const CHART_CONTAINER_CONFIG = {
    title: 'Horas facturadas por cliente',
    iconClassName: 'pi pi-briefcase text-3xl',
    className: 'flex-1 font-cairo flex-shrink-0 w-6'
  }
  const PIECHART_RESPONSIVE_CONTAINER_CONFIG = {
    minHeight: '300px',
    maxHeight: '300px',
    minWidth: !isMobile ? '450px' : '200px',
    maxWidth: '470px'
  }
  const SHOW_EMPTY_MESSAGE = !data?.[0]?.data?.length
  const debouncedSetData = debounce(setData, 50)
  const handleMouseEnterLegend = (e) => {
    const hoveredLegend = e?.currentTarget?.dataset?.name
    data[0].data = data[0].data.map(item => ({
      ...item,
      extraInfo: {
        ...item.extraInfo,
        opacity: item.name === hoveredLegend ? OPACITY_HIGHLIGHT_LEVELS.HIGHLIGHTED : OPACITY_HIGHLIGHT_LEVELS.NOT_HIGHLIGHTED
      }
    }))
    debouncedSetData([...data])
  }

  const handleMouseLeaveLegend = () => {
    data[0].data = data[0].data.map(item => ({
      ...item,
      extraInfo: {
        ...item.extraInfo,
        opacity: OPACITY_HIGHLIGHT_LEVELS.HIGHLIGHTED
      }
    }))
    debouncedSetData([...data])
  }

  if (error) {
    return (
      <ContainerOfChart
        title='Facturación'
        iconClassName='pi pi-dollar text-3xl'
        className='flex-1 font-cairo flex-shrink-0 w-6'
      >
        <div className="flex justify-content-center align-items-center">
          {error}: Error al cargar la información
        </div>
      </ContainerOfChart>
    )
  }
  return (
    <>
      <PieChart
        data={data}
        label={false}
        responsiveContainerConfig={PIECHART_RESPONSIVE_CONTAINER_CONFIG}
        legend
        legendTemplate={(data) => (
          <ClientBadgeListLegend
            data={data}
            onMouseEnter={handleMouseEnterLegend}
            onMouseLeave={handleMouseLeaveLegend}
          />
        )}
        legendConfig={{
          verticalAlign: isMobile ? 'bottom' : 'middle',
          align: isMobile ? 'center' : 'right',
          layout: isMobile ? 'horizontal' : 'vertical'
        }}
        margin={{
          top: !isMobile ? 16 : 5,
          bottom: !isMobile ? 16 : 5
        }}
        chartContainerConfig={CHART_CONTAINER_CONFIG}
        showEmptyMessage={SHOW_EMPTY_MESSAGE}
        emptyMessageConfig={{
          title: 'Sin información',
          message: 'No hay horas registradas durante el periodo seleccionado, intenta cambiando los filtros.'
        }}
        loading={loading}
      />
    </>
  )
}

export default HoursByClientPieChart
