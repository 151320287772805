import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AVERAGE_CONSUMPTION_TYPES, AVERAGE_CONSUMPTION_TYPES_OPTIONS } from '../../../consts/modules/budget.consts'
import Card from '../../../components/atoms/Card.atom'
import DropdownInput from '../../../components/molecules/DropdownInput.molecule'
import { ValueWithLabelContainer, ValueWithLabelTemplate } from '../components/ValueWithLabelElements'
import { formatDecimals } from '../../../utils/numbers.utils'
import { getEstimatedBudgetEnd } from '../../../utils/budget.utils'

const AverageConsumption = ({
  data: budgetData,
  loading
}) => {
  const [periodOption, setPeriodOption] = useState(AVERAGE_CONSUMPTION_TYPES.MENSUAL)
  const [data, setData] = useState({
    historicalAverage: 0,
    last6MonthsAverage: 0,
    last3MonthsAverage: 0,
    estimatedBudgetEnd: 'Calculando...'
  })

  useEffect(() => {
    if (budgetData && Object.keys(budgetData).length) {
      formatResult(budgetData)
    }
  }, [periodOption, budgetData])

  const formatResult = (result) => {
    const isMonthly = periodOption === AVERAGE_CONSUMPTION_TYPES.MENSUAL
    const formattedResult = {
      historicalAverage: result.totalBilledTime / (isMonthly ? result.totalMonths : result.totalMonths * 4) || 0,
      last6MonthsAverage: result.lastSixMonthBilledTime / (isMonthly ? 6 : 6 * 4),
      last3MonthsAverage: result.lastThreeMonthBilledTime / (isMonthly ? 3 : 3 * 4),
      hasBudget: result.hasBudget,
      consumedTimePercentage: result.consumedTimePercentage
    }
    const estimatedEndBudget = getEstimatedBudgetEnd(
      result.remainingBudget,
      isMonthly ? formattedResult.historicalAverage : formattedResult.historicalAverage * 4,
      isMonthly ? formattedResult.last3MonthsAverage : formattedResult.last3MonthsAverage * 4
    )
    setData(formattedResult)
    formattedResult.estimatedBudgetEnd = estimatedEndBudget.label
    formattedResult.estimatedEndMinDate = estimatedEndBudget.estimatedEndMinDate
    formattedResult.estimatedEndMaxDate = estimatedEndBudget.estimatedEndMaxDate
  }

  const HeaderTemplate = () => {
    return (
      <div className='flex justify-content-between align-items-center'>
        <span className='text-3xl font-cairo font-semibold'>Consumo promedio {periodOption?.toLocaleLowerCase()}</span>
        <DropdownInput
          options={AVERAGE_CONSUMPTION_TYPES_OPTIONS}
          value={periodOption}
          onChange={(e) => setPeriodOption(e.value)}
          valueTemplate={(option) => {
            return (
              <span>
                <i className='pi pi-calendar mr-2' />
                {option.label}
              </span>
            )
          }}
          className='w-12rem average-consumption-dropdown'
        />
      </div>
    )
  }
  const estimatedBudgetEndTemplate = () => {
    let title = 'Sin estimación'
    let subtitle = ''
    const consumedTimePercentage = parseFloat(data?.consumedTimePercentage)
    if (data?.hasBudget) {
      if (consumedTimePercentage > 100) {
        subtitle = 'Por presupuesto sobregirado'
      } else if (consumedTimePercentage === 100) {
        subtitle = 'Por presupuesto finalizado'
      } else {
        title = data.estimatedBudgetEnd
        subtitle = `${data.estimatedEndMinDate?.toFormat('dd/LL/yyyy')} - ${data.estimatedEndMaxDate?.toFormat('dd/LL/yyyy')}`
      }
    } else {
      subtitle = 'Por falta de presupuesto'
    }
    if (data?.historicalAverage === 0 && data?.last3MonthsAverage === 0) {
      title = 'Sin información'
      subtitle = ''
    }
    return (
      <div className='flex flex-column gap-0'>
        <span className='text-xl font-semibold'>{title}</span>
        <span className='text-base font-normal line-height-1'>{subtitle ? `(${subtitle})` : ''}</span>
      </div>
    )
  }
  const customAverageConsumptionFormat = (time) => {
    return time > 0 ? `${formatDecimals(time)} horas` : 'Sin información'
  }
  return (
    <Card className={'dashboard-card shadow-2 min-h-fit max-h-fit'}>
      <div className='flex flex-column gap-3'>
        <HeaderTemplate />
        <ValueWithLabelContainer>
          <ValueWithLabelTemplate
            label='Histórico'
            value={customAverageConsumptionFormat(data.historicalAverage)}
            loading={loading}
          />
          <ValueWithLabelTemplate
            label='Últimos 6 meses'
            value={customAverageConsumptionFormat(data.last6MonthsAverage)}
            loading={loading}
          />
          <ValueWithLabelTemplate
            label='Últimos 3 meses'
            value={customAverageConsumptionFormat(data.last3MonthsAverage)}
            loading={loading}
          />
          <ValueWithLabelTemplate
            label='Fin del presupuesto estimado'
            labelTooltip
            labelTooltipContent='Rango estimado de fechas que se calcula a partir del consumo promedio histórico y el de los últimos 3 meses.'
            labelTooltipPosition='bottom'
            labelTooltipIcon='pi pi-info-circle'
            value={estimatedBudgetEndTemplate()}
            loading={loading}
          />
        </ValueWithLabelContainer>
      </div>
    </Card>
  )
}

export default AverageConsumption

AverageConsumption.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool
}
