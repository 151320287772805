import React from 'react'
import DashboardHeader from './controllers/DashboardHeader.controller'
import DashboardContent from './controllers/DashboardContent.controller'

const Dashboard = () => {
  return (
    <>
      <DashboardHeader/>
      <DashboardContent/>
    </>
  )
}

export default Dashboard
