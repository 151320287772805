/* eslint-disable */
import React from 'react'

const UserActiveIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={33}
      height={32}
      viewBox="0 0 33 32"
      fill="none"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={33}
        height={32}
      >
        <path fill="#D9D9D9" d="M0.5 0H32.5V32H0.5z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M28.5 27.051V6.744a.4.4 0 00-.115-.295.4.4 0 00-.295-.116H4.91a.4.4 0 00-.295.116.4.4 0 00-.115.295V16c0 .283-.096.52-.288.712A.968.968 0 013.5 17a.966.966 0 01-.713-.288A.967.967 0 012.5 16V6.744c0-.663.236-1.23.708-1.703a2.321 2.321 0 011.702-.708h23.18c.663 0 1.23.236 1.702.708s.708 1.04.708 1.703v18.064c0 .58-.193 1.083-.578 1.511-.386.428-.86.672-1.422.732zm-16-8.795c-1.283 0-2.382-.457-3.296-1.37-.914-.914-1.37-2.013-1.37-3.296 0-1.284.456-2.382 1.37-3.296.914-.914 2.013-1.371 3.296-1.371s2.382.457 3.296 1.371c.914.914 1.37 2.012 1.37 3.296 0 1.283-.456 2.382-1.37 3.296-.914.913-2.013 1.37-3.296 1.37zm0-2c.733 0 1.361-.26 1.883-.783.523-.522.784-1.15.784-1.883 0-.734-.261-1.361-.784-1.884a2.568 2.568 0 00-1.883-.783c-.733 0-1.361.261-1.883.783a2.568 2.568 0 00-.784 1.884c0 .733.261 1.36.784 1.883.522.522 1.15.783 1.883.783zM4.526 28.41c-.563 0-1.041-.197-1.435-.59a1.954 1.954 0 01-.591-1.435v-.939c0-.653.177-1.258.532-1.814a3.55 3.55 0 011.422-1.283 19.345 19.345 0 013.988-1.454 17.266 17.266 0 018.116 0c1.34.323 2.67.808 3.988 1.454.593.299 1.067.727 1.422 1.283a3.31 3.31 0 01.532 1.814v.939c0 .562-.197 1.04-.591 1.434a1.953 1.953 0 01-1.435.591H4.526zm-.026-2h16v-.964c0-.27-.078-.52-.235-.75a1.778 1.778 0 00-.637-.563 17.156 17.156 0 00-3.514-1.286 15.196 15.196 0 00-7.228 0c-1.194.292-2.365.72-3.514 1.286-.269.146-.48.333-.637.563-.157.23-.235.48-.235.75v.964z"
          fill="#006AA6"
        />
      </g>
    </svg>
  )
}

export default UserActiveIcon
