export default class Component {
  static classNames (...args) {
    if (args) {
      let classes = []

      for (let i = 0; i < args.length; i++) {
        const className = args[i]

        if (!className) continue

        const type = typeof className

        if (type === 'string' || type === 'number') {
          classes.push(className)
        } else if (type === 'object') {
          const _classes = Array.isArray(className) ? className : Object.entries(className).map(([key, value]) => (value ? key : null))

          classes = _classes.length ? classes.concat(_classes.filter((c) => !!c)) : classes
        }
      }

      return classes.join(' ')
    }

    return undefined
  }
}

export const getElementFullHeight = (element) => {
  if (!element) return 0
  const styles = window.getComputedStyle(element)
  const marginTop = parseFloat(styles.marginTop)
  const marginBottom = parseFloat(styles.marginBottom)
  const totalHeight = element.offsetHeight + marginTop + marginBottom
  return totalHeight
}
