import React, { useLayoutEffect } from 'react'

const useHandleDuplicateGroupsDataTable = (loading, isSort, deps) => {
  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      if (loading || isSort) return
      const headers = document.querySelectorAll('.p-rowgroup-header td:first-child')
      const headerSet = new Set()
      headers.forEach((header) => {
        const content = header.textContent.trim()
        if (headerSet.has(content)) {
          header.classList.add('duplicate')
        } else {
          headerSet.add(content)
        }
      })
      // console.log('useHandleDuplicateGroupsDataTable', isSort, loading)
    }, 10)
    return () => clearTimeout(timeoutId)
  }, [...deps])
}

export default useHandleDuplicateGroupsDataTable
