import { UNIFIEDDATE_VALUES } from './CompoundRangeDatePicker'

export const DiaId = {
  LUNES: 1,
  MARTES: 2,
  MIERCOLES: 3,
  JUEVES: 4,
  VIERNES: 5,
  SABADO: 6,
  DOMINGO: 7
}

export const DATE_PICKER_LABELS = {
  HOY: 'Hoy',
  AYER: 'Ayer'
}

export const REPORTS_PERIODS = {
  DIARIO: 'diario',
  SEMANAL: 'semanal',
  MENSUAL: 'mensual',
  ANUAL: 'anual',
  CUSTOM: 'custom'
}

export const REPORTS_PERIODS_ENG = {
  DIARIO: UNIFIEDDATE_VALUES.SINGLEDAY,
  SEMANAL: UNIFIEDDATE_VALUES.SINGLEWEEK,
  MENSUAL: UNIFIEDDATE_VALUES.SINGLEMONTH,
  ANUAL: UNIFIEDDATE_VALUES.SINGLEYEAR,
  CUSTOM: UNIFIEDDATE_VALUES.RANGEDAY
}

export const REPORT_PERIOD_BY_LANG = {
  es: REPORTS_PERIODS,
  eng: REPORTS_PERIODS_ENG
}

export const LOWERCASE_DAYNAMES = {
  LUNES: 'lunes',
  MARTES: 'martes',
  MIERCOLES: 'miércoles',
  JUEVES: 'jueves',
  VIERNES: 'viernes',
  SABADO: 'sábado',
  DOMINGO: 'domingo'
}

export const SHORT_TO_LONG_MONTHS = {
  Ene: 'Enero',
  Feb: 'Febrero',
  Mar: 'Marzo',
  Abr: 'Abril',
  May: 'Mayo',
  Jun: 'Junio',
  Jul: 'Julio',
  Ago: 'Agosto',
  Sep: 'Septiembre',
  Oct: 'Octubre',
  Nov: 'Noviembre',
  Dic: 'Diciembre'
}

export const ENG_SHORT_MONTHS_ARRAY = [
  'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
]

export const ENG_LONG_MONTHS_ARRAY = [
  'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
]
